<template>
  <div class="layout-footer">
    <div class="p-grid">
      <div class="p-col-12">
        <div class="footer-bottom">
          <h4>Logistica Lupnova</h4>
          <h6>Copyright Ⓒ Mosi S,A.</h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
};
</script>
