import { createStore } from 'vuex';
import authmodule from './modules/auth/index.js';
import cusnewModule from './modules/cusnew/index.js';
import listvaluesModule from './modules/listvalues/index.js';
import cusnewdir from './modules/cusnewdir/index.js';
import deliveries from './modules/deliveries/index.js';
import billings from './modules/billings/index.js';

const montab = createStore({
  modules: {
    auth: authmodule,
    cusnew: cusnewModule,
    listvalues: listvaluesModule,
    cusnewdir: cusnewdir,
    deliveries: deliveries,
    billings: billings,
  },
});

export default montab;
