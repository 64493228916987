export default {
  insertCusnewdir(state, payload) {
    console.log(payload);
    state.cusnewdir['cusnewdir'].push(payload);
  },
  setCusnewDir(state, payload) {
    console.log(payload);
    state.cusnewdir['cusnewdir'] = payload;
    console.log(state);
  },
  setFetchTimestamp(state) {
    state.lastFetch = new Date().getTime();
  },
  updateCusnewdir(state, payload) {
    console.log('actualizacion');
    console.log(payload);
    state.cusnewdir['cusnewdir'].push(payload);
  },
  deleteCusnewdir(state, payload) {
    console.log('Mutation para delete ' + payload);
    state.cusnewdir['cusnewdir'].push(payload);
  },
};
