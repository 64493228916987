<template>
  <Toast />
  <DataTable
    ref="dv"
    responsiveLayout="scroll"
    id="costos"
    :value="CostosData"
    dataKey="mes"
    :paginator="true"
    :rows="12"
    showGridlines
    :loading="loading1"
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :rowsPerPageOptions="[12, , 24, 50, 75, 100, 125]"
    currentPageReportTemplate="Viendo {first} a {last} de {totalRecords} registros"
  >
    <template #header>
      <div
        class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between"
      ></div>
    </template>
    <template #empty><b>No se encontró ningun registros.</b></template>
    <template #loading> Cargando todos las registros... </template>
    <Column
      field="mes"
      header="Mes"
      :sortable="true"
      style="min-width: 12rem"
    ></Column>
    <Column
      field="ano"
      header="Año"
      :sortable="true"
      style="min-width: 12rem"
    ></Column>
    <Column
      field="ganancia"
      header="Ganancia"
      :sortable="true"
      style="min-width: 12rem"
    ></Column>
    <Column
      field="costo"
      header="Costo"
      :sortable="true"
      style="min-width: 12rem"
    ></Column>
    <Column :exportable="false" style="min-width: 8rem">
      <template #body="slotProps">
        <Button
          icon="pi pi-refresh"
          class="p-button-rounded p-button-info p-mr-2"
          @click="Actualiza(slotProps.data)"
        />        
      </template>
    </Column>
  </DataTable>
</template>

<script>
export default {
  data() {
    return {
      loading1: true,
      isLoading: false,
      CostosData: null,
      CostosData2: null,
      fecha_del:null,
      fecha_al:null
    };
  },
  created() {
    this.loadCostos(false, 1);
    //this.loadListValuesCostos(true);
    //this.CostosData = this.listValuesCostos();
  },
  methods: {
    async loadCostos(refresh = false, cuscia = 1) {
      this.isLoading = true;
      this.deliveries = null;

      try {
        await this.$store.dispatch('listvalues/loadListValuesCostos', cuscia, {
          forceRefresh: refresh,
        });
      } catch (error) {
        this.error = error.message || 'algo salió mal';
        this.isLoading = false;
      }
      this.isLoading = false;
      this.CostosData =
        this.$store.getters['listvalues/listvalues']['listvaluescostos'];
      //console.log(this.CostosData);
      this.loading1 = false;
      return true;
    },
    async loadListValuesCostos(refresh = false) {
      //this.isLoading = true;
      //console.log('mensajeros #1');
      try {
        this.CostosData = await this.$store.dispatch(
          'listvalues/loadListValuesCostos',
          {
            forceRefresh: refresh,
          }
        );
      } catch (error) {
        this.error = error.message || 'algo salió mal';
        //console.log(error.message);
      }
      //this.isLoading = false;
    },

    listValuesCostos() {
      try {
        const costos =
          this.$store.getters['listvalues/listvalues']['listvaluescostos'];
        return costos;
      } catch (error) {
        //console.log(error.messages);
        return true;
      }
    },
    Actualiza(selectedDoc){
      this.loading1 = true;
      let dates = this.getMonthStartAndEnd(selectedDoc.cod_mes, selectedDoc.ano);
      //startDate, endDate
      /*console.log(dates)
      this.$toast.add({
          severity: 'info',
          summary: 'Cs',
          detail: `${dates.startDate} - ${dates.endDate}`,
          //group: 'br',
          life: 3500,
        });*/
         this.fecha_del = dates.startDate;
         this.fecha_al = dates.endDate;
        
        this.trasladoDocumentos(true,dates.startDate,dates.endDate)
        

    },
    getMonthStartAndEnd(month, year) {
    // El mes en el objeto Date es de 0 a 11, así que restamos 1
    let startDate = new Date(year, month - 1, 1);
    let endDate = new Date(year, month, 0); // Usamos 0 para obtener el último día del mes anterior
    
    // Formateamos las fechas como "DD-MM-YYYY"
    /*let start = `${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`;
    let end = `${endDate.getDate()}-${endDate.getMonth() + 1}-${endDate.getFullYear()}`;
    console.log(start)
    start = this.formatDate(startDate);
    end = this.formatDate(endDate);*/
    return { startDate, endDate };
    },
    formatDate(date) {
      let day = date.getDate().toString().padStart(2, '0');
      let month = (date.getMonth() + 1).toString().padStart(2, '0');
      let year = date.getFullYear().toString();
      return `${year}-${month}-${day}`;
    },
    async trasladoDocumentos(refresh = false,fechadel,fechaal) {
      this.isLoading = true;
      this.loading1 = true;
      const formData = {
        fechadel: fechadel,
        fechaal: fechaal,
      };
      console.log(formData)
      try {
        await this.$store.dispatch('billings/trasladoDocumentos', formData, {
          forceRefresh: refresh,
        }); //viene de la clases action.js G
      } catch (error) {
        this.error = error.message || 'algo salió mal';
      }
      setTimeout(() => {
        this.loadCostos(true, 1);
        this.isLoading = false;
      }, 1100);
      return true;
    },
  },
};
</script>
