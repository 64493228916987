<template>
  <section>
    <base-card>
      <h2>Ingresar un Cliente Nuevo</h2>
      <cusnew-form @save-data="saveData"></cusnew-form
    ></base-card>
  </section>
</template>

<script>
import CusnewForm from '../../components/cusnew/CusnewForm.vue';

export default {
  components: {
    CusnewForm,
  },
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.goBack, false);
    }
  },
  methods: {
    saveData(data, dir) {
      console.log(data);
      for (const key in dir) {
        let datadir = null;
        console.log(dir[key]);
        datadir = dir[key];
        console.log(datadir);
        this.$store.dispatch('cusnewdir/insertCusnewdir', datadir);
      }

      this.$store.dispatch('cusnew/insertCusnew', data);
      this.$router.replace('/Clientes');
    },
    removeItemFromArr(arr, item) {
      console.log(arr);
      var i = arr.indexOf(item);

      if (i !== -1) {
        arr.splice(i, 1);
      }
    },
  },
};
</script>
