import API_DOMAIN from '../../../config';

export default {
  /*******************************************************************
   ************RECUPERACION DE TODOS LOS CLIENTES*********************
   *******************************************************************
   */
  dateFormatOracle(fecha) {
    const date = new Date(fecha);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }
    return dt + '/' + month + '/' + year;
  },
  async sendMail(context, data) {
    const infodata = {
      asunto: data.asunto,
      nit: data.nit,
      quien_envia_nombre: data.quien_envia_nombre,
      quien_envia_mail: data.quien_envia_mail,
      quien_recibe: data.quien_recibe,
      quien_recibe_mail: data.quien_recibe_mail,
      nombre_archivo: data.nombre_archivo,
      tipo: data.tipo,
      autorizacion: data.autorizacion,
      serie: data.serie,
      numero: data.numero,
      establecimiento: data.establecimiento,
      fecha: data.fecha,
      monto: data.monto,
      interno: data.interno,
      adjunto: data.adjunto,
    };
    console.log(infodata);
    const response = await fetch(`${API_DOMAIN}/sendmail/facturacion/lupnova`, {
      method: 'POST', //INSERT
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(infodata),
    });
    //console.log(response);
    const responseData = await response.json();
    console.log(responseData);
    if (!response.ok) {
      //console.log('ha ocurrido un error');
      const error = new Error(
        responseData.message || ' Error enviando el correo.'
      );
      console.log(error);
      throw error;
    }
    return responseData;
  },
  async loadBillings(context, docmcia) {
    console.log('Inicio de Metodo de Recuperacion de Facturas...' + docmcia);

    const token = context.rootGetters.token;
    ////console.log(token);
    //console.log(docmcia);

    const response = await fetch(`${API_DOMAIN}/api/mosi/listado/Docmt`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        //cache: 'no-store',
        Authorization: 'Bearer ' + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Error recuperando informacion.'
      );
      throw error;
    }
    const facturas = [];

    for (const key in responseData['docmt']) {
      const docmt = {
        docmcia: responseData['docmt'][key].docmcia,
        docmper: responseData['docmt'][key].docmper,
        docmtip: responseData['docmt'][key].docmtip,
        docmdoc: responseData['docmt'][key].docmdoc,
        docmtipped: responseData['docmt'][key].docmtipped,
        docmped: responseData['docmt'][key].docmped,
        docmfecped: responseData['docmt'][key].docmfecped,
        docmafe: responseData['docmt'][key].docmafe,
        docmcon: responseData['docmt'][key].docmcon,
        docmcte: responseData['docmt'][key].docmcte,
        docmruta: responseData['docmt'][key].docmruta,
        docmnom: responseData['docmt'][key].docmnom,
        docmdir: responseData['docmt'][key].docmdir,
        docmvalfle: responseData['docmt'][key].docmvalfle,
        docmklfle: responseData['docmt'][key].docmklfle,
        docmimp: responseData['docmt'][key].docmimp,
        docmiva: responseData['docmt'][key].docmiva,
        docmtot: Number.parseFloat(responseData['docmt'][key].docmtot).toFixed(
          2
        ),
        docmexe: responseData['docmt'][key].docmexe,
        docmmsg: responseData['docmt'][key].docmmsg,
        docmsalcte: responseData['docmt'][key].docmsalcte,
        docmlimcre: responseData['docmt'][key].docmlimcre,
        docmchq: responseData['docmt'][key].docmchq,
        docmflg: responseData['docmt'][key].docmflg,
        docmdes: responseData['docmt'][key].docmdes,
        docmfecdoc: responseData['docmt'][key].docmfecdoc,
        docmchqa: responseData['docmt'][key].docmchqa,
        docmant: responseData['docmt'][key].docmant,
        docmanta: responseData['docmt'][key].docmanta,
        docmmini: responseData['docmt'][key].docmmini,
        docmminia: responseData['docmt'][key].docmminia,
        docmvprm: responseData['docmt'][key].docmvprm,
        docmmrk: responseData['docmt'][key].docmmrk,
        docmklprd: responseData['docmt'][key].docmklprd,
        docmrate: responseData['docmt'][key].docmrate,
        docmcrr: responseData['docmt'][key].docmcrr,
        docmgra: responseData['docmt'][key].docmgra,
        docmxen: responseData['docmt'][key].docmxen,
        docmdol: responseData['docmt'][key].docmdol,
        docmenv: responseData['docmt'][key].docmenv,
        docmdcr: responseData['docmt'][key].docmdcr,
        docmnit: responseData['docmt'][key].docmnit,
        docmsbr: responseData['docmt'][key].docmsbr,
        docmvalpro: responseData['docmt'][key].docmvalpro,
        docmvalproi: responseData['docmt'][key].docmvalproi,
        docmvoucher: responseData['docmt'][key].docmvoucher,
        docmnumauto: responseData['docmt'][key].docmnumauto,
        docmtarcre: responseData['docmt'][key].docmtarcre,
        docmentdpper: responseData['docmt'][key].docmentdpper,
        docmentdir: responseData['docmt'][key].docmentdir,
        docmcrg: responseData['docmt'][key].docmcrg,
        docmtra: responseData['docmt'][key].docmtra,
        docmbodcon: responseData['docmt'][key].docmbodcon,
        docmsold: responseData['docmt'][key].docmsold,
        docmship: responseData['docmt'][key].docmship,
        docmord: responseData['docmt'][key].docmord,
        docmfecent: responseData['docmt'][key].docmfecent,
        docmurg: responseData['docmt'][key].docmurg,
        docmconv: responseData['docmt'][key].docmconv,
        docmsts: responseData['docmt'][key].docmsts,
        docmtda: responseData['docmt'][key].docmtda,
        docmser: responseData['docmt'][key].docmser,
        docmtent: responseData['docmt'][key].docmtent,
        docmtentotr:
          responseData['docmt'][key].docmtentotr == null
            ? ''
            : responseData['docmt'][key].docmtentotr,
        docmpn: responseData['docmt'][key].docmpn,
        docmpb: responseData['docmt'][key].docmpb,
        docmblts: responseData['docmt'][key].docmblts,
        docmcajas: responseData['docmt'][key].docmcajas,
        docmvia: responseData['docmt'][key].docmvia,
        docmtipbl: responseData['docmt'][key].docmtipbl,
        docmbl: responseData['docmt'][key].docmbl,
        docmemp: responseData['docmt'][key].docmemp,
        docmafeser: responseData['docmt'][key].docmafeser,
        docmuuid:
          responseData['docmt'][key].docmuuid == null
            ? responseData['docmt'][key].docmentdir
            : responseData['docmt'][key].docmuuid,
        docmfeldoc: responseData['docmt'][key].docmfeldoc,
        docmfelser: responseData['docmt'][key].docmfelser,
        docmfelfec: responseData['docmt'][key].docmfelfec,
        items: responseData['docmt'][key].items,
      };

      facturas.push(docmt);
    }
    console.log(facturas)
    //this.loadBillingsMaster(context,1);
    //this.loadBillingsMaster(context,2)
    context.commit('setBillings', facturas);
    context.commit('setFetchTimestamp');
  },
  /****************************************************************************
   *****************RECUPERACION DEL DETALLE DE LA FACTURA*********************
   ****************************************************************************
   */
  async loadDetailBill(context, data) {
    console.log(data);
    const infodata = {
      docmcia: data.docmcia,
      docmdoc: data.docmdoc,
    };

    console.log(
      'Inicio de Metodo de Recuperacion de detalle de factura...' +
        infodata.docmdoc
    );
    const token = context.rootGetters.token;
    const response = await fetch(
      `${API_DOMAIN}/billings/detail/data/${infodata.docmcia}/${infodata.docmdoc}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          //cache: 'no-store',
          Authorization: 'Bearer ' + token,
        },
      }
    );
    console.log(response);
    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Error recuperando informacion.'
      );
      throw error;
    }
    const detalle = [];
    detalle.length = 0;
    console.log(detalle);
    //context.commit('setDocdt', detalle);
    //console.log(responseData['docdt']);
    //DOCDPRECAJ1
    for (const key in responseData['docdt']) {
      const detail = {
        docdprd: responseData['docdt'][key].docdprd,
        docdunivta: responseData['docdt'][key].docdunivta,
        docdnumlin: responseData['docdt'][key].docdnumlin,
        docdesc: responseData['docdt'][key].docdesc,
        docdprcuni: responseData['docdt'][key].docdprcuni,
        docdprecaj1: responseData['docdt'][key].docdprecaj1,
        docdiva: responseData['docdt'][key].docdiva,
      };
      detalle.push(detail);
    }
    context.commit('setDocdt', detalle);
    context.commit('setFetchTimestamp');
  },
  isString(variable) {
    return typeof variable === 'string'},
  /****************************************************************************
   ***************** TRASLADO DE FACTURAS*********************
   ****************************************************************************
   */
  async trasladoDocumentos(context, data) {
    console.log(data)
    const infodata = {
      fechadel: data.fechadel.toISOString().substring(0, 10),
      fechaal: data.fechaal.toISOString().substring(0, 10),
    };
   
   
    let fecha1 = infodata.fechadel;
    console.log(fecha1);
    console.log(infodata.fechaal.substring(0, 10));
    console.log('Inicio de Metodo de Traslado de Documentos...');

    const token = context.rootGetters.token;
    console.log(`${API_DOMAIN}/relocation/billings/${infodata.fechadel}/${infodata.fechaal}`)
    const response = await fetch(
      `${API_DOMAIN}/relocation/billings/${infodata.fechadel}/${infodata.fechaal}`,
      {
        method: 'POST', //INSERT
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      }
    );
    const responseData = await response.json();

    if (!response.ok) {
      //console.log('ha ocurrido un error');
      const error = new Error(
        responseData.mensaje || 'Error trasladando la informacion.'
      );
      //console.log(error);
      throw error;
    }
  },
  async actualizaMaestro(context, data) {
    const infodata = {
      docmcia: data.cia,
      docmper: data.per,
      docmtip: data.tip,
      docmdoc: data.doc,
      docmtipped: data.tipped,
      docmped: data.ped,
      docmfecped: data.fecped,
      docmafe: data.afe,
      docmcon: data.con,
      docmcte: data.cte,
      docmruta: data.ruta,
      docmnom: data.nom,
      docmdir: data.dir,
      docmvalfle: data.valfle,
      docmklfle: data.klfle,
      docmimp: data.imp,
      docmiva: data.iva,
      docmtot: data.tot,
      docmexe: data.exe,
      docmmsg: data.msg,
      docmsalcte: data.salcte,
      docmlimcre: data.limcre,
      docmchq: data.chq,
      docmflg: data.flg,
      docmdes: data.des,
      docmfecdoc: data.fecdoc,
      docmchqa: data.chqa,
      docmant: data.ant,
      docmanta: data.anta,
      docmmini: data.mini,
      docmminia: data.minia,
      docmvprm: data.vprm,
      docmmrk: data.mrk,
      docmklprd: data.klprd,
      docmrate: data.rate,
      docmcrr: data.crr,
      docmgra: data.gra,
      docmxen: data.xen,
      docmdol: data.dol,
      docmenv: data.env,
      docmdcr: data.dcr,
      docmnit: data.nit,
      docmsbr: data.sbr,
      docmvalpro: data.valpro,
      docmvalproi: data.valproi,
      docmvoucher: data.voucher,
      docmnumauto: data.numauto,
      docmtarcre: data.tarcre,
      docmentdpper: data.entdpper,
      docmentdir: data.entdir,
      docmcrg: data.crg,
      docmtra: data.tra,
      docmbodcon: data.bodcon,
      docmsold: data.sold,
      docmship: data.ship,
      docmord: data.ord,
      docmfecent: data.fecent,
      docmurg: data.urg,
      docmconv: data.conv,
      docmsts: data.sts,
      docmtda: data.tda,
      docmser: data.ser,
      docmtent: data.tent,
      docmtentotr: data.tentotr,
      docmpn: data.pn,
      docmpb: data.pb,
      docmblts: data.blts,
      docmcajas: data.cajas,
      docmvia: data.via,
      docmtipbl: data.tipbl,
      docmbl: data.bl,
      docmemp: data.emp,
      docmafeser: data.afeser,
      docmuuid: data.uuid,
      docmfeldoc: data.feldoc,
      docmfelser: data.felser,
      docmfelfec: data.felfec,
    };

    console.log('Inicio de Metodo de Actulizacion de Documentos Maestro...');
    const token = context.rootGetters.token;
    //old `${API_DOMAIN}/billings/master/${infodata.docmdoc}`,
    const response = await fetch(`${API_DOMAIN}/api/mosi/docmt`, {
      method: 'PUT', //INSERT
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(infodata),
    });
    const responseData = await response.json();

    if (!response.ok) {
      return {
        mensaje: response.statusText,
        codigo: 0, // Ejemplo de lógica para determinar el valor de "codigo"
      };
    } else {
      return {
        mensaje: responseData.docmt[0].mensaje,
        codigo: responseData.docmt[0].codigo,
      };
    }
  },

  async certificacionDocumentos(context, data) {
    //{{servidorP}}/documentos/generacion/FEL/1/323/1/LUP/7404/1
    const infodata = {
      docmcia: data.docmcia,
      docmper: data.docmper,
      docmtip: data.docmtip,
      docmser: data.docmser,
      docmdoc: data.docmdoc,
      docmflg: data.docmflg,
    };

    const token = context.rootGetters.token;
    try {
      const response = await fetch(
        `${API_DOMAIN}/documentos/generacion/FEL/${infodata.docmcia}/${infodata.docmper}/${infodata.docmtip}/${infodata.docmser}/${infodata.docmdoc}/${infodata.docmflg}`,
        {
          method: 'POST', //INSERT
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      );
      console.log(response);
      if (!response.ok) {
        // Manejar el error si la respuesta no es exitosa
        const errorData = await response.json();
        const error = new Error(
          errorData.mensaje ||
            'Error en la Generacion y Certificacion de Documentos.'
        );
        throw error;
      }

      // Manejar la respuesta exitosa
      const responseData = await response.json();
      console.log(responseData);

      // Asumiendo que responseData es el objeto que quieres utilizar en tu aplicación Vue.js
      return responseData;
    } catch (error) {
      console.error(error);
      // Aquí puedes decidir cómo manejar el error en tu aplicación Vue.js
      throw error;
    }
    //codigo comentariado por isaias 18122023
    /*const responseData = await response.json();
    console.log(responseData);
    if (!response.ok) {
      //console.log('ha ocurrido un error');
      const error = new Error(
        responseData.mensaje ||
          ' Error en la Generacion y Certificacion de Documentos.'
      );
      //console.log(error);
      throw error;
    }*/
  },
  async recoverFile(context, data) {
    const infodata = {
      docmcia: data.docmcia,
      docmuuid: data.docmuuid,
    };
    console.log(infodata);
    const token = context.rootGetters.token;

    // Número máximo de intentos
    const maxAttempts = 3;

    for (let attempt = 1; attempt <= maxAttempts; attempt++) {
      try {
        const response = await fetch(
          `${API_DOMAIN}/api/Factura/RecuperacionFile/${infodata.docmcia}/${infodata.docmuuid}`,
          {
            method: 'POST', //INSERT
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            },
          }
        );

        const responseData = await response.json();
        console.log(responseData);
        console.log(response);

        // Si la respuesta es exitosa (código 200), retornar los datos
        if (response.ok) {
          return {
            result: responseData.result,
            mensaje: responseData.mensaje,
            file: responseData.file,
          };
        } else {
          console.error(
            `Intento ${attempt}: Recibido código HTTP ${response.status}`
          );
        }
      } catch (error) {
        console.error(
          `Intento ${attempt}: Error en la solicitud: ${error.message}`
        );
      }

      // Esperar un tiempo antes de realizar el siguiente intento (puedes ajustar este valor)
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    // Si llega aquí, significa que se han agotado los intentos sin éxito
    return {
      result: false,
      mensaje: 'Se ha alcanzado el número máximo de intentos sin éxito',
      file: null,
    };
  },
  /****************************************************************************
   ********** RECUPERACION DE DETALLE DE DOCUMENTOS ESPECIFICOS ***************
   ****************************************************************************
   */
  async actualizaDoct(context, data) {
    const infodata = {
      docddes: null,
      docdbar: null,
      docdref: null,
      docdlin: null,
      docdvaldol: null,
      docdproesp: null,
      docdped: data.ped,
      docdprcuni: data.prcuni,
      docdvalproi: null,
      docdtip: data.tip,
      docdruta: data.ruta,
      docdorden: null,
      docdcte: data.cte,
      docdtipfac: null,
      docdreg: null,
      docdkl: null,
      docdprd: data.prd,
      docdtipevento: null,
      docddocprv: null,
      docdklb: null,
      docdper: data.per,
      docdcls: data.cls,
      docdprom: null,
      docdcu: null,
      docdvalcmp: null,
      docdfecped: data.fecped,
      docdctoultc: null,
      docdtexto: null,
      docdprom1: null,
      docdotr: null,
      docdcia: data.cia,
      docddoc: data.doc,
      docdgarf: null,
      docdntran: null,
      docdprecaj: data.precaj,
      docdfob: null,
      docddolcmp: null,
      docdbod: data.bod,
      docdfacmed: null,
      docdcstdol: data.cstdol,
      docdunivta: data.univta,
      docdprecaj1: data.precaj1,
      docdanio: null,
      docdprecaj2: data.precaj2,
      docdvprm: null,
      docdflete: null,
      docdvalpro: null,
      docdunimed: null,
      docdboda: null,
      docdemp2: null,
      docdemp1: null,
      docdconcen: null,
      docddcr: null,
      docdlot: data.lot,
      docdngar: null,
      docdvend: null,
      docdordinterna: null,
      docdmes: null,
      docdgar: null,
      docdcat: data.cat,
      docdnumfac: null,
      docdiva: data.iva,
      docdcstvta: null,
      docdseg: null,
      docdser: data.ser,
      docdvallin: data.vallin,
      docdflg: null,
      docdctoprome: null,
      docdnumlin: data.numlin,
      docdsublin: null,
      docdbodcon: null,
      docmgiro: null,
      docdctopromo: null,
      docdtda: null,
      docdenv: null,
      docdbonif: null,
      docdesc: data.desc,
      docdmod: null,
      docdmsg: null,
      docdcreg: null,
      docdbl: null,
      docdtipdes: null,
      docdfecdoc: data.fecdoc,
      docdpresp: null,
      docdtipped: null,
      docdcntvta: null,
      docdsalser: null,
      docdnomgen: null,
    };

    const token = context.rootGetters.token;
    const response = await fetch(
      `${API_DOMAIN}/api/mosi/docdt/${infodata.docdcia}/${infodata.docdper}/${infodata.docddoc}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          //cache: 'no-store',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify(infodata),
      }
    );
    const responseData = await response.json();
    console.log(responseData);
    if (!response.ok) {
      return {
        mensaje: response.statusText,
        codigo: 0, // Ejemplo de lógica para determinar el valor de "codigo"
      };
    } else {
      return {
        mensaje: responseData.docdt[0].mensaje,
        codigo: responseData.docdt[0].codigo,
      };
    }
  },
  async loadDetailBillxDoc(context, data) {
    //console.log(data);
    
    const infodata = {
      docmcia: data.docmcia,
      docmper: data.docmper,
      docmdoc: data.docmdoc,
    };

    /*console.log(
      'Inicio de Metodo de Recuperacion de detalle de factura...' +
        infodata.docmdoc
    );*/
    const token = context.rootGetters.token;
    const response = await fetch(
      `${API_DOMAIN}/api/mosi/docdt/${infodata.docmcia}/${infodata.docmper}/${infodata.docmdoc}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          //cache: 'no-store',
          Authorization: 'Bearer ' + token,
        },
      }
    );
    //console.log(response);
    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Error recuperando informacion.'
      );
      throw error;
    }
    const responseData = await response.json();

    const detalle = [];
    detalle.length = 0;
    //console.log(detalle);
    let detail = {};
    for (const key in responseData['docdt']) {
      detail = {
        docdprd: responseData['docdt'][key].docdprd,
        docdunivta: responseData['docdt'][key].docdunivta,
        docdnumlin: responseData['docdt'][key].docdnumlin,
        docdesc: responseData['docdt'][key].docdesc,
        docdprcuni: responseData['docdt'][key].docdprcuni,
        docdprecaj1: responseData['docdt'][key].docdprecaj1,
        docdiva: responseData['docdt'][key].docdiva,
        docdvallin: responseData['docdt'][key].docdvallin,
      };
      detalle.push(detail);
    }
    //console.log(detalle);
    /*context.commit('setDocdtxDoc', null);
    context.commit('setDocdtxDoc', detalle);
    context.commit('setFetchTimestamp')*/
    return detalle;
  },
  async loadBillingsMaster(context,formData) {
    
    console.log(
      'Inicio de Metodo de Recuperacion Maestro de Facturas...' 
    );

    const token = context.rootGetters.token;
    ////console.log(token);
    console.log(`${API_DOMAIN}/api/mosi/docmt/maestros/${formData.param1}/${formData.param2}`);

    const response = await fetch(`${API_DOMAIN}/api/mosi/docmt/maestros/${formData.param1}/${formData.param2}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        //cache: 'no-store',
        Authorization: 'Bearer ' + token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Error recuperando informacion.'
      );
      throw error;
    }
    const facturas = [];

    for (const key in responseData['docmt']) {
     

      
     
      try {
        const docmt = {
        docmcia: responseData['docmt'][key].docmcia,
        docmper: responseData['docmt'][key].docmper,
        docmtip: responseData['docmt'][key].docmtip,
        docmdoc: responseData['docmt'][key].docmdoc,
        docmtipped: responseData['docmt'][key].docmtipped,
        docmped: responseData['docmt'][key].docmped,
        docmfecped: responseData['docmt'][key].docmfecped,
        docmafe: responseData['docmt'][key].docmafe,
        docmcon: responseData['docmt'][key].docmcon,
        docmcte: responseData['docmt'][key].docmcte,
        docmruta: responseData['docmt'][key].docmruta,
        docmnom: responseData['docmt'][key].docmnom,
        docmdir: responseData['docmt'][key].docmdir,
        docmvalfle: responseData['docmt'][key].docmvalfle,
        docmklfle: responseData['docmt'][key].docmklfle,
        docmimp: responseData['docmt'][key].docmimp,
        docmiva: responseData['docmt'][key].docmiva,
        docmtot: Number.parseFloat(responseData['docmt'][key].docmtot).toFixed(
          2
        ),
        docmexe: responseData['docmt'][key].docmexe,
        docmmsg: responseData['docmt'][key].docmmsg,
        docmsalcte: responseData['docmt'][key].docmsalcte,
        docmlimcre: responseData['docmt'][key].docmlimcre,
        docmchq: responseData['docmt'][key].docmchq,
        docmflg: responseData['docmt'][key].docmflg,
        docmdes: responseData['docmt'][key].docmdes,
        docmfecdoc: responseData['docmt'][key].docmfecdoc,
        docmchqa: responseData['docmt'][key].docmchqa,
        docmant: responseData['docmt'][key].docmant,
        docmanta: responseData['docmt'][key].docmanta,
        docmmini: responseData['docmt'][key].docmmini,
        docmminia: responseData['docmt'][key].docmminia,
        docmvprm: responseData['docmt'][key].docmvprm,
        docmmrk: responseData['docmt'][key].docmmrk,
        docmklprd: responseData['docmt'][key].docmklprd,
        docmrate: responseData['docmt'][key].docmrate,
        docmcrr: responseData['docmt'][key].docmcrr,
        docmgra: responseData['docmt'][key].docmgra,
        docmxen: responseData['docmt'][key].docmxen,
        docmdol: responseData['docmt'][key].docmdol,
        docmenv: responseData['docmt'][key].docmenv,
        docmdcr: responseData['docmt'][key].docmdcr,
        docmnit: responseData['docmt'][key].docmnit,
        docmsbr: responseData['docmt'][key].docmsbr,
        docmvalpro: responseData['docmt'][key].docmvalpro,
        docmvalproi: responseData['docmt'][key].docmvalproi,
        docmvoucher: responseData['docmt'][key].docmvoucher,
        docmnumauto: responseData['docmt'][key].docmnumauto,
        docmtarcre: responseData['docmt'][key].docmtarcre,
        docmentdpper: responseData['docmt'][key].docmentdpper,
        docmentdir: responseData['docmt'][key].docmentdir,
        docmcrg: responseData['docmt'][key].docmcrg,
        docmtra: responseData['docmt'][key].docmtra,
        docmbodcon: responseData['docmt'][key].docmbodcon,
        docmsold: responseData['docmt'][key].docmsold,
        docmship: responseData['docmt'][key].docmship,
        docmord: responseData['docmt'][key].docmord,
        docmfecent: responseData['docmt'][key].docmfecent,
        docmurg: responseData['docmt'][key].docmurg,
        docmconv: responseData['docmt'][key].docmconv,
        docmsts: responseData['docmt'][key].docmsts,
        docmtda: responseData['docmt'][key].docmtda,
        docmser: responseData['docmt'][key].docmser,
        docmtent: responseData['docmt'][key].docmtent,
        docmtentotr:
          responseData['docmt'][key].docmtentotr == null
            ? ''
            : responseData['docmt'][key].docmtentotr,
        docmpn: responseData['docmt'][key].docmpn,
        docmpb: responseData['docmt'][key].docmpb,
        docmblts: responseData['docmt'][key].docmblts,
        docmcajas: responseData['docmt'][key].docmcajas,
        docmvia: responseData['docmt'][key].docmvia,
        docmtipbl: responseData['docmt'][key].docmtipbl,
        docmbl: responseData['docmt'][key].docmbl,
        docmemp: responseData['docmt'][key].docmemp,
        docmafeser: responseData['docmt'][key].docmafeser,
        docmuuid:
          responseData['docmt'][key].docmuuid == null
            ? responseData['docmt'][key].docmentdir
            : responseData['docmt'][key].docmuuid,
        docmfeldoc: responseData['docmt'][key].docmfeldoc,
        docmfelser: responseData['docmt'][key].docmfelser,
        docmfelfec: responseData['docmt'][key].docmfelfec,
        //items: responseData['docmt'][key].items,
        
        //items:  null//[]//await this.loadDetailBillxDoc(context, detail)

      };
     
      facturas.push(docmt);
      
      } catch (error) {
        console.log(error);
        this.error = error.message || 'algo salió mal';
        //this.isLoading = false;
      }
      
      //console.log(await this.loadDetailBillxDoc(context, detail))
      /*try {
        await this.loadDetailBillxDoc(context, facturas);
      } catch (error) {
        this.error = error.message || 'algo salió mal';
        this.isLoading = false;
      }*/
      
    }

    context.commit('setBillings', facturas);
    context.commit('setFetchTimestamp');
  },
  //comentado por Isaias A. el 28/12/2023 por pruebas con otra version del codigo
  // async recoverFile(context, data) {
  //   const infodata = {
  //     docmcia: data.docmcia,
  //     docmuuid: data.docmuuid,
  //   };
  //   console.log(infodata);
  //   const token = context.rootGetters.token;
  //   const response = await fetch(
  //     `${API_DOMAIN}/api/Factura/RecuperacionFile/${infodata.docmcia}/${infodata.docmuuid}`,
  //     {
  //       method: 'POST', //INSERT
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: 'Bearer ' + token,
  //       },
  //     }
  //   );

  //   const responseData = await response.json();
  //   console.log(responseData);
  //   console.log(response);
  //   if (responseData.result) {
  //     return {
  //       result: responseData.result,
  //       mensaje: responseData.mensaje,
  //       file: responseData.file,
  //     };
  //   } else {
  //     return {
  //       result: responseData.result,
  //       mensaje: responseData.mensaje,
  //       file: responseData.file,
  //     };
  //   }
  // },
};
