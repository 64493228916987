export default {
  /*****************************************************************
   ************ACTUALIZACION DE UNA CLIENTE ESPECIFICAMENTE***********
   ******************************************************************
   */
  async updateCusnew(context, data) {
    const infodata = {
      cuscia: data.cia,
      cuscod: data.cod,
      cusname: data.name,
      cusdir: data.dir,
      cuszona: data.zona,
      cusciudad: data.ciudad,
      custel: data.tel,
      cusfax: data.fax,
      cusnit: data.nit,
      cusfecing: data.fecing,
      cuslimcre: data.limcre,
      cusdiaven: data.diaven,
      cusruta: data.ruta,
      cusiva: data.iva,
      custipo: data.tipo,
      custipod: data.tipod,
      cusgrupo: data.grupo,
      cusgrupod: data.grupod,
      cuscarta: data.carta,
      cuscartav: data.cartav,
      cuscomp: data.comp,
      cusflg: data.flg,
      cusdepto: data.depto,
      cusrazon: data.razon,
      cusdiafac: data.diafac,
      cusdes: data.des,
      cusgiro: data.giro,
      cusctad: data.ctad,
      cusctad1: data.ctad1,
      cusprv: data.prv,
      cusrutco: data.rutco,
      cusnit1: data.nit1,
      cusruta2: data.ruta2,
      cusdiv: data.div,
      cusenc: data.enc,
      cusprop: data.prop,
      cusmail: data.mail,
      cusprec: data.prec,
      cuscls: data.cls,
      cuscodant: data.codant,
      cuscontac: data.contac,
      cusconsig: data.consig,
      cuscat: data.cat,
      cusuff: data.uff,
      cusufp: data.ufp,
      cusufc: data.ufc,
      cusmemo: data.memo,
      cusststda: data.ststda,
      custercom: data.tercom,
      cuspago: data.pago,
      cuscie: data.cie,
      cusentdir: data.entdir,
      cushorario: data.horario,
      cusordcom: data.ordcom,
      cusctafle: data.ctafle,
      cusctaseg: data.ctaseg,
      cusctagts: data.ctagts,
      cusauto: data.auto,
      cusmaster: data.master,
      cusmsgche: data.msgche,
      cusprecio: data.precio,
      cusidiomalet: data.idiomalet,
      cusimpprv: data.impprv,
      cusmsg1: data.msg1,
      cuscontacpto: data.contacpto,
      cusrepleg: data.repleg,
      cusgergen: data.gergen,
      cusdpi: data.dpi,
      cuspostal: data.postal,
      cusweb: data.web,
      cuscontado: data.contado,
      cuscredito: data.credito,
      cusbancos: data.bancos,
      cuspagchq: data.pagchq,
      cuspagtra: data.pagtra,
      cuspagotr: data.pagotr,
      cuspolcomp: data.polcomp,
      cuspolmarg: data.polmarg,
      custcind: data.tcind,
      custcdis: data.tcdis,
      custcmay: data.tcmay,
      custcexp: data.tcexp,
      custcotr: data.tcotr,
      cusnegest: data.negest,
      cusnegtec: data.negtec,
      cusrelemp: data.relemp,
      cusvision: data.vision,
      cusmision: data.mision,
      cusvalores: data.valores,
      cusobjetivos: data.objetivos,
      cusalianza: data.alianza,
      cuspromocion: data.promocion,
      cusimpulsar: data.impulsar,
      cuscolocar: data.colocar,
      cusrpotro: data.rpotro,
      cuspuntosventa: data.puntosventa,
      cussegmento: data.segmento,
      cusorientconsumo: data.orientconsumo,
      cusbboom: data.bboom,
      cuselderly: data.elderly,
      cusgenrex: data.genrex,
      custeens: data.teens,
      cusgenotro: data.genotro,
      cuscatfamch: data.catfamch,
      cuscatfamcp: data.catfamcp,
      cuscatfamotro: data.catfamotro,
      cuscamtrad: data.camtrad,
      cuscaminnov: data.caminnov,
      cuscamotro: data.camotro,
      cuspreferencia: data.preferencia,
      cusfrecom: data.frecom,
      cuslogistpv: data.logistpv,
      cuslogistcd: data.logistcd,
      cuslogistrec: data.logistrec,
      cuslogistotro: data.logistotro,
      cusdistribucion: data.distribucion,
      cusmargprecvta: data.margprecvta,
      cusctefre: data.ctefre,
      cusprodvta: data.prodvta,
      cuspromvta: data.promvta,
      cusmedvta: data.medvta,
      cusruteros: data.ruteros,
      custiendas: data.tiendas,
      cusrealventas: data.realventas,
      cusregiones: data.regiones,
    };
    const token = context.rootGetters.token;
    //console.log('Envio de informacion para realizar UPDATE en Oracle');
    console.log(JSON.stringify(infodata));
    console.log(
      `https://192.9.200.125:81/custab_nuevos/${infodata.cuscia}/${infodata.cusnit}/${infodata.cuscod}`
    );
    const response = fetch(
      `https://192.9.200.125:81/custab_nuevos/${infodata.cuscia}/${infodata.cusnit}/${infodata.cuscod}`,
      {
        method: 'PUT', //INSERT
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify(infodata),
      }
    );

    //const responseData = await response.json();
    if (!response.ok) {
      //hay un error
    }
    const cusnews = [];

    context.commit('insertCusnew', {
      ...infodata,
      cusnit: data.cusnit,
    });
    context.commit('setCusnew', cusnews);
  },
  /*****************************************************************
   ************ELIMINACION DE UNA CLIENTE ESPECIFICAMENTE***********
   *****************************************************************
   */
  async deleteCusnew(context, data) {
    const cusnewData = {
      cuscia: data.cia,
      cusnit: data.nit,
      cuscod: data.cod,
    };

    const token = context.rootGetters.token;
    //console.log(token);
    //console.log(cusnewData);
    const raw = '';
    console.log(
      `https://192.9.200.125:81/custab_nuevos/${cusnewData.cuscia}/${cusnewData.cusnit}/${cusnewData.cuscod}`
    );
    const response = fetch(
      `https://192.9.200.125:81/custab_nuevos/${cusnewData.cuscia}/${cusnewData.cusnit}/${cusnewData.cuscod}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: raw,
      }
    );

    //console.log(response);
    if (!response.ok) {
      //..
    }

    context.commit('deleteCusnew', {
      ...cusnewData,
      cusnit: cusnewData.cusnit,
    });
  },
  /*****************************************************************
   ************INSERCCION DE UNA NUEVO CLIENTE**********************
   *****************************************************************
   */
  async insertCusnew(context, data) {
    const infodata = {
      cuscia: data.cia,
      cuscod: data.cod,
      cusname: data.name,
      cusdir: data.dir,
      cuszona: data.zona,
      cusciudad: data.ciudad,
      custel: data.tel,
      cusfax: data.fax,
      cusnit: data.nit,
      cusfecing: data.fecing,
      cuslimcre: data.limcre,
      cusdiaven: data.diaven,
      cusruta: data.ruta,
      cusiva: data.iva,
      custipo: data.tipo,
      custipod: data.tipod,
      cusgrupo: data.grupo,
      cusgrupod: data.grupod,
      cuscarta: data.carta,
      cuscartav: data.cartav,
      cuscomp: data.comp,
      cusflg: data.flg,
      cusdepto: data.depto,
      cusrazon: data.razon,
      cusdiafac: data.diafac,
      cusdes: data.des,
      cusgiro: data.giro,
      cusctad: data.ctad,
      cusctad1: data.ctad1,
      cusprv: data.prv,
      cusrutco: data.rutco,
      cusnit1: data.nit1,
      cusruta2: data.ruta2,
      cusdiv: data.div,
      cusenc: data.enc,
      cusprop: data.prop,
      cusmail: data.mail,
      cusprec: data.prec,
      cuscls: data.cls,
      cuscodant: data.codant,
      cuscontac: data.contac,
      cusconsig: data.consig,
      cuscat: data.cat,
      cusuff: data.uff,
      cusufp: data.ufp,
      cusufc: data.ufc,
      cusmemo: data.memo,
      cusststda: data.ststda,
      custercom: data.tercom,
      cuspago: data.pago,
      cuscie: data.cie,
      cusentdir: data.entdir,
      cushorario: data.horario,
      cusordcom: data.ordcom,
      cusctafle: data.ctafle,
      cusctaseg: data.ctaseg,
      cusctagts: data.ctagts,
      cusauto: data.auto,
      cusmaster: data.master,
      cusmsgche: data.msgche,
      cusprecio: data.precio,
      cusidiomalet: data.idiomalet,
      cusimpprv: data.impprv,
      cusmsg1: data.msg1,
      cuscontacpto: data.contacpto,
      cusrepleg: data.repleg,
      cusgergen: data.gergen,
      cusdpi: data.dpi,
      cuspostal: data.postal,
      cusweb: data.web,
      cuscontado: data.contado,
      cuscredito: data.credito,
      cusbancos: data.bancos,
      cuspagchq: data.pagchq,
      cuspagtra: data.pagtra,
      cuspagotr: data.pagotr,
      cuspolcomp: data.polcomp,
      cuspolmarg: data.polmarg,
      custcind: data.tcind,
      custcdis: data.tcdis,
      custcmay: data.tcmay,
      custcexp: data.tcexp,
      custcotr: data.tcotr,
      cusnegest: data.negest,
      cusnegtec: data.negtec,
      cusrelemp: data.relemp,
      cusvision: data.vision,
      cusmision: data.mision,
      cusvalores: data.valores,
      cusobjetivos: data.objetivos,
      cusalianza: data.alianza,
      cuspromocion: data.promocion,
      cusimpulsar: data.impulsar,
      cuscolocar: data.colocar,
      cusrpotro: data.rpotro,
      cuspuntosventa: data.puntosventa,
      cussegmento: data.segmento,
      cusorientconsumo: data.orientconsumo,
      cusbboom: data.bboom,
      cuselderly: data.elderly,
      cusgenrex: data.genrex,
      custeens: data.teens,
      cusgenotro: data.genotro,
      cuscatfamch: data.catfamch,
      cuscatfamcp: data.catfamcp,
      cuscatfamotro: data.catfamotro,
      cuscamtrad: data.camtrad,
      cuscaminnov: data.caminnov,
      cuscamotro: data.camotro,
      cuspreferencia: data.preferencia,
      cusfrecom: data.frecom,
      cuslogistpv: data.logistpv,
      cuslogistcd: data.logistcd,
      cuslogistrec: data.logistrec,
      cuslogistotro: data.logistotro,
      cusdistribucion: data.distribucion,
      cusmargprecvta: data.margprecvta,
      cusctefre: data.ctefre,
      cusprodvta: data.prodvta,
      cuspromvta: data.promvta,
      cusmedvta: data.medvta,
      cusruteros: data.ruteros,
      custiendas: data.tiendas,
      cusrealventas: data.realventas,
      cusregiones: data.regiones, //verificar porque se seleccionan los dos checkbox y ademas vanda el value de true o false
      //cuando debe de enviar S o N
    };

    const token = context.rootGetters.token;
    //console.log('Envio de informacion para realizar insert en Oracle');
    //console.log(JSON.stringify(infodata));
    //console.log(token);
    const response = fetch(
      `https://192.9.200.125:81/custab_nuevos/${infodata.cuscia}/${infodata.cusnit}/${infodata.cuscod}`,
      {
        method: 'POST', //INSERT
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify(infodata),
      }
    );
    console.log('///////////////////////////////////////////////////////');
    console.log(response);
    console.log('///////////////////////////////////////////////////////');
    //const responseData = await response.json();
    if (!response.ok) {
      //hay un error
    }

    context.commit('insertCusnew', {
      ...infodata,
      cusnit: data.cusnit,
    });
  },
  /*******************************************************************
   ************RECUPERACION DE TODOS LOS CLIENTES*********************
   *******************************************************************
   */
  dateFormatOracle(fecha) {
    const date = new Date(fecha);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }
    return dt + '/' + month + '/' + year;
  },
  async loadCusnew(context, cuscia) {
    //console.log('Inicio de Metodo de Recuperacion de Clientes.');
    const token = context.rootGetters.token;
    //console.log(token);

    const response = await fetch(
      `https://192.9.200.125:81/custab_nuevos/data/${cuscia}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      }
    );

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Error recuperando informacion.'
      );
      throw error;
    }
    const cusnews = [];
    for (const key in responseData['custab_nuevos']) {
      const custab_nuevos = {
        cuscia: responseData['custab_nuevos'][key].cuscia,
        cuscod: responseData['custab_nuevos'][key].cuscod,
        cusname: responseData['custab_nuevos'][key].cusname,
        cusdir: responseData['custab_nuevos'][key].cusdir,
        cuszona: responseData['custab_nuevos'][key].cuszona,
        cusciudad: responseData['custab_nuevos'][key].cusciudad,
        custel: responseData['custab_nuevos'][key].custel,
        cusfax: responseData['custab_nuevos'][key].cusfax,
        cusnit: responseData['custab_nuevos'][key].cusnit,
        cusfecing: responseData['custab_nuevos'][key].cusfecing.replace(
          ' 00:00:00',
          ''
        ),
        cuslimcre: responseData['custab_nuevos'][key].cuslimcre,
        cusdiaven: responseData['custab_nuevos'][key].cusdiaven,
        cusruta: responseData['custab_nuevos'][key].cusruta,
        cusiva: responseData['custab_nuevos'][key].cusiva,
        custipo: responseData['custab_nuevos'][key].custipo,
        custipod: responseData['custab_nuevos'][key].custipod,
        cusgrupo: responseData['custab_nuevos'][key].cusgrupo,
        cusgrupod: responseData['custab_nuevos'][key].cusgrupod,
        cuscarta: responseData['custab_nuevos'][key].cuscarta,
        cuscartav: responseData['custab_nuevos'][key].cuscartav,
        cuscomp: responseData['custab_nuevos'][key].cuscomp,
        cusflg: responseData['custab_nuevos'][key].cusflg,
        cusdepto: responseData['custab_nuevos'][key].cusdepto,
        cusrazon: responseData['custab_nuevos'][key].cusrazon,
        cusdiafac: responseData['custab_nuevos'][key].cusdiafac,
        cusdes: responseData['custab_nuevos'][key].cusdes,
        cusgiro: responseData['custab_nuevos'][key].cusgiro,
        cusctad: responseData['custab_nuevos'][key].cusctad,
        cusctad1: responseData['custab_nuevos'][key].cusctad1,
        cusprv: responseData['custab_nuevos'][key].cusprv,
        cusrutco: responseData['custab_nuevos'][key].cusrutco,
        cusnit1: responseData['custab_nuevos'][key].cusnit1,
        cusruta2: responseData['custab_nuevos'][key].cusruta2,
        cusdiv: responseData['custab_nuevos'][key].cusdiv,
        cusenc: responseData['custab_nuevos'][key].cusenc,
        cusprop: responseData['custab_nuevos'][key].cusprop,
        cusmail: responseData['custab_nuevos'][key].cusmail,
        cusprec: responseData['custab_nuevos'][key].cusprec,
        cuscls: responseData['custab_nuevos'][key].cuscls,
        cuscodant: responseData['custab_nuevos'][key].cuscodant,
        cuscontac: responseData['custab_nuevos'][key].cuscontac,
        cusconsig: responseData['custab_nuevos'][key].cusconsig,
        cuscat: responseData['custab_nuevos'][key].cuscat,
        cusuff: responseData['custab_nuevos'][key].cusuff,
        cusufp: responseData['custab_nuevos'][key].cusufp,
        cusufc: responseData['custab_nuevos'][key].cusufc,
        cusmemo: responseData['custab_nuevos'][key].cusmemo,
        cusststda: responseData['custab_nuevos'][key].cusststda,
        custercom: responseData['custab_nuevos'][key].custercom,
        cuspago: responseData['custab_nuevos'][key].cuspago,
        cuscie: responseData['custab_nuevos'][key].cuscie,
        cusentdir: responseData['custab_nuevos'][key].cusentdir,
        cushorario: responseData['custab_nuevos'][key].cushorario,
        cusordcom: responseData['custab_nuevos'][key].cusordcom,
        cusctafle: responseData['custab_nuevos'][key].cusctafle,
        cusctaseg: responseData['custab_nuevos'][key].cusctaseg,
        cusctagts: responseData['custab_nuevos'][key].cusctagts,
        cusauto: responseData['custab_nuevos'][key].cusauto,
        cusmaster: responseData['custab_nuevos'][key].cusmaster,
        cusmsgche: responseData['custab_nuevos'][key].cusmsgche,
        cusprecio: responseData['custab_nuevos'][key].cusprecio,
        cusidiomalet: responseData['custab_nuevos'][key].cusidiomalet,
        cusimpprv: responseData['custab_nuevos'][key].cusimpprv,
        cusmsg1: responseData['custab_nuevos'][key].cusmsg1,
        cuscontacpto: responseData['custab_nuevos'][key].cuscontacpto,
        cusrepleg: responseData['custab_nuevos'][key].cusrepleg,
        cusgergen: responseData['custab_nuevos'][key].cusgergen,
        cusdpi: responseData['custab_nuevos'][key].cusdpi,
        cuspostal: responseData['custab_nuevos'][key].cuspostal,
        cusweb: responseData['custab_nuevos'][key].cusweb,
        cuscontado: responseData['custab_nuevos'][key].cuscontado,
        cuscredito: responseData['custab_nuevos'][key].cuscredito,
        cusbancos: responseData['custab_nuevos'][key].cusbancos,
        cuspagchq: responseData['custab_nuevos'][key].cuspagchq,
        cuspagtra: responseData['custab_nuevos'][key].cuspagtra,
        cuspagotr: responseData['custab_nuevos'][key].cuspagotr,
        cuspolcomp: responseData['custab_nuevos'][key].cuspolcomp,
        cuspolmarg: responseData['custab_nuevos'][key].cuspolmarg,
        custcind: responseData['custab_nuevos'][key].custcind,
        custcdis: responseData['custab_nuevos'][key].custcdis,
        custcmay: responseData['custab_nuevos'][key].custcmay,
        custcexp: responseData['custab_nuevos'][key].custcexp,
        custcotr: responseData['custab_nuevos'][key].custcotr,
        cusnegest: responseData['custab_nuevos'][key].cusnegest,
        cusnegtec: responseData['custab_nuevos'][key].cusnegtec,
        cusrelemp: responseData['custab_nuevos'][key].cusrelemp,
        cusvision: responseData['custab_nuevos'][key].cusvision,
        cusmision: responseData['custab_nuevos'][key].cusmision,
        cusvalores: responseData['custab_nuevos'][key].cusvalores,
        cusobjetivos: responseData['custab_nuevos'][key].cusobjetivos,
        cusalianza: responseData['custab_nuevos'][key].cusalianza,
        cuspromocion: responseData['custab_nuevos'][key].cuspromocion,
        cusimpulsar: responseData['custab_nuevos'][key].cusimpulsar,
        cuscolocar: responseData['custab_nuevos'][key].cuscolocar,
        cusrpotro: responseData['custab_nuevos'][key].cusrpotro,
        cuspuntosventa: responseData['custab_nuevos'][key].cuspuntosventa,
        cussegmento: responseData['custab_nuevos'][key].cussegmento,
        cusorientconsumo: responseData['custab_nuevos'][key].cusorientconsumo,
        cusbboom: responseData['custab_nuevos'][key].cusbboom,
        cuselderly: responseData['custab_nuevos'][key].cuselderly,
        cusgenrex: responseData['custab_nuevos'][key].cusgenrex,
        custeens: responseData['custab_nuevos'][key].custeens,
        cusgenotro: responseData['custab_nuevos'][key].cusgenotro,
        cuscatfamch: responseData['custab_nuevos'][key].cuscatfamch,
        cuscatfamcp: responseData['custab_nuevos'][key].cuscatfamcp,
        cuscatfamotro: responseData['custab_nuevos'][key].cuscatfamotro,
        cuscamtrad: responseData['custab_nuevos'][key].cuscamtrad,
        cuscaminnov: responseData['custab_nuevos'][key].cuscaminnov,
        cuscamotro: responseData['custab_nuevos'][key].cuscamotro,
        cuspreferencia: responseData['custab_nuevos'][key].cuspreferencia,
        cusfrecom: responseData['custab_nuevos'][key].cusfrecom,
        cuslogistpv: responseData['custab_nuevos'][key].cuslogistpv,
        cuslogistcd: responseData['custab_nuevos'][key].cuslogistcd,
        cuslogistrec: responseData['custab_nuevos'][key].cuslogistrec,
        cuslogistotro: responseData['custab_nuevos'][key].cuslogistotro,
        cusdistribucion: responseData['custab_nuevos'][key].cusdistribucion,
        cusmargprecvta: responseData['custab_nuevos'][key].cusmargprecvta,
        cusctefre: responseData['custab_nuevos'][key].cusctefre,
        cusprodvta: responseData['custab_nuevos'][key].cusprodvta,
        cuspromvta: responseData['custab_nuevos'][key].cuspromvta,
        cusmedvta: responseData['custab_nuevos'][key].cusmedvta,
        cusruteros: responseData['custab_nuevos'][key].cusruteros,
        custiendas: responseData['custab_nuevos'][key].custiendas,
        cusrealventas: responseData['custab_nuevos'][key].cusrealventas,
        cusregiones: responseData['custab_nuevos'][key].cusregiones,
        vendedor: '',
        telefonos:
          (responseData['custab_nuevos'][key].custel == null
            ? ''
            : responseData['custab_nuevos'][key].custel + ' / ') +
          (responseData['custab_nuevos'][key].cusfax == null
            ? ''
            : responseData['custab_nuevos'][key].cusfax),
      };
      //this.selectCliente.cuscod == null ? 0 : this.selectCliente.cuscod;

      //console.log(custab_nuevos);
      cusnews.push(custab_nuevos);
    }

    context.commit('setCusnew', cusnews);
    context.commit('setFetchTimestamp');
  },
};
