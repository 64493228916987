export default {
  listvalues(state) {
    return state.listvalues;
  },
  hasListvalues(state) {
    //sirve para verificar si tenemos valores por mostrar.
    return state.listvalues && state.listvalues['listvalues'].length > 0;
  },

  hasListvaluesMensajeros(state) {
    //sirve para verificar si tenemos valores por mostrar.
    return (
      state.listvalues && state.listvalues['listvaluesmensajeros'].length > 0
    );
  },
  hasListvaluesCostos(state) {
    //sirve para verificar si tenemos valores por mostrar.
    return (
      state.listvalues && state.listvalues['listvaluescostos'].length > 0
    );
  },
  hasListvaluesPrioridades(state) {
    //sirve para verificar si tenemos valores por mostrar.
    return (
      state.listvalues && state.listvalues['listvaluesprioridades'].length > 0
    );
  },
  hasTazaCambio(state) {
    //sirve para verificar si tenemos valores por mostrar.
    return state.listvalues && state.listvalues['tazacambio'].length > 0;
  },
  isListvalues(_, getters) {
    const listvaluess = getters.listvalues;
    console.log(listvaluess['listvalues']);

    const userId = getters.listvalues['listvalues'].moncod;
    return listvaluess['listvalues'].some(
      (listvalues) => listvalues.id === userId
    );
  },
  shouldUpdate(state) {
    const lastFetch = state.lastFetch;
    if (!lastFetch) {
      return true;
    }
    const currentTimeStamp = new Date().getTime();
    return (currentTimeStamp - lastFetch) / 1000 > 60;
  },

  Contadores(state) {
    return state.listvalues;
  },
  hasContadores(state) {
    //sirve para verificar si tenemos valores por mostrar.
    return state.listvalues && state.listvalues['contadores'].length > 0;
  },
};
