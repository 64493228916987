<template>
  <div v-if="isLoggedIn" class="card">
    <div class="card">
      <Toast />
      <form @submit.prevent="save">
        <Toolbar
          ><template v-slot:right
            ><Button
              label="Guardar"
              icon="pi pi-save"
              @click="submitForm()"
              class="p-button-raised p-button-rounded" /></template
        ></Toolbar>

        <Divider align="left"
          ><div class="p-field"></div>
          <div class="p-d-inline-flex p-ai-center">
            <i class="pi pi-id-card p-mr-1"></i>
            <h3>Generales</h3>
          </div>
        </Divider>
        <div class="p-grid p-fluid"></div>
        <div class="p-formgroup-inline">
          <div class="p-field">
            <div class="form-control" :class="{ invalid: !delcia.isValida }">
              <span class="p-float-label">
                <AutoComplete
                  v-model="delcia.val"
                  @blur="clearValidity('delcia')"
                  :dropdown="true"
                  size="35"
                  placeholder="Seleccione una compañia"
                  :suggestions="filteredCiatab"
                  @complete="searchCiatab($event)"
              /></span>
            </div>
          </div>
          <div class="p-field">
            <div class="form-control" :class="{ invalid: !deldoc.isValida }">
              <b>
                <span class="p-float-label">
                  <InputNumber
                    id="deldoc"
                    v-model="deldoc.val"
                    :useGrouping="false"
                  />
                  <label for="deldoc">No. de Pedido</label>
                </span></b
              >
            </div>
          </div>
          <div class="p-field">
            <span class="p-float-label">
              <AutoComplete
                :dropdown="true"
                v-model="delprioridad.val"
                size="35"
                @blur="clearValidity('delprioridad')"
                :suggestions="filteredPrioridades"
                @complete="searchPrioridad($event)"
                placeholder="Seleccione la prioridad del envio"
            /></span>
          </div>
          <div class="p-field"></div>
        </div>
        <div>
          <br />
          <br />
        </div>
        <div class="p-formgroup-inline">
          <div class="p-field">
            <b
              ><span class="p-float-label">
                <AutoComplete
                  :dropdown="true"
                  size="35"
                  v-model="delmens.val"
                  @blur="clearValidity('delmens')"
                  :suggestions="filteredMensajeros"
                  @complete="searchMensajeros($event)"
                  placeholder="Seleccione un Agente de Logística" /></span
            ></b>
          </div>
          <div class="p-field">
            <b
              ><span class="p-float-label">
                <Calendar
                  id="delfecdoc"
                  v-model="delfecdoc.val"
                  :showIcon="false"
                  date-format="dd/mm/yy"
                />
                <label for="delfecdoc">Fecha de Envio</label>
              </span></b
            >
          </div>
        </div>
        <br />
        <div class="p-formgroup-inline">
          <div class="form-control" :class="{ invalid: !delnom.isValida }">
            <b>
              <span class="p-float-label">
                <InputText
                  id="delnom"
                  type="text"
                  @blur="clearValidity('delnom')"
                  size="100"
                  :maxlength="100"
                  v-model="delnom.val"
                />
                <label for="delnom">Nombre de quien recibe</label>
              </span>
            </b>
            <p v-if="!delnom.isValida"></p>
          </div>
        </div>
        <div>
          <br />
          <br />
        </div>
        <div class="p-formgroup-inline">
          <div class="form-control" :class="{ invalid: !deldir.isValida }">
            <b>
              <span class="p-float-label">
                <Textarea
                  id="deldir"
                  type="text"
                  @blur="clearValidity('deldir')"
                  rows="2"
                  :autoResize="false"
                  :maxlength="200"
                  v-model="deldir.val"
                  cols="110"
                />

                <label for="deldir">Dirección de Entrega</label>
              </span></b
            >
            <p v-if="!deldir.isValida">
              Debe ingresar un valor para el dirección ó contiene caracteres
              invalidos (/)
            </p>
          </div>
        </div>
        <div>
          <br />
          <br />
        </div>
        <div class="p-formgroup-inline">
          <div class="form-control">
            <b
              ><span class="p-float-label">
                <InputText
                  id="delnit"
                  type="text"
                  size="30"
                  :maxlength="30"
                  v-model="delnit"
                />

                <label for="delnit">NIT</label>
              </span></b
            >
          </div>
          <div class="form-control">
            <b
              ><span class="p-float-label"
                ><InputText
                  id="deltel"
                  type="text"
                  rows="5"
                  :maxlength="30"
                  v-model="deltel"
                />

                <label for="deltel">Teléfono de Contacto</label>
              </span></b
            >
          </div>
        </div>
        <div>
          <br />
          <br />
        </div>
        <div class="p-formgroup-inline">
          <div class="form-control">
            <b>
              <span class="p-float-label"
                ><InputNumber
                  id="delbultos"
                  type="text"
                  size="20"
                  :maxlength="5"
                  v-model="delbultos.val"
                />

                <label for="delbultos">Cantidad de Bultos</label>
              </span>
            </b>
            <div>
              <br />
            </div>
            <div class="p-field">
              <div class="form-control"></div>
            </div>
          </div>
        </div>
        <div class="p-formgroup-inline">
          <div class="form-control">
            <span class="p-float-label">
              <b>
                <Textarea
                  v-model="delmmsg"
                  placeholder="Ingresa observaciones sobre este envio"
                  :autoResize="true"
                  rows="5"
                  :maxlength="1000"
                  cols="110"
              /></b>
            </span>
          </div>
        </div>
        <p v-if="!formIsValida">
          Por favor revisar que los campos se encuentren llenos y probar
          nuevamente.
        </p>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['save-data'],
  data() {
    return {
      formIsValida: true,
      contador: 0,
      displayPosition: false,
      error: null,

      ciatab: null,
      filteredCiatab: null,
      mensajeros: null,
      filteredMensajeros: null,
      prioridades: null,
      filteredPrioridades: null,

      isLoading: false,
      delcia: { val: '1 - LUPNOVA', isValida: true },
      deldoc: { val: 0, isValida: true },
      delmens: { val: '', isValida: true },
      delfecdoc: { val: '', isValida: true },
      delnom: { val: '', isValida: true },
      deldir: { val: '', isValida: true },
      delnit: '',
      deltel: '',
      dellatitud: '',
      dellongitud: '',
      delbultos: { val: null },
      delprioridad: { val: '', isValida: true },
      delflg: '',
      deldispositivo: '',
      delfirma: '',
      deluuid: '',
      delfelfec: '',
      delfeldoc: '',
      delfelser: '',
      delmmsg: '',
    };
  },
  created() {
    this.loadListValuesCiatab(true);
    this.ciatab = this.listValuesCiatab;
    this.loadListValuesMensajeros(true);
    this.mensajeros = this.listValuesMensajeros;
    this.loadListValuesPrioridades(true);
    console.log(
      this.$store.getters['listvalues/listvalues']['listvaluesprioridades']
    );
    this.prioridades = this.listValuesPrioridades;
    setTimeout(() => {
      while (this.ciatab.length === 0) {
        console.log('estoy aca ciatab');
        if (this.ciatab.length === 0 || this.ciatab == null) {
          this.loadListValuesCiatab(true);
          this.ciatab = this.listValuesCiatab;
        }
        this.contador++;
        if (this.contador == 3) {
          break;
        }
      }

      while (this.mensajeros.length === 0) {
        console.log('estoy aca mensajeros');
        if (this.mensajeros.length === 0 || this.mensajeros == null) {
          this.loadListValuesMensajeros(true);
          this.mensajeros = this.listValuesMensajeros;
        }
        this.contador++;
        if (this.contador == 5) {
          break;
        }
      }

      while (this.prioridades.length === 0) {
        console.log('estoy aca prioridadesa');
        if (this.prioridades.length === 0 || this.prioridades == null) {
          this.loadListValuesPrioridades(true);
          this.prioridades = this.listValuesPrioridades;
        }
        this.contador++;
        if (this.contador == 5) {
          break;
        }
      }
    }, 900);
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    listValuesCiatab() {
      try {
        const ciatab =
          this.$store.getters['listvalues/listvalues']['listvalues'];
        return ciatab.filter((ciatab) => {
          if (ciatab.ciacod != 0) {
            return true;
          }
          if (ciatab.ciacod != 0) {
            return true;
          }

          return true;
        });
      } catch (error) {
        console.log(error.messages);
        return true;
      }
    },
    listValuesMensajeros() {
      try {
        console.log(
          this.$store.getters['listvalues/listvalues']['listvaluesmensajeros']
        );
        const mensajeros =
          this.$store.getters['listvalues/listvalues']['listvaluesmensajeros'];
        return mensajeros.filter((mensajeros) => {
          if (mensajeros.menscod != 0) {
            return true;
          }
          if (mensajeros.menscod != 0) {
            return true;
          }
          console.log(mensajeros);

          return true;
        });
      } catch (error) {
        console.log(error.messages);
        return true;
      }
    },
    listValuesPrioridades() {
      try {
        const prioridades =
          this.$store.getters['listvalues/listvalues']['listvaluesprioridades'];
        return prioridades.filter((prioridades) => {
          if (prioridades.pricod != 0) {
            return true;
          }
          if (prioridades.pricod != 0) {
            return true;
          }

          return true;
        });
      } catch (error) {
        console.log(error.messages);
        return true;
      }
    },
  },
  methods: {
    handleError() {
      this.error = null;
    },
    openPosition(position) {
      this.position = position;
      this.displayPosition = true;
    },
    dateFormatOracle(fecha) {
      const date = new Date(fecha);
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();

      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }
      return dt + '/' + month + '/' + year;
    },
    validForm() {
      this.formIsValida = true;

      if (this.delcia.val === '') {
        this.formIsValida = false;
        this.delcia.isValida = false;
      }
      if (this.deldoc.val === 0) {
        this.formIsValida = false;
        this.deldoc.isValida = false;
      }
      if (this.delmens.val === '') {
        this.formIsValida = false;
        this.delmens.isValida = false;
      }
      if (this.delfecdoc.val === '') {
        this.formIsValida = false;
        this.delfecdoc.isValida = false;
      }
      if (this.delnom.val === '') {
        this.formIsValida = false;
        this.delnom.isValida = false;
      }
      if (this.deldir.val === '') {
        this.formIsValida = false;
        this.deldir.isValida = false;
      }
      if (this.delprioridad.val === '') {
        this.formIsValida = false;
        this.delprioridad.isValida = false;
      }
    },
    submitForm() {
      this.validForm();

      if (!this.formIsValida) {
        this.$toast.add({
          severity: 'error',
          summary: 'Campos Incorrectos',
          detail: 'Por favor revisar que los campos se encuentren correctos',
          life: 3000,
        });
        return;
      }
      const dateEntrega = this.dateFormatOracle(
        this.delfecdoc.val.toISOString()
      );
      console.log(this.delfecdoc.val.toISOString());
      const formData = {
        cia: this.delcia.val.substr(0, this.delcia.val.indexOf('-') - 1),
        doc: this.deldoc.val,
        mens: this.delmens.val.substr(0, this.delmens.val.indexOf('-') - 1),
        fecdoc: dateEntrega.replace('/', ''),
        nom: this.delnom.val,
        dir: this.deldir.val,
        nit: this.delnit === '' ? 'CF' : this.delnit,
        tel: this.deltel,
        latitud: this.dellatitud,
        longitud: this.dellongitud,
        bultos: this.delbultos.val,
        prioridad: this.delprioridad.val.substr(
          0,
          this.delprioridad.val.indexOf('-') - 1
        ),
        flg: 'S',
        dispositivo: this.deldispositivo,
        firma: this.delfirma,
        uuid: this.deluuid,
        felfec: this.delfelfec,
        feldoc: this.delfeldoc,
        felser: this.delfelser,
        mmsg: this.delmmsg,
      };

      setTimeout(() => {
        this.$emit('save-data', formData, null);
      }, 500);
    },

    clearValidity(input) {
      this[input].isValida = true;
    },
    searchMensajeros(event) {
      const mensajero = [];
      console.log(this.mensajeros.length);
      this.mensajeros = this.listValuesMensajeros;

      if (this.mensajeros.length === 0 || this.mensajeros === null) {
        console.log('tuve que refrescar mensajeros');
        this.loadListValuesMensajeros(true);
        this.mensajeros = this.listValuesMensajeros;
      }
      setTimeout(() => {
        for (const key in this.mensajeros) {
          mensajero.push(
            this.mensajeros[key].menscod + ' - ' + this.mensajeros[key].mensnom
          );
        }
        if (!event.query.trim().length) {
          this.filteredMensajeros = [...mensajero];
        } else {
          this.filteredMensajeros = mensajero.filter((mensnom) => {
            return mensnom.toLowerCase().includes(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    searchCiatab(event) {
      const companias = [];
      setTimeout(() => {
        for (const key in this.ciatab) {
          companias.push(
            this.ciatab[key].ciacod + ' - ' + this.ciatab[key].cianombre
          );
        }
        if (!event.query.trim().length) {
          this.filteredCiatab = [...companias];
        } else {
          this.filteredCiatab = companias.filter((country) => {
            return country.toLowerCase().includes(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    searchPrioridad(event) {
      console.log(this.prioridades.length);
      this.prioridades = this.listValuesPrioridades;

      if (this.prioridades.length === 0 || this.prioridades === null) {
        console.log('tuve que refrescar prioridades');
        this.loadListValuesPrioridades(true);
        this.prioridades = this.listValuesPrioridades;
      }
      const prioridades = [];
      //const prioridades = this.prioridades;
      setTimeout(() => {
        for (const key in this.prioridades) {
          prioridades.push(
            this.prioridades[key].pricod + ' - ' + this.prioridades[key].pridesc
          );
        }
        if (!event.query.trim().length) {
          this.filteredPrioridades = [...prioridades];
        } else {
          this.filteredPrioridades = prioridades.filter((pridesc) => {
            return pridesc.toLowerCase().includes(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    async loadListValuesMensajeros(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('listvalues/loadListValuesMensajeros', {
          forceRefresh: refresh,
        }); //viene de la clases action.js G
      } catch (error) {
        this.error = error.message || 'algo salió mal';
        console.log(error.message);
      }
      this.isLoading = false;
    },
    async loadListValuesCiatab(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('listvalues/loadListValuesCiatab', {
          forceRefresh: refresh,
        }); //viene de la clases action.js G
      } catch (error) {
        this.error = error.message || 'algo salió mal';
        console.log(error.message);
      }
      this.isLoading = false;
    },
    async loadListValuesPrioridades(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('listvalues/loadListValuesPrioridades', {
          forceRefresh: refresh,
        }); //viene de la clases action.js G
      } catch (error) {
        this.error = error.message || 'algo salió mal';
        console.log(error.message);
      }
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
input {
  font-weight: bold;
}

.center {
  border: 5px solid #ffffff;
  text-align: center;
}
.form-control {
  margin: 0.5rem 0;
}

Calendar {
  color: #000000;
}

input[type='inputnumber'] {
  font-weight: bold;
}

input[type='checkbox'] + label {
  font-weight: normal;
  display: inline;
  margin: 0 0 0 0.5rem;
}

label {
  font-weight: bold;
  color: #aaaaaa;
  margin-bottom: 0.5rem;
}
InputNumber {
  font-weight: bold;
}

input,
textarea {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  font: inherit;
}

input:focus,
textarea:focus {
  background-color: #f0e6fd;
  outline: none;
  border-color: #3d008d;
}

input[type='checkbox'] {
  display: inline;
  width: auto;
  border: none;
}

input[type='checkbox']:focus {
  outline: #3d008d solid 1px;
}

h3 {
  margin: 0.5rem 0;
  font-size: 1rem;
}

.invalid label {
  color: red;
}

.invalid input,
.invalid textarea {
  border: 1px solid red;
}
</style>
