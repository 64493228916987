export default {
  deliveries(state) {
    return state.deliveries;
  },
  hasDeliveries(state) {
    //sirve para verificar si tenemos valores por mostrar.
    return state.deliveries && state.deliveries['deliveries'].length > 0;
  },
  isDeliveries(_, getters) {
    const deliveriess = getters.deliveries;
    console.log(deliveriess['deliveries']);

    const userId = getters.deliveries['deliveries'].moncod;
    return deliveriess['deliveries'].some((deliveries) => deliveries.id === userId);
  },
  shouldUpdate(state) {
    const lastFetch = state.lastFetch;
    if (!lastFetch) {
      return true;
    }
    const currentTimeStamp = new Date().getTime();
    return (currentTimeStamp - lastFetch) / 1000 > 60;
  },
};