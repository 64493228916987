<template>
  <div v-if="isLoggedIn" class="card">
    <base-card
      ><div class="card">
        <form @submit.prevent="saveClient">
          <Toolbar
            ><template v-slot:right
              ><Button
                label="Guardar"
                icon="pi pi-save"
                @click="submitForm()"
                class="p-button-raised p-button-rounded" /></template
          ></Toolbar>

          <Divider align="left"
            ><div class="p-field"></div>
            <div class="p-d-inline-flex p-ai-center">
              <i class="pi pi-id-card p-mr-1"></i>
              <h3>Generales</h3>
            </div>
          </Divider>

          <span class="p-float-label"> </span>
          <div class="p-formgroup-inline">
            <div class="p-field">
              <div class="p-field">
                <div
                  class="form-control"
                  :class="{ invalid: !cuscia.isValida }"
                >
                  <span class="p-float-label">
                    <AutoComplete
                      v-model="cuscia.val"
                      @blur="clearValidity('cuscia')"
                      :dropdown="true"
                      size="35"
                      placeholder="Seleccione una compañia"
                      :suggestions="filteredCiatab"
                      @complete="searchCiatab($event)"
                  /></span>
                  <p v-if="!cuscia.isValida">Debe seleccionar una compañia</p>
                </div>
              </div>
            </div>
            <div class="p-field">
              <div class="form-control" :class="{ invalid: !cusnit.isValida }">
                <span class="p-float-label">
                  <InputText
                    id="cusnit"
                    type="text"
                    @blur="clearValidity('cusnit')"
                    size="10"
                    v-model="cusnit.val"
                  />
                  <label for="cusnit">NIT</label>
                </span>
                <p v-if="!cusnit.isValida">
                  Debe ingresar un valor para el NIT ó contiene caracteres
                  invalidos (/)
                </p>
              </div>
            </div>
            <div class="p-field">
              <span class="p-float-label">
                <InputText id="cusdpi" type="text" v-model="cusdpi" />
                <label for="cusdpi">DPI</label>
              </span>
            </div>
            <div class="p-field">
              <span class="p-float-label">
                <Calendar
                  id="cusfecing"
                  v-model="cusfecing"
                  :showIcon="false"
                  date-format="dd/mm/yy"
                />
                <label for="cusfecing">Fecha Ingreso</label>
              </span>
            </div>
          </div>

          <div class="p-formgroup-inline">
            <div class="form-control" :class="{ invalid: cuscod.isValid }">
              <div class="p-field">
                <span class="p-float-label">
                  <InputText
                    id="cuscod"
                    type="text"
                    v-model="cuscod.val"
                    @blur="clearValidity('cuscod')"
                  />
                  <label for="cuscod">Codigo</label>
                </span>
                <p v-if="!cuscod.isValida">
                  El codigo de Cliente debe ser un valor diferente de 0
                </p>
              </div>
            </div>
            <div>
              <br />
            </div>
            <div class="form-control" :class="{ invalid: cusruta.isValid }">
              <div class="p-field">
                <span class="p-float-label">
                  <AutoComplete
                    id="cusruta"
                    type="text"
                    :dropdown="true"
                    :suggestions="filteredFilveq"
                    @complete="searchFilveq($event)"
                    @blur="clearValidity('cusruta')"
                    v-model="cusruta.val"
                    size="90"
                  />
                  <label for="cusruta">Seleccione un Vendedor</label>
                </span>
              </div>
              <p v-if="!cusruta.isValida">
                Debe seleccionar un vendedor asociado a este cliente
              </p>
            </div>

            <div>
              <br />
            </div>
          </div>
          <div class="p-formgroup-inline">
            <div class="p-field">
              <span class="p-float-label">
                <InputText
                  id="cusprop"
                  type="text"
                  v-model="cusprop"
                  size="125"
                />
                <label for="cusprop">Propietario</label>
              </span>
              <div>
                <br />
              </div>
              <div class="form-control" :class="{ invalid: !cusname.isValida }">
                <span class="p-float-label">
                  <InputText
                    id="cusname"
                    type="text"
                    @blur="clearValidity('cusname')"
                    v-model="cusname.val"
                    size="125"
                  />
                  <label for="cusname">Nombre Comercial</label>
                </span>
                <p v-if="!cusname.isValida">
                  Valor invalido para Nombre Comercial.
                </p>
              </div>

              <div>
                <br />
              </div>
              <span class="p-float-label">
                <InputText
                  id="cusrazon"
                  type="text"
                  v-model="cusrazon"
                  size="125"
                />
                <label for="cusrazon">Razon Social</label>
              </span>
              <div>
                <br />
              </div>
              <span class="p-float-label">
                <InputText
                  id="cusgergen"
                  type="text"
                  v-model="cusgergen"
                  size="125"
                />
                <label for="cusgergen">Gerente General</label>
              </span>
              <div>
                <br />
              </div>
              <span class="p-float-label">
                <InputText
                  id="cusrepleg"
                  type="text"
                  v-model="cusrepleg"
                  size="125"
                />
                <label for="cusrepleg">Representante Legal</label>
              </span>
              <div>
                <br />
              </div>
              <span class="p-float-label">
                <InputText
                  id="cuscontac"
                  type="text"
                  v-model="cuscontac"
                  size="125"
                />
                <label for="cuscontac">Contacto</label>
              </span>
              <div>
                <br />
              </div>
              <span class="p-float-label">
                <InputText
                  id="cuscontacpto"
                  type="text"
                  v-model="cuscontacpto"
                  size="125"
                />
                <label for="cuscontacpto">Puesto Contacto</label>
              </span>
              <div>
                <br />
              </div>
              <span class="p-float-label">
                <InputText
                  id="cusenc"
                  type="text"
                  v-model="cusenc"
                  size="125"
                />
                <label for="cusenc">Encargado</label>
              </span>
            </div>
          </div>
          <div class="p-formgroup-inline">
            <div class="p-field">
              <div class="form-control" :class="{ invalid: !cusdir.isValida }">
                <span class="p-float-label">
                  <InputText
                    id="cusdir"
                    type="text"
                    background-color="#000000"
                    v-model="cusdir.val"
                    @blur="clearValidity('cusdir')"
                    size="100"
                  />
                  <label for="cusdir">Direccion</label>
                </span>
                <p v-if="!cusdir.isValida">Valor invalido para la direccion</p>
              </div>
            </div>
            <div class="p-field">
              <span class="p-float-label">
                <InputNumber id="cuszona" type="" v-model="cuszona" size="16" />
                <label for="cuszona">Zona</label>
              </span>
            </div>
          </div>
          <div class="p-formgroup-inline">
            <div class="p-field">
              <span class="p-float-label">
                <AutoComplete
                  v-model="cusdepto"
                  :suggestions="filteredCusdeptab"
                  :dropdown="true"
                  size="42"
                  placeholder="Departamento"
                  @complete="searchCusdeptab($event)"
              /></span>
            </div>

            <div class="p-field">
              <span class="p-float-label">
                <AutoComplete
                  v-model="cusciudad"
                  :dropdown="true"
                  size="41"
                  placeholder="Ciudad"
                  :suggestions="filteredCiutab"
                  @complete="searchCiutab($event)"
                />
              </span>
            </div>

            <div class="p-field">
              <span class="p-float-label">
                <InputNumber
                  id="cuspostal"
                  type=""
                  v-model="cuspostal"
                  size="13"
                />
                <label for="cuspostal">Cod. Postal</label>
              </span>
            </div>
          </div>
          <!-- LUGAR DE RECEPCION, HORARIO RECEPCION, PAGINA WEB, EMAIL-->
          <div class="p-formgroup-inline">
            <div class="p-field">
              <span class="p-float-label">
                <InputText
                  id="cusentdir"
                  type="text"
                  v-model="cusentdir"
                  size="125"
                />
                <label for="cusentdir">Lugar de Recepcion</label>
              </span>
              <div>
                <br />
              </div>
              <span class="p-float-label">
                <InputText
                  id="cushorario"
                  type="text"
                  v-model="cushorario"
                  size="125"
                />
                <label for="cushorario">Horario de Recepcion</label>
              </span>
              <div>
                <br />
              </div>
              <span class="p-float-label">
                <InputText
                  id="cusweb"
                  type="text"
                  v-model="cusweb"
                  size="125"
                />
                <label for="cusweb">Pagina Web</label>
              </span>
              <div>
                <br />
              </div>
              <span class="p-float-label">
                <InputText
                  id="cusmail"
                  type="text"
                  v-model="cusmail"
                  size="125"
                />
                <label for="cusmail">Direccion Email</label>
              </span>
            </div>
          </div>
          <!--Telefonos, Celular-->
          <div class="p-formgroup-inline">
            <div class="p-field">
              <span class="p-float-label">
                <InputText id="custel" type="" v-model="custel" size="65" />
                <label for="custel">Telefonos</label>
              </span>
            </div>
            <div class="p-field">
              <span class="p-float-label">
                <InputText id="cusfax" type="" v-model="cusfax" size="51" />
                <label for="cusfax">Celular</label>
              </span>
            </div>
          </div>
          <!--Contado, Credito, CreditoQ, Dias Vencimiento-->
          <div class="p-formgroup-inline">
            <div class="p-field-checkbox">
              <Checkbox
                id="cuscontado"
                name="contado"
                value="S"
                v-model="contado"
              />
              <label for="cuscontado">Contado</label>
            </div>

            <div class="p-field-checkbox">
              <Checkbox id="cuscredito" name="pago" value="S" v-model="pago" />
              <label for="cuscredito">Credito</label>
            </div>
            <div class="p-field">
              <span class="p-float-label">
                <InputNumber
                  id="cuslimcre"
                  type=""
                  v-model="cuslimcre"
                  size="20"
                />
                <label for="cuslimcre">Credito</label>
              </span>
            </div>
            <div class="p-field">
              <span class="p-float-label">
                <InputNumber
                  id="cusdiaven"
                  type=""
                  v-model="cusdiaven"
                  size="20"
                />
                <label for="cusdiaven">Dias Vencimiento</label>
              </span>
            </div>
          </div>
          <!--Bancos que utiliza-->
          <div class="p-formgroup-inline">
            <div class="p-field">
              <span class="p-float-label">
                <InputText
                  id="cusbancos"
                  type=""
                  v-model="cusbancos"
                  size="125"
                />
                <label for="cusbancos">Bancos que Utiliza</label>
              </span>
            </div>
          </div>
          <!-- Formas de Pago, cheque, transferencioas, otra -->
          <div class="p-formgroup-inline">
            <div class="p-field-checkbox">
              <Checkbox
                id="cuspagchq"
                name="fpago"
                value="S"
                v-model="cuspagchq"
              />
              <label for="chcuspagchqeque">Cheque</label>
            </div>

            <div class="p-field-checkbox">
              <Checkbox
                id="cuspagtra"
                name="fpago"
                value="cuspagtra"
                v-model="cuspagtra"
              />
              <label for="cuspagtra">Transferencia</label>
            </div>
            <div class="p-field">
              <span class="p-float-label">
                <InputText
                  id="cuspagotr"
                  type=""
                  v-model="cuspagotr"
                  size="88"
                />
                <label for="cuspagotr">Otra</label>
              </span>
            </div>
          </div>
          <!-- politica de compra, politica de margenes, observaciones-->
          <div class="p-formgroup-inline">
            <div class="p-field">
              <span class="p-float-label">
                <InputText
                  id="cuspolcomp"
                  type="text"
                  v-model="cuspolcomp"
                  size="125"
                />
                <label for="cuspolcomp">Politica de Compra</label>
              </span>
              <div>
                <br />
              </div>
              <span class="p-float-label">
                <InputText
                  id="cuspolmarg"
                  type="text"
                  v-model="cuspolmarg"
                  size="125"
                />
                <label for="cuspolmarg">Politica de Margenes</label>
              </span>
              <div>
                <br />
              </div>
              <span class="p-float-label">
                <InputText
                  id="cusmemo"
                  type="text"
                  v-model="cusmemo"
                  size="125"
                />
                <label for="cusmemo">Observaciones</label>
              </span>
            </div>
          </div>
          <Divider align="left">
            <div class="p-d-inline-flex p-ai-center">
              <i class="pi pi-user p-mr-1"></i>
              <h3>Tipificacion del Cliente</h3>
            </div>
          </Divider>
          <!--Giro Comercial-->
          <div class="p-formgroup-inline">
            <div class="p-field">
              <span class="p-float-label">
                <InputText
                  id="cusgiro"
                  v-model="cusgiro"
                  size="125"
                  :maxlength="100"
                />
                <label for="cusgiro">Giro Comercial</label>
              </span>
            </div>
          </div>
          <!--Tipo Cliente, individual, distribuidor, mayorista, exportacion, otro-->
          <div class="p-formgroup-inline">
            <div class="p-field-checkbox">
              <Checkbox
                id="custcind"
                name="tcliente"
                value="S"
                v-model="custcind"
              />
              <label for="custcind">Individual</label>
            </div>

            <div class="p-field-checkbox">
              <Checkbox
                id="custcdis"
                name="custcdis"
                value="S"
                v-model="cities2"
              />
              <label for="custcdis">Distribuidor</label>
            </div>
            <div class="p-field-checkbox">
              <Checkbox
                id="custcmay"
                name="custcmay"
                value="S"
                v-model="custcmay"
              />
              <label for="mayorista">Mayorista</label>
            </div>
            <div class="p-field-checkbox">
              <Checkbox
                id="custcexp"
                name="custcexp"
                value="S"
                v-model="custcexp"
              />
              <label for="custcexp">Exportacion</label>
            </div>
            <div class="p-field">
              <span class="p-float-label">
                <InputText id="totro" type="" v-model="value" size="49" />
                <label for="totro">Otro</label>
              </span>
            </div>
          </div>
          <!--Perfil del negocio-->
          <Divider align="left">
            <div class="p-d-inline-flex p-ai-center">
              <i class="pi pi-briefcase p-mr-1"></i>
              <h3>Perfil del negocio</h3>
            </div>
          </Divider>
          <div class="p-formgroup-inline">
            <div class="p-field">
              <span class="p-float-label">
                <InputText
                  id="cusnegest"
                  type="text"
                  v-model="cusnegest"
                  size="125"
                />
                <label for="cusnegest">Estilo</label>
              </span>
              <div>
                <br />
              </div>
              <span class="p-float-label">
                <InputText
                  id="cusnegtec"
                  type="text"
                  v-model="cusnegtec"
                  size="125"
                />
                <label for="cusnegtec">Tecnologia en negocio</label>
              </span>
              <div>
                <br />
              </div>
              <span class="p-float-label">
                <InputText
                  id="cusrelemp"
                  type="text"
                  v-model="cusrelemp"
                  size="125"
                />
                <label for="cusrelemp">Relacion con empleados</label>
              </span>
            </div>
          </div>
          <!--Filosofia Empresarial-->
          <Divider align="left">
            <div class="p-d-inline-flex p-ai-center">
              <i class="pi pi-briefcase p-mr-1"></i>
              <h3>Filosofia Empresarial</h3>
            </div>
          </Divider>
          <div class="p-formgroup-inline">
            <div class="p-field">
              <span class="p-float-label">
                <InputText
                  id="cusvision"
                  type="text"
                  v-model="cusvision"
                  size="125"
                />
                <label for="cusvision">Vision</label>
              </span>
              <div>
                <br />
              </div>
              <span class="p-float-label">
                <InputText
                  id="cusmision"
                  type="text"
                  v-model="cusmision"
                  size="125"
                />
                <label for="cusmision">Mision</label>
              </span>
              <div>
                <br />
              </div>
              <span class="p-float-label">
                <InputText
                  id="cusvalores"
                  type="text"
                  v-model="cusvalores"
                  size="125"
                />
                <label for="cusvalores">Valores</label>
              </span>
              <div>
                <br />
              </div>
              <span class="p-float-label">
                <InputText
                  id="cusobjetivos"
                  type="text"
                  v-model="cusobjetivos"
                  size="125"
                />
                <label for="cusobjetivos">Objetivos</label>
              </span>
            </div>
          </div>
          <!--Relacion con proveedores-->
          <Divider align="left">
            <div class="p-d-inline-flex p-ai-center">
              <i class="pi pi-briefcase p-mr-1"></i>
              <h3>Relacion con proveedores</h3>
            </div>
          </Divider>
          <div class="p-formgroup-inline">
            <div class="p-field-checkbox">
              <Checkbox
                id="cusalianza"
                name="proveedores"
                value="S"
                v-model="cusalianza"
              />
              <label for="cusalianza">Alianzas</label>
            </div>

            <div class="p-field-checkbox">
              <Checkbox
                id="cuspromocion"
                name="proveedores"
                value="S"
                v-model="cuspromocion"
              />
              <label for="cuspromocion">Promociones</label>
            </div>
            <div class="p-field-checkbox">
              <Checkbox
                id="cusimpulsar"
                name="proveedores"
                value="S"
                v-model="cusimpulsar"
              />
              <label for="cusimpulsar">Impulsadoras</label>
            </div>
            <div class="p-field-checkbox">
              <Checkbox
                id="cuscolocar"
                name="proveedores"
                value="S"
                v-model="cuscolocar"
              />
              <label for="cuscolocar">Colocadoras</label>
            </div>
            <div class="p-field">
              <span class="p-float-label">
                <InputText
                  id="cusrpotro"
                  type=""
                  v-model="cusrpotro"
                  size="46"
                />
                <label for="cusrpotro">Otro</label>
              </span>
            </div>
          </div>
          <!--Influencias generacionales-->
          <Divider align="left">
            <div class="p-d-inline-flex p-ai-center">
              <i class="pi pi-briefcase p-mr-1"></i>
              <h3>Influencias generacionales</h3>
            </div>
          </Divider>
          <div class="p-formgroup-inline">
            <div class="p-field-checkbox">
              <Checkbox
                id="cusboom"
                name="cusboom"
                value="S"
                v-model="cusboom"
              />
              <label for="cusboom">Baby Boom</label>
            </div>

            <div class="p-field-checkbox">
              <Checkbox
                id="cuselderly"
                name="cuselderly"
                value="S"
                v-model="cuselderly"
              />
              <label for="cuselderly">Elderly Sr.</label>
            </div>
            <div class="p-field-checkbox">
              <Checkbox
                id="cusgenrex"
                name="cusgenrex"
                value="S"
                v-model="cusgenrex"
              />
              <label for="cusgenrex">Gener X.</label>
            </div>
            <div class="p-field-checkbox">
              <Checkbox
                id="custeens"
                name="custeens"
                value="S"
                v-model="custeens"
              />
              <label for="custeens">Teens</label>
            </div>
            <div class="p-field">
              <span class="p-float-label">
                <InputText
                  id="cusgenotro"
                  type=""
                  v-model="cusgenotro"
                  size="58"
                />
                <label for="cusgenotro">Otro</label>
              </span>
            </div>
          </div>
          <!--Categorias o familias que se pueden promover-->
          <Divider align="left">
            <div class="p-d-inline-flex p-ai-center">
              <i class="pi pi-briefcase p-mr-1"></i>
              <h3>Categorias o familias que se pueden promover</h3>
            </div>
          </Divider>
          <div class="p-formgroup-inline">
            <div class="p-field-checkbox">
              <Checkbox
                id="cuscatfamch"
                name="cuscatfamch"
                value="S"
                v-model="cuscatfamch"
              />
              <label for="cuscatfamch">CH</label>
            </div>

            <div class="p-field-checkbox">
              <Checkbox
                id="cuscatfamcp"
                name="cuscatfamcp"
                value="S"
                v-model="cuscatfamcp"
              />
              <label for="cuscatfamcp">CP</label>
            </div>

            <div class="p-field">
              <span class="p-float-label">
                <InputText
                  id="cuscatfamotro"
                  type="text"
                  v-model="cuscatfamotro"
                  size="105"
                />
                <label for="cuscatfamotro"
                  >Otro (Detalle el producto si es necesario)</label
                >
              </span>
            </div>
          </div>
          <!--Capacidad de adaptacion al merdado que posee-->
          <Divider align="left">
            <div class="p-d-inline-flex p-ai-center">
              <i class="pi pi-briefcase p-mr-1"></i>
              <h3>Capacidad de adaptacion al merdado que posee</h3>
            </div>
          </Divider>
          <div class="p-formgroup-inline">
            <div class="p-field-checkbox">
              <Checkbox
                id="cuscamtrad"
                name="cuscamtrad"
                value="S"
                v-model="cuscamtrad"
              />
              <label for="cuscamtrad">Tradicional</label>
            </div>

            <div class="p-field-checkbox">
              <Checkbox
                id="cuscaminnov"
                name="cuscaminnov"
                value="S"
                v-model="cuscaminnov"
              />
              <label for="cuscaminnov">Innovadores</label>
            </div>

            <div class="p-field">
              <span class="p-float-label">
                <InputText
                  id="cuscamotro"
                  type=""
                  v-model="cuscamotro"
                  size="105"
                />
                <label for="cuscamotro">Otro </label>
              </span>
            </div>
          </div>
          <!--Preferencias por marcas establecidas por PV o CD-->
          <Divider align="left">
            <div class="p-d-inline-flex p-ai-center">
              <i class="pi pi-briefcase p-mr-1"></i>
              <h3>Preferencias por marcas establecidas por PV o CD</h3>
            </div>
          </Divider>
          <div class="p-formgroup-inline">
            <div class="p-field">
              <span class="p-float-label">
                <InputText
                  id="cuspreferencia"
                  type="text"
                  v-model="cuspreferencia"
                  size="125"
                />
                <label for="cuspreferencia">Detalle Marcas</label>
              </span>
              <div>
                <br />
              </div>
              <span class="p-float-label">
                <InputText
                  id="cusprodvta"
                  type="text"
                  v-model="cusprodvta"
                  size="125"
                />
                <label for="cusprodvta">Productos Principales</label>
              </span>
              <div>
                <br />
              </div>
              <span class="p-float-label">
                <InputText
                  id="cuspromvta"
                  type="text"
                  v-model="cuspromvta"
                  size="125"
                />
                <label for="cuspromvta">Unidades promedio mes</label>
              </span>
              <div>
                <br />
              </div>
              <span class="p-float-label">
                <InputText
                  id="cusmargprecvta"
                  type="text"
                  v-model="cusmargprecvta"
                  size="125"
                />
                <label for="cusmargprecvta">Marginacion precio venta</label>
              </span>
              <div>
                <br />
              </div>
              <span class="p-float-label">
                <InputText
                  id="cusrealventas"
                  type="text"
                  v-model="cusrealventas"
                  size="125"
                />
                <label for="cusrealventas">Ventas Contado/Credito</label>
              </span>
            </div>
          </div>
          <!--Caracteristicas Geodemograficas-->
          <Divider align="left">
            <div class="p-d-inline-flex p-ai-center">
              <i class="pi pi-map-marker p-mr-1"></i>
              <h3>Caracteristicas Geodemograficas</h3>
            </div>
          </Divider>
          <div class="p-formgroup-inline">
            <div class="p-field">
              <span class="p-float-label">
                <InputNumber
                  id="cuspuntosventa"
                  type="text"
                  v-model="cuspuntosventa"
                  size="50"
                />
                <label for="cuspuntosventa"
                  >Mercado que atiende: puntos de venta o distribucion</label
                >
              </span>
              <div>
                <br />
              </div>
              <span class="p-float-label">
                <InputNumber
                  id="cusfrecom"
                  type="text"
                  v-model="cusfrecom"
                  size="50"
                />
                <label for="cusfrecom"
                  >Frecuencias de compra promedio, en dias</label
                >
              </span>
              <div>
                <br />
              </div>
              <span class="p-float-label">
                <InputText
                  id="cussegmento"
                  type="text"
                  v-model="cussegmento"
                  size="125"
                />
                <label for="cussegmento">Segmento</label>
              </span>
              <div>
                <br />
              </div>
              <span class="p-float-label">
                <InputText
                  id="cusorientconsumo"
                  type="text"
                  v-model="cusorientconsumo"
                  size="125"
                />
                <label for="cusorientconsumo">Orientacion de Consumo</label>
              </span>
            </div>
            <div class="p-field-checkbox">
              <Checkbox
                id="cuslogistpv"
                name="cuslogistpv"
                value="S"
                v-model="cuslogistpv"
              />
              <label for="cuslogistpv">Logistica en PV</label>
            </div>
            <div class="p-field-checkbox">
              <Checkbox
                id="cuslogistcd"
                name="cuslogistcd"
                value="S"
                v-model="cuslogistcd"
              />
              <label for="cuslogistcd">en CD</label>
            </div>
            <div class="p-field-checkbox">
              <Checkbox
                id="cuslogistrec"
                name="cuslogistrec"
                value="S"
                v-model="cuslogistrec"
              />
              <label for="cuslogistrec">Recolecta</label>
            </div>
            <span class="p-float-label">
              <InputText
                id="cuslogistotro"
                type="text"
                v-model="cuslogistotro"
                size="70"
              />
              <label for="cuslogistotro">Otro</label>
            </span>
            <div class="p-field">
              <div>
                <br />
              </div>
              <span class="p-float-label">
                <InputText
                  id="cusdistribucion"
                  type="text"
                  v-model="cusdistribucion"
                  size="125"
                />
                <label for="cusdistribucion">Modo de Distribucion</label>
              </span>
              <div>
                <br />
              </div>
              <span class="p-float-label">
                <InputText
                  id="cusregiones"
                  type="text"
                  v-model="cusregiones"
                  size="125"
                />
                <label for="cusregiones">Regiones que abarca</label>
              </span>
              <div>
                <br />
              </div>
              <span class="p-float-label">
                <InputText
                  id="cusctefre"
                  type="text"
                  v-model="cusctefre"
                  size="125"
                />
                <label for="cusctefre">Clientes Frecuentes</label>
              </span>
              <div>
                <br />
              </div>
              <span class="p-float-label">
                <InputText
                  id="cusmedvta"
                  type="text"
                  v-model="cusmedvta"
                  size="125"
                />
                <label for="cusmedvta">Medio de Venta</label>
              </span>
              <div>
                <br />
              </div>
              <span class="p-float-label">
                <InputText
                  id="custiendas"
                  type="text"
                  v-model="custiendas"
                  size="125"
                />
                <label for="custiendas">Cantidad de Tiendas</label>
              </span>
              <div>
                <br />
              </div>
              <span class="p-float-label">
                <InputText
                  id="cusruteros"
                  type="text"
                  v-model="cusruteros"
                  size="125"
                />
                <label for="cusruteros">Cantidad de Vendedores</label>
              </span>
              <div>
                <div>
                  <br />
                </div>
                <div class="card">
                  <DataTable
                    :value="cusnewdir"
                    dataKey="id"
                    :paginator="true"
                    :rows="5"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 25]"
                    currentPageReportTemplate="Viendo {first} a {last} de {totalRecords} Direcciones"
                    showGridlines
                    responsiveLayout="scroll"
                  >
                    <Toolbar class="p-mb-4"
                      ><template v-slot:right>
                        <Button
                          label="Agregar"
                          @click="openNewDir"
                          icon="pi pi-plus"
                          class="p-mr-2" /></template
                    ></Toolbar>
                    <template #header>
                      Lugares que cubre y Ubicaciones de Distribucion
                    </template>
                    <Column
                      field="cusdcolonia"
                      header="Colonia/Barrio/Residencial/Edificio"
                    ></Column>
                    <Column field="cusdzona" header="Zona"></Column>
                    <Column field="cusddep" header="Dep"></Column>
                    <Column
                      field="ncusddep"
                      header="Departamento/Estado"
                    ></Column>
                    <Column field="cusdciu" header="Ciu"></Column>
                    <Column field="ncusdciu" header="Ciudad/Localidad"></Column>
                  </DataTable>
                  <Dialog
                    v-model:visible="productDialogDir"
                    :style="{ width: '450px' }"
                    header="Direcciones"
                    :modal="true"
                    class="p-fluid"
                  >
                    <form @submit.prevent="saveProduct">
                      <div class="p-field">
                        <label for="cusdcalle">Calle</label>
                        <InputText
                          id="cusdcalle"
                          v-model="dircalle"
                          :maxlength="35"
                        />
                        <div
                          class="input-group-addon"
                          v-text="35 - dircalle.length"
                        ></div>
                      </div>
                      <div class="p-field">
                        <label for="cusdcasa">Casa</label>
                        <InputText
                          id="cusdcasa"
                          v-model="dircasa"
                          :maxlength="20"
                        />
                        <div
                          class="input-group-addon"
                          v-text="20 - dircasa.length"
                        ></div>
                      </div>
                      <div class="p-field">
                        <label for="cusdcolonia"
                          >Colonia/Barrio/Residencial/Edificio</label
                        >
                        <InputText v-model="colonia" :maxlength="50" />
                        <div
                          class="input-group-addon"
                          v-text="50 - colonia.length"
                        ></div>
                      </div>
                      <div class="p-field" :class="{ invalid: !zona.isValida }">
                        <label for="cusdezona">Zona</label>
                        <InputText
                          id="cusdezona"
                          @blur="clearValidity('zona')"
                          v-model="zona.val"
                          :maxlength="20"
                        />
                        <div
                          class="input-group-addon"
                          v-text="20 - zona.val.length"
                        ></div>
                      </div>
                      <div
                        class="p-field"
                        :class="{ invalid: !departamento.isValida }"
                      >
                        <label for="cusddep">Departamento/Estado</label>
                        <AutoComplete
                          v-model="departamento.val"
                          :dropdown="true"
                          :suggestions="filteredCusdeptab"
                          @complete="searchCusdeptab($event)"
                        />
                      </div>
                      <div
                        class="p-field"
                        :class="{ invalid: !ciudad.isValida }"
                      >
                        <label for="cusdciu">Ciudad/Localidad</label>
                        <AutoComplete
                          v-model="ciudad.val"
                          :dropdown="true"
                          :suggestions="filteredCiutab"
                          @complete="searchCiutab($event)"
                        />
                      </div>
                    </form>

                    <template #footer>
                      <Button
                        label="Cancelar"
                        icon="pi pi-times"
                        @click="hideDialog"
                        class="p-button-text"
                      />
                      <Button
                        label="Guardar"
                        @click="saveAddress"
                        icon="pi pi-check"
                        class="p-button-text"
                      />
                    </template>
                    <p v-if="!formIsValidaDir">
                      Por favor revisar que los campos se encuentren llenos y
                      probar nuevamente.
                    </p>
                  </Dialog>
                </div>
                <p v-if="!formIsValida">
                  Por favor revisar que los campos se encuentren llenos y probar
                  nuevamente.
                </p>
                <div>
                  <Button
                    label="Guardar"
                    icon="pi pi-save"
                    @click="submitForm()"
                    class="p-button-raised p-button-rounded"
                  />
                </div>
              </div>
            </div>
          </div>
        </form></div
    ></base-card>
  </div>
</template>

<script>
export default {
  emits: ['save-data'],
  data() {
    return {
      isLoading: false,

      //Listas de valores
      ciatab: null,
      filteredCiatab: null,

      ciutab: null,
      filveq: null,
      cusdeptab: null,
      filteredCiutab: null,
      filteredCusdeptab: null,
      filteredFilveq: null,
      productDialogDir: false,

      //
      tituloDialog: 'Lugares',
      // Variable direcciones en tabla cusdir_new

      departamento: { val: 0, isValida: true },
      ciudad: { val: 0, isValida: true },
      zona: { val: '', isValida: true },
      colonia: '',
      dircalle: '',
      dircasa: '',
      cusnewdir: [],

      //

      productDialog: false,
      submitted: false,
      cuscia: { val: '', isValida: true },
      cuscod: { val: 0, isValida: true },
      cusname: { val: '', isValida: true },
      cusdir: { val: '', isValida: true },
      cuszona: 0,
      cusciudad: '',
      custel: '',
      cusfax: '',
      cusnit: { val: '', isValida: true },
      cusfecing: '',
      cuslimcre: 0,
      cusdiaven: 0,
      cusruta: { val: '', isValida: true },
      cusiva: '',
      custipo: 0,
      custipod: 0,
      cusgrupo: 0,
      cusgrupod: 0,
      cuscarta: '',
      cuscartav: '',
      cuscomp: '',
      cusflg: '',
      cusdepto: '',
      cusrazon: '',
      cusdiafac: 0,
      cusdes: 0,
      cusgiro: '',
      cusctad: '',
      cusctad1: '',
      cusprv: '',
      cusrutco: '',
      cusnit1: '',
      cusruta2: '',
      cusdiv: 0,
      cusenc: '',
      cusprop: '',
      cusmail: '',
      cusprec: 0,
      cuscls: 0,
      cuscodant: '',
      cuscontac: '',
      cusconsig: '',
      cuscat: 0,
      cusuff: '',
      cusufp: '',
      cusufc: '',
      cusmemo: '',
      cusststda: '',
      custercom: 0,
      cuspago: 0,
      cuscie: 0,
      cusentdir: '',
      cushorario: '',
      cusordcom: '',
      cusctafle: '',
      cusctaseg: '',
      cusctagts: '',
      cusauto: '',
      cusmaster: '',
      cusmsgche: '',
      cusprecio: 0,
      cusidiomalet: 0,
      cusimpprv: '',
      cusmsg1: '',
      cuscontacpto: '',
      cusrepleg: '',
      cusgergen: '',
      cusdpi: '',
      cuspostal: '',
      cusweb: '',
      cuscontado: '',
      contado: '',
      cuscredito: '',
      cusbancos: '',
      cuspagchq: '',
      cuspagtra: '',
      cuspagotr: '',
      cuspolcomp: '',
      cuspolmarg: '',
      custcind: '',
      custcdis: '',
      custcmay: '',
      custcexp: '',
      custcotr: '',
      cusnegest: '',
      cusnegtec: '',
      cusrelemp: '',
      cusvision: '',
      cusmision: '',
      cusvalores: '',
      cusobjetivos: '',
      cusalianza: '',
      cuspromocion: '',
      cusimpulsar: '',
      cuscolocar: '',
      cusrpotro: '',
      cuspuntosventa: 0,
      cussegmento: '',
      cusorientconsumo: '',
      cusbboom: '',
      cuselderly: '',
      cusgenrex: '',
      custeens: '',
      cusgenotro: '',
      cuscatfamch: '',
      cuscatfamcp: '',
      cuscatfamotro: '',
      cuscamtrad: '',
      cuscaminnov: '',
      cuscamotro: '',
      cuspreferencia: '',
      cusfrecom: 0,
      cuslogistpv: '',
      cuslogistcd: '',
      cuslogistrec: '',
      cuslogistotro: '',
      cusdistribucion: '',
      cusmargprecvta: '',
      cusctefre: '',
      cusprodvta: '',
      cuspromvta: '',
      cusmedvta: '',
      cusruteros: '',
      custiendas: '',
      cusrealventas: '',
      cusregiones: '',

      ncusruta: null,
      ncusdepto: null,
      ncusciudad: null,
      pago: null,
      cusboom: '',
      cities2: null,
      value: null,
      formIsValida: true,
      formIsValidaDir: true,
    };
  },
  created() {
    this.loadListValuesCiatab(true);
    this.loadListValuesCusdeptab(true);
    this.loadListValuesCiutab(true);
    this.loadListValuesFilveq(true);

    setTimeout(() => {
      this.ciatab = this.listValuesCiatab;
      this.cusdeptab = this.listValuesCusdeptab;
      this.ciutab = this.listValuesCiutab;
      this.filveq = this.listValuesFilveq;
    }, 6000);
  },
  computed: {
    isLoggedIn() {
      //console.log(this.$store.getters.isAuthenticated);
      return this.$store.getters.isAuthenticated;
    },
    listValuesCiutab() {
      this.loadListValuesCiutab(true);

      const ciutab =
        this.$store.getters['listvalues/listvalues']['listvaluesciutab'];
      console.log(ciutab);
      return ciutab.filter((ciu) => {
        if (ciu.ciuciu != '0') {
          return true;
        }
        if (ciu.ciuciu != '0') {
          return true;
        }
        console.log(ciutab);

        return true;
      });
    },
    listValuesFilveq() {
      this.loadListValuesFilveq(true);

      const filveq =
        this.$store.getters['listvalues/listvalues']['listvaluesfilveq'];
      console.log(filveq);
      return filveq.filter((filveq) => {
        if (filveq.veqcia != '0') {
          return true;
        }
        if (filveq.veqcia != '0') {
          return true;
        }
        console.log(filveq);

        return true;
      });
    },
    listValuesCusdeptab() {
      this.loadListValuesCusdeptab(true);

      const cusdeptab =
        this.$store.getters['listvalues/listvalues']['listvaluescusdeptab'];
      console.log(cusdeptab);
      return cusdeptab.filter((cusdeptab) => {
        if (cusdeptab.cusdepcod != '0') {
          return true;
        }
        if (cusdeptab.cusdepcod != '0') {
          return true;
        }
        console.log(cusdeptab);

        return true;
      });
    },
    listValuesCiatab() {
      this.loadListValuesCiatab;
      const ciatab = this.$store.getters['listvalues/listvalues']['listvalues'];
      console.log(ciatab);
      return ciatab.filter((ciatab) => {
        if (ciatab.ciacod != '0') {
          return true;
        }
        if (ciatab.ciacod != '0') {
          return true;
        }
        console.log(ciatab);

        return true;
      });
    },
  },
  methods: {
    dateFormatOracle(fecha) {
      const date = new Date(fecha);
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();

      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }
      return dt + '/' + month + '/' + year;
    },
    async loadListValuesCiutab(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('listvalues/loadListValuesCiutab', {
          forceRefresh: refresh,
        }); //viene de la clases action.js G
      } catch (error) {
        this.error = error.message || 'algo salió mal';
      }

      this.isLoading = false;
    },
    async loadListValuesCusdeptab(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('listvalues/loadListValuesCusdeptab', {
          forceRefresh: refresh,
        }); //viene de la clases action.js G
      } catch (error) {
        this.error = error.message || 'algo salió mal';
      }

      this.isLoading = false;
    },
    async loadListValuesFilveq(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('listvalues/loadListValuesFilveq', {
          forceRefresh: refresh,
        }); //viene de la clases action.js G
      } catch (error) {
        this.error = error.message || 'algo salió mal';
      }

      this.isLoading = false;
    },
    searchCusdeptab(event) {
      const departamentos = [];
      console.log(this.cusdeptab);
      setTimeout(() => {
        for (const key in this.cusdeptab) {
          departamentos.push(
            this.cusdeptab[key].cusdepcod +
              ' - ' +
              this.cusdeptab[key].cusdepnom
          );
        }
        if (!event.query.trim().length) {
          this.filteredCusdeptab = [...departamentos];
        } else {
          this.filteredCusdeptab = departamentos.filter((country) => {
            return country.toLowerCase().includes(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    searchCiutab(event) {
      const ciudades = [];
      console.log(this.ciutab);
      setTimeout(() => {
        for (const key in this.ciutab) {
          ciudades.push(
            this.ciutab[key].ciuciu + ' - ' + this.ciutab[key].ciudes
          );
        }
        if (!event.query.trim().length) {
          this.filteredCiutab = [...ciudades];
        } else {
          this.filteredCiutab = ciudades.filter((country) => {
            return country.toLowerCase().includes(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    searchCiatab(event) {
      const companias = [];
      console.log(this.ciatab);
      setTimeout(() => {
        for (const key in this.ciatab) {
          companias.push(
            this.ciatab[key].ciacod + ' - ' + this.ciatab[key].cianombre
          );
        }
        if (!event.query.trim().length) {
          this.filteredCiatab = [...companias];
        } else {
          this.filteredCiatab = companias.filter((country) => {
            return country.toLowerCase().includes(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    saveData() {},
    hideDialog() {
      this.productDialogDir = false;
      this.submitted = false;
    },
    saveAddress() {
      this.submitted = true;
      this.validFormDir();

      if (!this.formIsValidaDir) {
        console.log('pase por aca!!!');
        return;
      }

      this.cusnewdir.push({
        cusdcia: this.cuscia.val.substr(0, this.cuscia.val.indexOf('-') - 1),
        cusdenit: this.cusnit.val,
        cusddep: this.departamento.val.substr(
          0,
          this.departamento.val.indexOf('-') - 1
        ),
        cusdciu: this.ciudad.val.substr(0, this.ciudad.val.indexOf('-') - 1),
        cusdzona: this.zona.val,
        cusdcolonia: this.colonia,
        cusdcalle: this.dircalle,
        cusdcasa: this.dircasa,
      });
      this.departamento.val = '';
      this.ciudad.val = '';
      this.zona.val = '';
      this.colonia = '';
      this.dircalle = '';
      this.dircasa = '';
      this.productDialogDir = false;
      this.product = {};
    },
    openNewDir() {
      this.product = {};
      this.submitted = false;
      this.productDialogDir = true;
    },
    openNew() {
      this.product = {};
      this.submitted = false;
      this.productDialog = true;
    },
    submitForm() {
      this.validForm();

      if (!this.formIsValida) {
        return;
      }
      const newdir = [];
      for (const key in this.cusnewdir) {
        const dirnew = {
          cusdcia: this.cusnewdir[key].cusdcia,
          cusdnit: this.cusnewdir[key].cusdenit,
          cusddep: this.cusnewdir[key].cusddep,
          cusdciu: this.cusnewdir[key].cusdciu,
          cusdzona: this.cusnewdir[key].cusdzona,
          cusdcolonia: this.cusnewdir[key].cusdcolonia,
          cusdcalle: this.cusnewdir[key].cusdcalle,
          cusdcasa: this.cusnewdir[key].cusdcasa,
        };

        newdir.push(dirnew);
      }
      console.log(newdir);
      const formData = {
        cia: this.cuscia.val.substr(0, this.cuscia.val.indexOf('-') - 1),
        cod: this.cuscod.val,
        name: this.cusname.val,
        dir: this.cusdir.val,
        zona: this.cuszona,
        ciudad:
          this.cusciudad == ''
            ? 0
            : this.cusciudad.substr(0, this.cusciudad.indexOf('-') - 1),
        tel: this.custel,
        fax: this.cusfax,
        nit: this.cusnit.val,
        fecing: this.dateFormatOracle(this.cusfecing.toISOString()),
        limcre: this.cuslimcre,
        diaven: this.cusdiaven,
        ruta: this.cusruta.val.substr(0, this.cusruta.val.indexOf('-') - 1),
        iva: this.cusiva,
        tipo: this.custipo,
        tipod: this.custipod,
        grupo: this.cusgrupo,
        grupod: this.cusgrupod,
        carta: this.cuscarta,
        cartav: this.cuscartav,
        comp: this.cuscomp,
        flg: this.cusflg,
        depto:
          this.cusdepto == ''
            ? 0
            : this.cusdepto.substr(0, this.cusdepto.indexOf('-') - 1),
        razon: this.cusrazon,
        diafac: this.cusdiafac,
        des: this.cusdes,
        giro: this.cusgiro,
        ctad: this.cusctad,
        ctad1: this.cusctad1,
        prv: this.cusprv,
        rutco: this.cusrutco,
        nit1: this.cusnit1,
        ruta2: this.cusruta2,
        div: this.cusdiv,
        enc: this.cusenc,
        prop: this.cusprop,
        mail: this.cusmail,
        prec: this.cusprec,
        cls: this.cuscls,
        codant: this.cuscodant,
        contac: this.cuscontac,
        consig: this.cusconsig,
        cat: this.cuscat,
        uff: this.cusuff,
        ufp: this.cusufp,
        ufc: this.cusufc,
        memo: this.cusmemo,
        ststda: this.cusststda,
        tercom: this.custercom,
        pago: this.cuspago,
        cie: this.cuscie,
        entdir: this.cusentdir,
        horario: this.cushorario,
        ordcom: this.cusordcom,
        ctafle: this.cusctafle,
        ctaseg: this.cusctaseg,
        ctagts: this.cusctagts,
        auto: this.cusauto,
        master: this.cusmaster,
        msgche: this.cusmsgche,
        precio: this.cusprecio,
        idiomalet: this.cusidiomalet,
        impprv: this.cusimpprv,
        msg1: this.cusmsg1,
        contacpto: this.cuscontacpto,
        repleg: this.cusrepleg,
        gergen: this.cusgergen,
        dpi: this.cusdpi,
        postal: this.cuspostal,
        web: this.cusweb,
        contado: this.cuscontado,
        credito: this.cuscredito,
        bancos: this.cusbancos,
        pagchq: this.cuspagchq,
        pagtra: this.cuspagtra,
        pagotr: this.cuspagotr,
        polcomp: this.cuspolcomp,
        polmarg: this.cuspolmarg,
        tcind: this.custcind,
        tcdis: this.custcdis,
        tcmay: this.custcmay,
        tcexp: this.custcexp,
        tcotr: this.custcotr,
        negest: this.cusnegest,
        negtec: this.cusnegtec,
        relemp: this.cusrelemp,
        vision: this.cusvision,
        mision: this.cusmision,
        valores: this.cusvalores,
        objetivos: this.cusobjetivos,
        alianza: this.cusalianza,
        promocion: this.cuspromocion,
        impulsar: this.cusimpulsar,
        colocar: this.cuscolocar,
        rpotro: this.cusrpotro,
        puntosventa: this.cuspuntosventa,
        segmento: this.cussegmento,
        orientconsumo: this.cusorientconsumo,
        bboom: this.cusbboom,
        elderly: this.cuselderly,
        genrex: this.cusgenrex,
        teens: this.custeens,
        genotro: this.cusgenotro,
        catfamch: this.cuscatfamch,
        catfamcp: this.cuscatfamcp,
        catfamotro: this.cuscatfamotro,
        camtrad: this.cuscamtrad,
        caminnov: this.cuscaminnov,
        camotro: this.cuscamotro,
        preferencia: this.cuspreferencia,
        frecom: this.cusfrecom,
        logistpv: this.cuslogistpv,
        logistcd: this.cuslogistcd,
        logistrec: this.cuslogistrec,
        logistotro: this.cuslogistotro,
        distribucion: this.cusdistribucion,
        margprecvta: this.cusmargprecvta,
        ctefre: this.cusctefre,
        prodvta: this.cusprodvta,
        promvta: this.cuspromvta,
        medvta: this.cusmedvta,
        ruteros: this.cusruteros,
        tiendas: this.custiendas,
        realventas: this.cusrealventas,
        regiones: this.cusregiones,
        //inf: [],
      };

      //La linea de abajo se comento para ver las pruebas del json con las direcciones,
      //debe estar descomentada para que funcione el insert.
      this.$emit('save-data', formData, newdir);
    },

    searchFilveq(event) {
      const vendedores = [];
      console.log(this.filveq);
      setTimeout(() => {
        for (const key in this.filveq) {
          vendedores.push(
            this.filveq[key].veqrut + ' - ' + this.filveq[key].veqnom
          );
        }
        if (!event.query.trim().length) {
          this.filteredFilveq = [...vendedores];
        } else {
          this.filteredFilveq = vendedores.filter((country) => {
            return country.toLowerCase().includes(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    async loadListValuesCiatab(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('listvalues/loadListValuesCiatab', {
          forceRefresh: refresh,
        }); //viene de la clases action.js G
      } catch (error) {
        this.error = error.message || 'algo salió mal';
      }

      this.isLoading = false;
    },
    clearValidity(input) {
      this[input].isValida = true;
    },
    validFormDir() {
      console.log('hora de validaciones.');
      this.formIsValidaDir = true;
      if (this.departamento.val === 0) {
        this.formIsValidaDir = false;
        this.departamento.isValida = false;
      }
      if (this.ciudad.val === 0) {
        this.formIsValidaDir = false;
        this.ciudad.isValida = false;
      }
      if (this.zona.val === '') {
        this.formIsValidaDir = false;
        this.zona.isValida = false;
      }
    },
    validForm() {
      this.formIsValida = true;

      if (this.cuscia.val === '') {
        this.formIsValida = false;
        this.cuscia.isValida = false;
      }
      if (this.cuscod.val === 0) {
        this.formIsValida = false;
        this.cuscod.isValida = false;
      }
      if (this.cusname.val === '') {
        this.formIsValida = false;
        this.cusname.isValida = false;
      }
      if (this.cusdir.val === '') {
        this.formIsValida = false;
        this.cusdir.isValida = false;
      }
      if (this.cusnit.val === '' || this.cusnit.val.includes('/')) {
        this.formIsValida = false;
        this.cusnit.isValida = false;
      }
      if (this.cusruta.val === '') {
        this.formIsValida = false;
        this.cusruta.isValida = false;
      }
    },
  },
};
</script>

<style scoped>
.invalid label {
  color: red;
}
</style>
