<template>
  <div class="exception-body notfound">
    
    <div class="exception-wrapper">
      <div class="exception-content">
        <img
          src="assets/layout/images/pages/asset-404.svg"
          alt="freya-layout"
        />
        <span>404</span>
        <span class="exception-subtitle">Parece que estás perdido.</span>
        <Button type="button" label="Ir a Inicio" @click="goDashboard"></Button>
      </div>
      <div class="exception-footer p-mt-4">
        <h6>Copyright Ⓒ MOSI</h6>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    colorScheme() {
      return this.$appState.colorScheme;
    },
  },
  methods: {
    goDashboard() {
      window.location = '/home';
    },
  },
};
</script>
