import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      billings: {
        billings: [],
        docdt: [],
        docdt_doc: [],
      },
    };
  },
  mutations,
  actions,
  getters,
};
