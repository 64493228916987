export default {
  cusnew(state) {
    return state.cusnew;
  },
  hasCusnew(state) {
    //sirve para verificar si tenemos valores por mostrar.
    return state.cusnew && state.cusnew['cusnew'].length > 0;
  },
  isCusnew(_, getters) {
    const cusnews = getters.cusnew;
    console.log(cusnews['cusnew']);

    const userId = getters.cusnew['cusnew'].moncod;
    return cusnews['cusnew'].some((cusnew) => cusnew.id === userId);
  },
  shouldUpdate(state) {
    const lastFetch = state.lastFetch;
    if (!lastFetch) {
      return true;
    }
    const currentTimeStamp = new Date().getTime();
    return (currentTimeStamp - lastFetch) / 1000 > 60;
  },
};
