<template>
  <form @submit.prevent="submitForm">
    <Toast />
    <div class="login-body">
      <div class="login-wrapper">
        <div class="login-panel">
          <a @click="goDashboard" class="logo">
            <img
              src="assets/layout/images/logo-freya-single.svg"
              alt="freya-layout"
              width="400"
              height="453"
            />
          </a>
          <InputText id="email" placeholder="Usuario" v-model="email.val" />
          <p v-if="!email.isValida">HOLA</p>
          <Password
            toggleMask
            id="password"
            placeholder="Contraseña"
            :feedback="false"
            v-model="password.val"
            @keyup.enter="submitForm"
          />

          <Button label="INICIAR SESION" type="button" @click="submitForm">
          </Button>
          <h5>Copyright Ⓒ Mosi S.A.</h5>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      email: { val: '', isValida: true },
      password: { val: '', isValida: true },
      formIsValid: true,
      mode: 'login',
      isLoading: false,
      error: null,
    };
  },
  computed: {
    submitButtonCaption() {
      if (this.mode === 'login') {
        return 'Login';
      } else {
        return 'Signup';
      }
    },
    switchModeButtonCaption() {
      if (this.mode === 'login') {
        return 'Signup instead';
      } else {
        return 'Login instead';
      }
    },
  },
  methods: {
    validForm() {
      this.formIsValida = true;
    },
    async submitForm() {
      if (this.email.val === '' || this.password.val === '') {
        this.formIsValida = false;

        this.$toast.add({
          severity: 'error',
          summary: 'Credenciales Invalidas',
          detail:
            'Por favor revisar que los valores de los campos sean validos.',
          life: 3000,
        });
        return;
      }
      try {
        var response;
        if (this.mode === 'login') {
          //console.log('M1');
          response = await this.$store.dispatch('login', {
            email: this.email.val,
            password: this.password.val,
          });
          console.log(response);

          /*await this.$store.dispatch('activateCusNewDir');
          await this.$store.dispatch('activateCusNew');*/
        } else {
          await this.$store.dispatch('signup', {
            email: this.email.val,
            password: this.password.val,
          });
        }
        const redirectUrl = '/' + (this.$route.query.redirect || 'home');
        //console.log(redirectUrl);
        this.$router.replace(redirectUrl);
      } catch (error) {
        console.log(error.message);
        this.error =
          '   '+error.message;
        this.$toast.add({
          severity: 'error',
          summary: 'Fallo en la autenticacion',
          detail: this.error,
          life: 4000,
        });
      }

      this.formIsValid = false;
    },
    switchAuthMode() {
      if (this.mode === 'login') {
        this.mode = 'signup';
      } else {
        this.mode = 'login';
      }
    },
    handleError() {
      this.error = null;
    },
  },
};
</script>

<style scoped>
form {
  margin: 1rem;
  padding: 1rem;
}

.form-control {
  margin: 0.5rem 0;
}

label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
}

input,
textarea {
  display: block;
  width: 100%;
  font: inherit;
  border: 1px solid #ccc;
  padding: 0.15rem;
}

input:focus,
textarea:focus {
  border-color: #3d008d;
  background-color: #faf6ff;
  outline: none;
}
p {
  border-color: #f00b0b;
  background-color: #faf6ff;
  outline: none;
}
</style>
