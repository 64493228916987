export default {
  insertListvalues(state, payload) {
    //console.log(payload);
    state.Listvalues['listvalues'].push(payload);
  },
  setListvalues(state, payload) {
    //console.log(payload);
    state.listvalues['listvalues'] = payload;
  },
  setFetchTimestamp(state) {
    state.lastFetch = new Date().getTime();
  },
  deleteListvalues(state, payload) {
    //console.log('Mutation para delete ' + payload);
    state.Listvalues['listvalues'].push(payload);
  },
  updateListvalues(state, payload) {
    //console.log('test update');
    state.Listvalues['listvalues'].push(payload);
  },

  setListvaluesMensajeros(state, payload) {
    state.listvalues['listvaluesmensajeros'] = payload;
  },
  setListvaluesPrioridades(state, payload) {
    state.listvalues['listvaluesprioridades'] = payload;
  },
  setTazaCambio(state, payload) {
    state.listvalues['tazacambio'] = payload;
  },
  setContadores(state, payload) {
    state.listvalues['contadores'] = payload;
  },
  setListvaluesCostos(state, payload) {
    state.listvalues['listvaluescostos'] = payload;
  },
};
