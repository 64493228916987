<template>
  <Toast />
  <base-card>
    <div class="card">
      <Toolbar class="p-mb-4">
        <template v-slot:right>
          <Button
            label="Guardar"
            icon="pi pi-save"
            @click="updateDocmt"
            class="p-button-info p-mr-2"
          />
        </template>
      </Toolbar>
      <span class="p-float-label">
        <AutoComplete
          :dropdown="true"
          size="35"
          v-model="cia"
          :suggestions="filteredCiatab"
          @complete="searchCiatab($event)"
          placeholder="Seleccione una compañia"
      /></span>
      <br /><br />
      <div class="p-formgroup-inline">
        <br /><br />
        <div class="p-field">
          <b>
            <span class="p-float-label">
              <InputText
                id="docmnit"
                type="text"
                v-model="docmnit"
                size="30"
                :maxlength="30"
              />
              <label for="docmnit">NIT ó CUI</label>
            </span>
          </b>
        </div>

        <div class="p-field">
          <b
            ><span class="p-float-label">
              <Calendar
                id="docmfecdoc"
                v-model="docmfecdoc"
                :showIcon="true"
                date-format="dd/mm/yy"
                showTime
                hourFormat="24"
              />
              <label for="docmfecdoc">Fecha</label>
            </span></b
          >
        </div>
      </div>
      <br /><br />
      <b>
        <span class="p-float-label">
          <InputText
            id="docmnom"
            type="text"
            v-model="docmnom"
            size="125"
            :maxlength="100"
          />
          <label for="docmnom">Nombre Cliente</label>
        </span>
      </b>
      <br /><br />
      <b>
        <span class="p-float-label">
          <InputText
            id="docmdir"
            type="text"
            v-model="docmdir"
            size="125"
            :maxlength="200"
            class="p-inputtext"
          />
          <label for="docmdir">Direccion</label>
        </span>
      </b>
      <br /><br />
      <b>
        <span class="p-float-label">
          <InputText
            id="docmtentotr"
            type="text"
            v-model="docmtentotr"
            size="125"
            :maxlength="100"
          />
          <label for="docmtentotr">Correo Electronico</label>
        </span>
      </b>
      <br /><br />

      <DataTable
        ref="dv"
        responsiveLayout="scroll"
        id="entregas"
        :value="detallebill"
        dataKey="docdprd"
        :paginator="true"
        :rows="25"
        showGridlines
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[25, 50, 75, 100, 125]"
        currentPageReportTemplate="Viendo {first} a {last} de {totalRecords} items"
      >
        <template #header> </template>
        <template #empty><b>No se encontró ningún item ingresado.</b></template>
        <Column
          field="docdnumlin"
          header="No."
          :sortable="true"
          style="text-align: left"
        ></Column>
        <Column
          field="docdesc"
          header="Descripción"
          :sortable="true"
          style="min-width: 15rem"
        ></Column>
        <Column
          field="docdunivta"
          header="Cantidad"
          :sortable="true"
          style="min-width: 10rem; text-align: right"
        ></Column>
        <Column
          field="docdprecaj1"
          header="Precio"
          :sortable="true"
          style="min-width: 10rem; text-align: right"
        ></Column>

        <Column
          field="docdvallin"
          header="Valor"
          :sortable="true"
          style="min-width: 12rem; text-align: right"
        ></Column>
        <Column :exportable="false" style="min-width: 4rem">
          <template #body="slotProps">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-success p-mr-2"
              @click="openDialog(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
      <Dialog
        header="Modificacion de Producto"
        v-model:visible="openbasic"
        :breakpoints="{ '740px': '75vw', '540px': '60vw' }"
        :style="{ width: '50vw' }"
      >
        <div class="center">
          <div class="p-formgroup-inline">
            <span class="p-float-label">
              <InputText
                id="docdesc"
                type="text"
                v-model="docdesc"
                size="90"
                :maxlength="500"
              />
              <label for="docdesc">Descripcion</label></span
            >
          </div>
          <br /><br />
          <div class="p-formgroup-inline">
            <span class="p-float-label">
              <InputText
                id="docdunivta"
                type="text"
                v-model="docdunivta"
                size="90"
                :maxlength="500"
              />
              <label for="docdunivta">Cantidad</label></span
            >
          </div>
          <br /><br />
          <div class="p-formgroup-inline">
            <span class="p-float-label">
              <InputText
                id="docdprecuni"
                type="text"
                v-model="docdprecuni"
                size="90"
                :maxlength="500"
              />
              <label for="docdprecuni">Precio</label></span
            >
          </div>
        </div>

        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            @click="closeBasic"
            class="p-button-text"
          />
          <Button
            label="Aplicar"
            icon="pi pi-check"
            @click="updateDocdt()"
            autofocus
          />
        </template>
      </Dialog></div
  ></base-card>
</template>

<script>
export default {
  props: ['docmdoc'],
  data() {
    return {
      openbasic: false,
      docdesc: null,
      docdnumlin: null,
      docdunivta: null,
      docdprecuni: null,
      cia: null,
      docmper: null,
      docmcia: '',
      docmtip: null,
      docmnit: '',
      docmnom: '',
      docmdir: '',
      docdprd: null,
      detallebill: null,
      docmfecdoc: null,
      docmtentotr: null,
      ciatab: null,
      filteredCiatab: null,
      producto: null,
      fecha_documento: null,
    };
  },
  created() {
    this.ciatab = this.listValuesCiatab;
    if (this.ciatab.length == 0) {
      this.ciatab = this.listValuesCiatab;
    }
  },
  mounted() {
    this.cargaMaestro();
  },
  computed: {},
  methods: {
    closeBasic() {
      this.openbasic = false;
    },
    openDialog(produc) {
      this.openbasic = true;
      this.docdprd = produc.docdprd;
      this.docdesc = produc.docdesc;
      this.docdnumlin = produc.docdnumlin;
      this.docdunivta = produc.docdunivta;
      this.docdprecuni = produc.docdprcuni;
    },
    listValuesCiatab() {
      try {
        this.loadListValuesCiatab(true);
        const ciatab =
          this.$store.getters['listvalues/listvalues']['listvalues'];
        console.log(ciatab);
        return ciatab.filter((ciatab) => {
          if (ciatab.ciacod != 0) {
            return true;
          }
          if (ciatab.ciacod != 0) {
            return true;
          }
          console.log(ciatab);
          return true;
        });
      } catch (error) {
        console.log(error.messages);
        return true;
      }
    },
    searchCiatab(event) {
      const companias = [];
      console.log('search');

      setTimeout(() => {
        for (const key in this.$store.getters['listvalues/listvalues'][
          'listvalues'
        ]) {
          companias.push(
            this.$store.getters['listvalues/listvalues']['listvalues'][key]
              .ciacod +
              ' - ' +
              this.$store.getters['listvalues/listvalues']['listvalues'][key]
                .cianombre
          );
        }
        if (!event.query.trim().length) {
          this.filteredCiatab = [...companias];
        } else {
          this.filteredCiatab = companias.filter((country) => {
            return country.toLowerCase().includes(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    async loadDetail(refresh = false) {
      this.detallebill = null;
      const formData = {
        docmcia: this.docmcia,
        docmdoc: this.docmdoc,
      };
      try {
        await this.$store.dispatch('billings/loadDetailBill', formData, {
          forceRefresh: refresh,
        });
      } catch (error) {
        this.error = error.message || 'algo salió mal';
        console.log(error.message);
      }
    },
    cargaMaestro() {
      this.detallebill = null;
      this.detallebill = null;

      let contador = 0;

      const billings = this.$store.getters['billings/billings']['billings'];
      for (const key in billings) {
        //console.log(billings[key].docmnom);

        if (Number(billings[key].docmdoc) == Number(this.docmdoc)) {
          this.docmcia = billings[key].docmcia;
          for (const key in this.$store.getters['listvalues/listvalues'][
            'listvalues'
          ]) {
            if (
              (this.$store.getters['listvalues/listvalues']['listvalues'][
                key
              ].ciacod = this.docmcia)
            ) {
              this.cia =
                this.$store.getters['listvalues/listvalues']['listvalues'][key]
                  .ciacod +
                ' - ' +
                this.$store.getters['listvalues/listvalues']['listvalues'][key]
                  .cianombre;
            }
          }
          this.docmnom = billings[key].docmnom;
          this.docmdir = billings[key].docmdir;
          this.docmnit = billings[key].docmnit;
          //this.docmfecdoc = this.dateFormatOracle(billings[key].docmfecdoc);

          this.docmfecdoc = this.establecerFecha(billings[key].docmfecdoc);
          this.fecha_documento = billings[key].docmfecdoc;
          this.docmtentotr = billings[key].docmtentotr;
          this.docmper = billings[key].docmper;
          this.docmtip = billings[key].docmtip;
          this.loadDetail(true, this.docmdoc);
          while (billings[key].items.length == 0) {
            contador++;
            if (contador == 5) {
              break;
            }
          }
          setTimeout(() => {
            this.detallebill = null;
            this.detallebill = billings[key].items;
            this.ordenarDesc(this.detallebill, 'docdnumlin');
          }, 500);
        }
      }
    },
    dateFormatOracle(fecha) {
      const date = new Date(fecha);
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate() + 1;

      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }
      return dt + '/' + month + '/' + year;
    },
    dateFormatOracleUpdate(fecha, mes) {
      const date = new Date(fecha);
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();
      console.log(String(year));
      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }
      return dt + '-' + mes + '-' + String(year);
    },
    getMonthDay(fecha) {
      console.log(fecha);

      const date = new Date(fecha);
      console.log(date);
      let month = date.getMonth() + 1;
      let dt = date.getDate();

      console.log(month);
      console.log(dt);
      return dt;
    },
    getMonthOracle(fecha) {
      console.log(fecha);
      const date = new Date(fecha);
      console.log(date);
      let month = date.getMonth() + 1;
      let dt = date.getDate();

      console.log(dt);
      return month;
    },
    dateFormatOracleUpdate2(fecha) {
      //2023-04-09T06:00:00.000Z
      console.log(fecha);
      let dia = fecha.substr(8, 2);
      console.log(dia);

      let mes = fecha.substr(5, 2);
      console.log(mes);

      let year = fecha.substr(0, 4);
      console.log(year);

      //let year = fecha.getFullYear;
      if (parseInt(dia) > 12) {
        return year + '-' + mes + '-' + dia + ' 00:00:00';
      } else {
        return year + '-' + mes + '-' + dia + ' 00:00:00';
      }
    },
    ordenarDesc(p_array_json, p_key) {
      p_array_json.sort(function (a, b) {
        return a[p_key] > b[p_key];
      });
    },
    addZero(i) {
      if (i < 10) {
        i = '0' + i;
      }
      return i;
    },
    /*doreliagonzalez@hotmail.com*/
    async updateDocmt() {
      const formData = {
        cia: this.cia.substr(0, this.cia.indexOf('-') - 1),
        per: this.docmper,
        tip: this.docmtip,
        doc: this.docmdoc,
        tipped: null,
        ped: this.docmdoc,
        fecped: null,
        afe: null,
        con: null,
        cte: null,
        ruta: null,
        nom: this.docmnom,
        dir: this.docmdir,
        valfle: null,
        klfle: null,
        imp: null,
        iva: null,
        tot: null,
        exe: null,
        msg: null,
        salcte: null,
        limcre: null,
        chq: null,
        flg: null,
        des: null,
        fecdoc: this.convertirFecha(this.docmfecdoc),
        chqa: null,
        ant: null,
        anta: null,
        mini: null,
        minia: null,
        vprm: null,
        mrk: null,
        klprd: null,
        rate: null,
        crr: null,
        gra: null,
        xen: null,
        dol: null,
        env: null,
        dcr: null,
        nit: this.docmnit,
        sbr: null,
        valpro: null,
        valproi: null,
        voucher: null,
        numauto: null,
        tarcre: null,
        entdpper: null,
        entdir: null,
        crg: null,
        tra: null,
        bodcon: null,
        sold: null,
        ship: null,
        ord: null,
        fecent: null,
        urg: null,
        conv: null,
        sts: null,
        tda: null,
        ser: null,
        tent: null,
        tentotr: this.docmtentotr,
        pn: null,
        pb: null,
        blts: null,
        cajas: null,
        via: null,
        tipbl: null,
        bl: null,
        emp: null,
        afeser: null,
        uuid: null,
        feldoc: null,
        felser: null,
        felfec: null,
      };
      const response = await this.$store.dispatch(
        'billings/actualizaMaestro',
        formData
      );
      if (response.codigo === 1) {
        this.$toast.add({
          severity: 'success',
          summary: 'Modificacion',
          detail: response.mensaje,
          //group: 'br',
          life: 2000,
        });
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'Modificacion',
          detail: response.mensaje,
          //group: 'br',
          life: 2000,
        });
      }
      setTimeout(() => {
        this.$router.replace('/Billing');
      }, 3000);
    },
    async updateDocdt() {
      console.log(this.docdprd);
      const formData = {
        desc: this.docdesc,
        des: null,
        bar: null,
        ref: null,
        lin: null,
        valdol: null,
        proesp: null,
        ped: null,
        prcuni: this.docdprecuni,
        valproi: null,
        tip: this.docmtip,
        ruta: null,
        orden: null,
        cte: null,
        tipfac: null,
        reg: null,
        kl: null,
        prd: this.docdprd,
        tipevento: null,
        docprv: null,
        klb: null,
        per: this.docmper,
        cls: null,
        prom: null,
        cu: null,
        valcmp: null,
        fecped: null,
        ctoultc: null,
        texto: null,
        prom1: null,
        otr: null,
        cia: this.docmcia,
        doc: this.docmdoc,
        garf: null,
        ntran: null,
        precaj: this.docdprecuni,
        fob: null,
        dolcmp: null,
        bod: null,
        facmed: null,
        cstdol: null,
        univta: this.docdunivta,
        precaj1: this.docdprecuni,
        anio: null,
        precaj2: this.docdprecuni,
        vprm: null,
        flete: null,
        valpro: null,
        unimed: null,
        boda: null,
        emp2: null,
        emp1: null,
        concen: null,
        dcr: null,
        lot: null,
        ngar: null,
        vend: null,
        ordinterna: null,
        mes: null,
        gar: null,
        cat: null,
        numfac: null,
        iva: null,
        cstvta: null,
        seg: null,
        ser: null,
        vallin: null,
        flg: null,
        ctoprome: null,
        numlin: this.docdnumlin,
        sublin: null,
        bodcon: null,
        p_docmgiro: null,
        ctopromo: null,
        tda: null,
        env: null,
        bonif: null,
        esc: null,
        mod: null,
        msg: null,
        creg: null,
        bl: null,
        tipdes: null,
        fecdoc: null,
        presp: null,
        tipped: null,
        cntvta: null,
        salser: null,
        nomgen: null,
      };
      console.log(formData);

      const response = await this.$store.dispatch(
        'billings/actualizaDoct',
        formData
      );
      console.log(response.codigo);
      if (response.codigo === 1) {
        this.$toast.add({
          severity: 'success',
          summary: 'Modificacion',
          detail: response.mensaje,
          //group: 'br',
          life: 2000,
        });
        this.cargaMaestro();
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'Modificacion',
          detail: response.mensaje,
          //group: 'br',
          life: 2000,
        });
      }
      this.openbasic = false;
    },
    establecerFecha(fechaString) {
      console.log(fechaString);
      let partesFecha = fechaString.split('-');
      let anio = parseInt(partesFecha[0], 10);
      let mes = parseInt(partesFecha[1], 10) - 1; // Restar 1 al mes porque enero es 0
      let dia = parseInt(partesFecha[2], 10);

      // Obtener la hora actual
      let ahora = new Date();
      let horas = ahora.getHours();
      let minutos = ahora.getMinutes();

      // Crear el objeto Date con la fecha y hora
      const fecha = new Date(anio, mes, dia, horas, minutos);
      //this.fechaSeleccionada = fecha;
      console.log(fecha);
      return fecha;
    },
    convertirFecha(fechaString) {
      console.log(fechaString);
      // Crear un nuevo objeto Date con la fecha proporcionada
      let fecha = new Date(fechaString);
      console.log(fecha);
      // Obtener los componentes de la fecha
      let year = fecha.getFullYear();
      let month = ('0' + (fecha.getMonth() + 1)).slice(-2); // Agregar 1 al mes porque enero es 0
      let day = ('0' + fecha.getDate()).slice(-2);
      let hours = ('0' + fecha.getHours()).slice(-2);
      let minutes = ('0' + fecha.getMinutes()).slice(-2);

      // Crear la cadena en el formato deseado
      let resultado = `${year}-${month}-${day} ${hours}:${minutes}`;

      return resultado;
    },
  },
};
</script>
<style>
.p-inputtext {
  max-width: 100%; /* Limita el ancho del campo de entrada al 100% del contenedor */
  overflow: hidden; /* Oculta el contenido que desborda */
  text-overflow: ellipsis; /* Agrega puntos suspensivos (...) al texto que desborda */
  white-space: nowrap; /* Evita el salto de línea */
}
</style>
