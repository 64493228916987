<template>
  <Toast />
  <section>
    <base-card
      ><div class="card">
        <form @submit.prevent="updateDeliverie">
          <Toolbar
            ><template v-slot:right
              ><Button
                label="Guardar"
                icon="pi pi-save"
                @click="updateDeliverie()"
                class="p-button-raised p-button-rounded" /></template
          ></Toolbar>
          <Divider align="left"
            ><div class="p-field"></div>
            <div class="p-d-inline-flex p-ai-center">
              <i class="pi pi-id-card p-mr-1"></i>
              <h3>Generales</h3>
            </div>
          </Divider>
          <br />
          <span class="p-float-label"> </span>
          <div class="p-formgroup-inline">
            <div class="p-field">
              <div class="form-control" :class="{ invalid: !compania }">
                <span class="p-float-label">
                  <AutoComplete
                    :dropdown="true"
                    size="35"
                    v-model="compania.val"
                    placeholder="Seleccione una compañia"
                    :suggestions="filteredCiatab"
                    @complete="searchCiatab($event)"
                /></span>
              </div>
            </div>
            <div class="p-field">
              <div class="form-control" :class="{ invalid: !documento }">
                <span class="p-float-label">
                  <InputNumber id="documento" v-model="documento.val" />
                  <label for="documento">No. de Pedido</label>
                </span>
              </div>
            </div>
            <div class="p-field">
              <span class="p-float-label">
                <AutoComplete
                  :dropdown="true"
                  v-model="prioridad.val"
                  size="35"
                  :suggestions="filteredPrioridades"
                  @complete="searchPrioridad($event)"
                  placeholder="Seleccione la prioridad del envío"
              /></span>
            </div>
            <div class="p-field"></div>
          </div>
          <div>
            <br />
            <br />
          </div>
          <div class="p-formgroup-inline">
            <div class="p-field">
              <span class="p-float-label">
                <AutoComplete
                  :dropdown="true"
                  size="35"
                  v-model="mensajero"
                  :suggestions="filteredMensajeros"
                  @complete="searchMensajeros($event)"
                  placeholder="Seleccione un Agente de Logística"
              /></span>
            </div>
            <div class="p-field">
              <span class="p-float-label">
                <Calendar
                  id="delfecdoc"
                  v-model="delfecdoc.val"
                  :showIcon="false"
                  date-format="dd/mm/yy"
                />
                <label for="delfecdoc">Fecha de Envío</label>
              </span>
            </div>
          </div>
          <div>
            <br />
            <br />
          </div>
          <div class="p-formgroup-inline">
            <div class="form-control" :class="{ invalid: !delnom.isValida }">
              <span class="p-float-label">
                <InputText
                  id="delnom"
                  type="text"
                  @blur="clearValidity('delnom')"
                  size="100"
                  :maxlength="100"
                  v-model="delnom.val"
                />
                <label for="delnom">Nombre de quien recibe</label>
              </span>
              <p v-if="!delnom.isValida"></p>
            </div>
          </div>
          <div>
            <br />
            <br />
          </div>
          <div class="p-formgroup-inline">
            <div class="form-control" :class="{ invalid: !deldir.isValida }">
              <span class="p-float-label">
                <Textarea
                  id="deldir"
                  type="text"
                  rows="2"
                  :autoResize="false"
                  :maxlength="200"
                  v-model="deldir.val"
                  cols="110"
                />
                <label for="deldir">Dirección de Entrega</label>
              </span>
              <p v-if="!deldir.isValida">
                Debe ingresar un valor para el dirección ó contiene caracteres
                invalidos (/)
              </p>
            </div>
          </div>
          <div>
            <br />
            <br />
          </div>
          <div class="p-formgroup-inline">
            <div class="form-control" :class="{ invalid: !nit.isValida }">
              <span class="p-float-label">
                <InputText
                  id="nit"
                  type="text"
                  size="30"
                  :maxlength="30"
                  v-model="nit.val"
                />
                <label for="nit">NIT</label>
              </span>
              <p v-if="!nit.isValida"></p>
            </div>
            <div class="form-control">
              <span class="p-float-label">
                <InputText
                  id="deltel"
                  type="text"
                  size="30"
                  :maxlength="30"
                  v-model="deltel"
                />
                <label for="deltel">Teléfono de Contacto</label>
              </span>
            </div>
          </div>
          <div>
            <br />
          </div>
          <div class="p-formgroup-inline">
            <div class="form-control">
              <span class="p-float-label">
                <InputNumber
                  id="delbultos"
                  type="text"
                  size="20"
                  :maxlength="5"
                  v-model="delbultos"
                />
                <label for="delbultos">Cantidad de Bultos</label>
              </span>
              <div>
                <br />
              </div>
              <div class="p-field">
                <div class="form-control"></div>
              </div>
            </div>
          </div>
          <div class="p-formgroup-inline">
            <span class="p-float-label">
              <Textarea
                v-model="delmmsg"
                placeholder="Ingresa observaciones sobre este envío"
                :autoResize="true"
                rows="5"
                :maxlength="1000"
                cols="110"
            /></span>
          </div>
          <p v-if="!formIsValida">
            Por favor revisar que los campos se encuentren llenos y probar
            nuevamente.
          </p>
          <br />
        </form></div
    ></base-card>
  </section>
</template>

<script>
//snew/CusnewUpdate.vue'
export default {
  props: ['delcia', 'deldoc', 'delnit', 'delenv'],
  data() {
    return {
      agente_logistica: '',
      nivel_prioridad: '',
      formIsValida: true,
      contador: 0,
      ciatab: null,
      filteredCiatab: null,
      mensajeros: null,
      filteredMensajeros: null,
      prioridades: null,
      filteredPrioridades: null,

      selectedDeliverie: null,

      isLoading: false,
      compania: { val: '', isValida: true },
      nit: { val: '', isValida: true },
      documento: { val: 0, isValida: true },
      envio: 0,
      mensajero: '',
      prioridad: { val: '', isValida: true },

      delmens: { val: '', isValida: true },
      delfecdoc: { val: '', isValida: true },
      delnom: { val: '', isValida: true },
      deldir: { val: '', isValida: true },
      deltel: '',
      dellatitud: '',
      dellongitud: '',
      delbultos: null,
      delprioridad: { val: '', isValida: true },
      delflg: '',
      deldispositivo: '',
      delfirma: '',
      deluuid: '',
      delfelfec: '',
      delfeldoc: '',
      delfelser: '',
      delmmsg: '',
    };
  },
  computed: {
    isLoggedIn() {
      //console.log(this.delenv);
      return this.$store.getters.isAuthenticated;
    },
    listValuesCiatab() {
      try {
        const ciatab =
          this.$store.getters['listvalues/listvalues']['listvalues'];
        return ciatab.filter((ciatab) => {
          if (ciatab.ciacod != 0) {
            return true;
          }
          if (ciatab.ciacod != 0) {
            return true;
          }

          return true;
        });
      } catch (error) {
        ////console.log(error.messages);
        return false;
      }
    },
    listValuesMensajeros() {
      try {
        const mensajeros =
          this.$store.getters['listvalues/listvalues']['listvaluesmensajeros'];
        return mensajeros.filter((mensajeros) => {
          if (mensajeros.menscod != 0) {
            return true;
          }
          if (mensajeros.menscod != 0) {
            return true;
          }
          ////console.log(mensajeros);

          return true;
        });
      } catch (error) {
        ////console.log(error.messages);
        return true;
      }
    },
    listValuesPrioridades() {
      try {
        ////console.log(
        ///this.$store.getters['listvalues/listvalues']['listvaluesprioridades']
        //);
        const prioridades =
          this.$store.getters['listvalues/listvalues']['listvaluesprioridades'];
        return prioridades.filter((prioridades) => {
          if (prioridades.pricod != 0) {
            return true;
          }
          if (prioridades.pricod != 0) {
            return true;
          }
          ////console.log(prioridades);

          return true;
        });
      } catch (error) {
        ////console.log(error.messages);
        return true;
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.loadDeliverie();
    }, 800);
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.goBack, false);
    }
  },
  created() {
    this.loadDeliveries(true, 2);
    this.loadListValuesMensajeros(true);
    this.loadListValuesPrioridades(true);
    this.loadListValuesCiatab(true);
    this.ciatab = this.listValuesCiatab;
    this.mensajeros = this.listValuesMensajeros;
    this.prioridades = this.listValuesPrioridades;
    console.log('companias: ' + this.ciatab.length);
    console.log('mensajero: ' + this.mensajeros.length);
    console.log('prioridades: ' + this.prioridades.length);

    setTimeout(() => {
      while (this.ciatab.length === 0) {
        this.contador++;
        console.log('Recuperacion de Companias...');
        if (this.ciatab.length === 0 || this.ciatab == null) {
          this.loadListValuesCiatab(true);
          this.ciatab = this.listValuesCiatab;
        }
        if (this.contador === 3) {
          break;
        }
      }

      while (this.mensajeros.length === 0) {
        //console.log('estoy aca mensajeros');
        this.contador++;

        if (this.mensajeros.length === 0 || this.mensajeros == null) {
          console.log('se ira a recuperar mensajeros.');
          this.loadListValuesMensajeros(true);
          this.mensajeros = this.listValuesMensajeros;
        }
        if (this.contador === 5) {
          break;
        }
      }

      while (this.prioridades.length === 0) {
        //console.log('estoy aca prioridades');
        this.contador++;
        //console.log(this.contador);

        if (this.prioridades.length === 0 || this.prioridades == null) {
          this.loadListValuesPrioridades(true);
          this.prioridades = this.listValuesPrioridades;
        }
        if (this.contador === 5) {
          break;
        }
      }
    }, 700);
  },
  methods: {
    getMonthDay(fecha) {
      //console.log(fecha);

      const date = new Date(fecha.toString());
      //console.log(date);
      //let month = date.getMonth() + 1;
      let dt = date.getDate();

      //console.log(month);
      //console.log(dt);
      return dt;
    },
    getMonthOracle(fecha) {
      //console.log(fecha);

      const date = new Date(fecha);
      //console.log(date);
      let month = date.getMonth() + 1;
      //let dt = date.getDate();

      //console.log(month);
      //console.log(dt);
      return month;
    },
    dateFormatOracleUpdate(fecha, mes) {
      const date = new Date(fecha);
      //console.log(date);
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();

      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }
      return dt + '-' + mes + '-' + String(year).substr(2, 2);
    },
    dateFormatOracleUpdate2(fecha) {
      console.log(fecha);
      let dia = fecha.substr(0, 2);
      let mes = fecha.substr(3, 2);
      let year = fecha.substr(6, 4);
      if (parseInt(dia) > 12) {
        return year + '-' + mes + '-' + dia + ' 00:00:00';
      } else {
        return year + '-' + mes + '-' + dia + ' 00:00:00';
      }
    },
    dateFormatOracle(fecha) {
      if (typeof fecha === 'string') {
        console.log(fecha);
        //const date = new Date(fecha);

        const parts = fecha.split('-');
        const year2 = parseInt(parts[0]);
        const month2 = parseInt(parts[1]) - 1; // Los meses en JavaScript son 0-indexados (enero es 0)
        const day2 = parseInt(parts[2]);

        const date2 = new Date(year2, month2, day2);
        console.log(date2);
        const year = date2.getFullYear();
        let month = date2.getMonth() + 1;
        let dt = date2.getDate();
        //console.log(month);
        //console.log(dt);

        if (dt < 10) {
          dt = '0' + dt;
        }
        if (month < 10) {
          month = '0' + month;
        }
        //console.log(month);

        return dt + '/' + month + '/' + year;
      } else {
        fecha = new Date(fecha);

        console.log(fecha); // Imprime la fecha en formato local

        // Para obtener los componentes individuales de la fecha
        const year = fecha.getFullYear();
        let month = fecha.getMonth() + 1; // Los meses son 0-indexados, por lo que debes sumar 1
        let day = fecha.getDate();

        if (day < 10) {
          day = '0' + day;
        }
        if (month < 10) {
          month = '0' + month;
        }
        //console.log(month);

        return day + '/' + month + '/' + year;
      }
    },
    searchMensajeros(event) {
      const mensajero = [];
      setTimeout(() => {
        //console.log(this.mensajeros);
        for (const key in this.mensajeros) {
          mensajero.push(
            this.mensajeros[key].menscod + ' - ' + this.mensajeros[key].mensnom
          );
        }
        if (!event.query.trim().length) {
          this.filteredMensajeros = [...mensajero];
        } else {
          this.filteredMensajeros = mensajero.filter((country) => {
            return country.toLowerCase().includes(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    searchCiatab(event) {
      const companias = [];
      setTimeout(() => {
        for (const key in this.ciatab) {
          companias.push(
            this.ciatab[key].ciacod + ' - ' + this.ciatab[key].cianombre
          );
        }
        if (!event.query.trim().length) {
          this.filteredCiatab = [...companias];
        } else {
          this.filteredCiatab = companias.filter((country) => {
            return country.toLowerCase().includes(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    searchPrioridad(event) {
      const prioridades = [];
      setTimeout(() => {
        for (const key in this.prioridades) {
          prioridades.push(
            this.prioridades[key].pricod + ' - ' + this.prioridades[key].pridesc
          );
        }
        if (!event.query.trim().length) {
          this.filteredPrioridades = [...prioridades];
        } else {
          this.filteredPrioridades = prioridades.filter((country) => {
            return country.toLowerCase().includes(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    cargaCompania(delcia) {
      console.log('cargando compania ' + this.listValuesCiatab.length);
      if (this.listValuesCiatab.length === 0) {
        this.loadListValuesCiatab(true);
        this.li;
      }
      console.log('cargando compania ' + this.listValuesCiatab);
      const cia = this.listValuesCiatab.find(
        (m) => Number(m.ciacod) == Number(delcia)
      );
      console.log(cia);
      if (cia) {
        console.log('Encont0rada');
        //this.mensajero = `${mensajeroEncontrado.menscod} - ${mensajeroEncontrado.mensnom}`;
        this.compania.val = `${delcia} - ${cia.cianombre}`;
      } else {
        this.$toast.add({
          severity: 'info',
          summary: 'Compañia',
          detail:
            'No se encontro la companñia del envio por favor seleccione una',
          //group: 'br',
          life: 2000,
        });
      }
    },
    cargaMensajeroName(mensajero) {
      console.log('buscando mensajero....' + mensajero);
      for (let m = 0; m < 6 && this.mensajero.length === 0; m++) {
        this.loadListValuesMensajeros(true);
        this.mensajeros = this.listValuesMensajeros;

        for (const key3 in this.mensajeros) {
          if (this.mensajeros[key3].mensnom === mensajero) {
            this.mensajero = `${this.mensajeros[key3].menscod} - ${this.mensajeros[key3].mensnom}`;
            console.log(this.mensajero);
            break;
          }
        }
      }
    },
    cargaMensajero(mensajero) {
      console.log(
        'buscando mensajero...' + Number(mensajero) + ' -> ' + mensajero
      );
      //176227073 gestion del banco
      if (Number.isNaN(Number(mensajero))) {
        console.log('Se irá a buscar por medio del nombre');

        this.cargaMensajeroName(mensajero);
      } else {
        this.loadListValuesMensajeros(true);

        const mensajeroEncontrado = this.listValuesMensajeros.find(
          (m) => Number(m.menscod) === Number(mensajero)
        );

        if (mensajeroEncontrado) {
          this.mensajero = `${mensajeroEncontrado.menscod} - ${mensajeroEncontrado.mensnom}`;
          console.log(this.mensajero);
        } else {
          this.$toast.add({
            severity: 'info',
            summary: 'Agente de Logistica',
            detail:
              'No se encontro el Agente de Logistica del envio por favor seleccione una',
            //group: 'br',
            life: 2000,
          });
        }
      }

      /*var m = 0;
      console.log(
        'buscando mensajero...' + Number(mensajero) + ' -> ' + mensajero
      );
      while (this.mensajero.length === 0) {
        this.mensajeros = this.listValuesMensajeros;
        m++;
        console.log(this.mensajeros);
        for (const key3 in this.mensajeros) {
          console.log(Number(this.mensajeros[key3].menscod));
          if (Number(this.mensajeros[key3].menscod) === Number(mensajero)) {
            this.mensajero =
              this.mensajeros[key3].menscod +
              ' - ' +
              this.mensajeros[key3].mensnom;
            console.log(this.mensajero);
          } else if (Number.isNaN(mensajero)) {
            console.log('Se ira a buscar por medio del nombre');
            this.cargaMensajeroName(mensajero);
          }
        }
        if (m === 6) {
          break;
        }
      }*/
    },
    cargaPrioridad(prioridad) {
      let m = 0;
      console.log(
        'buscando prioridad...' + Number(prioridad) + ' -> ' + prioridad
      );
      while (this.prioridad.val.length === 0 && m < 6) {
        this.loadListValuesPrioridades(true);
        this.prioridades = this.listValuesPrioridades;
        console.log(this.prioridades);
        for (const key4 in this.prioridades) {
          if (Number(this.prioridades[key4].pricod) === Number(prioridad)) {
            this.prioridad.val =
              prioridad + ' - ' + this.prioridades[key4].pridesc;
            break;
          }
        }
        m++;
      }

      if (this.prioridad.val.length === 0) {
        console.log('NO SE ENCONTRÓ LA PRIORIDAD');
      }
    },
    loadDeliverie() {
      //console.log(this.delenv);
      //console.log(
      /*'cia: ' +
          this.delcia +
          ' doc: ' +
          this.deldoc +
          ' nit: ' +
          this.delnit +
          ' delenv: ' +
          this.delenv
      );*/
      for (const key in this.$store.getters['deliveries/deliveries'][
        'deliveries'
      ]) {
        if (
          this.$store.getters['deliveries/deliveries']['deliveries'][key]
            .delcia == this.delcia &&
          this.$store.getters['deliveries/deliveries']['deliveries'][key]
            .deldoc == this.deldoc &&
          this.$store.getters['deliveries/deliveries']['deliveries'][key]
            .delnit == this.delnit &&
          this.$store.getters['deliveries/deliveries']['deliveries'][key]
            .delenv == this.delenv
        ) {
          this.cargaCompania(this.delcia);

          if (this.compania.val.length === 0) {
            this.cargaCompania(this.delcia);
          }
          this.selectedDeliverie =
            this.$store.getters['deliveries/deliveries']['deliveries'][key];
          //console.log(this.selectedDeliverie);
          //console.log('************************');
          //console.log(this.selectedDeliverie.delmens);
          this.cargaMensajero(this.selectedDeliverie.delmens);
          this.cargaPrioridad(this.selectedDeliverie.delprioridad);

          this.nivel_prioridad =
            this.selectedDeliverie.delprioridad == null
              ? ''
              : this.selectedDeliverie.delprioridad;

          this.agente_logistica =
            this.selectedDeliverie.delmens == null
              ? ''
              : this.selectedDeliverie.delmens;

          this.documento.val =
            this.selectedDeliverie.deldoc == null
              ? ''
              : this.selectedDeliverie.deldoc;

          this.envio = this.selectedDeliverie.delenv;

          this.nit.val =
            this.selectedDeliverie.delnit == null
              ? ''
              : this.selectedDeliverie.delnit;

          this.selectedDeliverie.deldoc == null
            ? ''
            : this.selectedDeliverie.deldoc;

          this.delnom.val =
            this.selectedDeliverie.delnom == null
              ? ''
              : this.selectedDeliverie.delnom;
          this.deldir.val =
            this.selectedDeliverie.deldir == null
              ? ''
              : this.selectedDeliverie.deldir;
          this.deltel =
            this.selectedDeliverie.deltel == null
              ? ''
              : this.selectedDeliverie.deltel;

          this.delbultos =
            this.selectedDeliverie.delbultos == null
              ? ''
              : this.selectedDeliverie.delbultos;
          this.delmmsg =
            this.selectedDeliverie.delmmsg == null
              ? ''
              : this.selectedDeliverie.delmmsg;

          //problemas con fecha:
          //console.log(this.selectedDeliverie.delfecdoc);

          this.delfecdoc.val =
            this.selectedDeliverie.delfecdoc == null
              ? ''
              : this.selectedDeliverie.delfecdoc;
          //console.log(this.selectedDeliverie.delfecdoc);

          if (!this.delfecdoc.val.includes('/')) {
            this.delfecdoc.val = this.dateFormatOracle(this.delfecdoc.val);
          }
          //console.log('************************');
          //console.log(this.delfecdoc.val);
          //console.log(this.mensajero);
          //console.log(this.prioridad.val);
          if (this.prioridad.val.length == 0) {
            //console.log('***prioridad no desplegada***');
          }
          //console.log('************************');
        }
      }
    },
    async loadListValuesMensajeros(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('listvalues/loadListValuesMensajeros', {
          forceRefresh: refresh,
        }); //viene de la clases action.js G
      } catch (error) {
        this.error = error.message || 'algo salió mal';
        ////console.log(error.message);
      }
      this.isLoading = false;
    },
    async loadListValuesCiatab(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('listvalues/loadListValuesCiatab', {
          forceRefresh: refresh,
        }); //viene de la clases action.js G
      } catch (error) {
        this.error = error.message || 'algo salió mal';
        ////console.log(error.message);
      }
      this.isLoading = false;
    },
    async loadListValuesPrioridades(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('listvalues/loadListValuesPrioridades', {
          forceRefresh: refresh,
        }); //viene de la clases action.js G
      } catch (error) {
        this.error = error.message || 'algo salió mal';
        ////console.log(error.message);
      }
      this.isLoading = false;
    },
    async loadDeliveries(refresh = false, cuscia) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('deliveries/loadDeliveries', cuscia, {
          forceRefresh: refresh,
        }); //viene de la clases action.js G
      } catch (error) {
        //console.log('loadDeliveries # errores');

        //console.log(error.message);
        this.error = error.message || 'algo salió mal';
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    async updateDeliverie() {
      //console.log(this.delfecdoc.val.toString());
      if (this.delfecdoc.val.toString().includes('/')) {
        const [day, month, year] = this.delfecdoc.val.split('/');
        /*console.log(month); // 06
        console.log(day); // 15
        console.log(year); // 2022*/
        this.delfecdoc.val = new Date(+year, +month - 1, +day);
      } else {
        //console.log('estas mal? ' + this.delfecdoc.val);
      }
      let meses = [
        'JAN',
        'JAN',
        'FEB',
        'MAR',
        'APR',
        'MAY',
        'JUN',
        'JUL',
        'AUG',
        'SEP',
        'OCT',
        'NOV',
        'DEC',
      ];
      for (const key4 in this.mensajeros) {
        if (this.mensajeros[key4].mensnom === this.agente_logistica) {
          this.agente_logistica = this.mensajeros[key4].menscod;
        }
      }
      //esta funcion esta cambiando de orden el dia y el mes
      //console.log('prioridad: ' + this.nivel_prioridad);
      //console.log('mensajero: ' + this.agente_logistica);
      //console.log('fecha de envio: ' + this.delfecdoc.val);
      //console.log(this.getMonthDay(this.delfecdoc.val));
      //if (parseInt(this.getMonthDay(this.delfecdoc.val)) >= 12) {
      this.delfecdoc.val = this.dateFormatOracle(this.delfecdoc.val);
      //}
      //console.log(this.delfecdoc.val);

      //console.log(this.delfecdoc.val);

      let dateEntrega = this.delfecdoc.val.replace('/', '');
      dateEntrega = dateEntrega.replace('/', '');

      let date2 = String(
        this.getMonthOracle(this.dateFormatOracleUpdate2(this.delfecdoc.val))
      );
      if (date2.indexOf('0') > 0) {
        date2.replace('0', '');
      }
      date2 = parseInt(date2);
      dateEntrega = this.dateFormatOracleUpdate(
        this.dateFormatOracleUpdate2(this.delfecdoc.val),
        meses[date2]
      );
      console.log(dateEntrega);

      const formData = {
        cia:
          this.compania.val.length == 0
            ? this.delcia
            : this.compania.val.substr(0, this.compania.val.indexOf('-') - 1),
        doc: this.documento.val,
        env: this.envio,
        mens:
          this.mensajero.length == 0
            ? this.agente_logistica
            : this.mensajero.substr(0, this.mensajero.indexOf('-') - 1),
        fecdoc: this.delfecdoc.val,
        nom: this.delnom.val,
        dir: this.deldir.val,
        nit: this.delnit,
        tel: this.deltel,
        latitud: this.dellatitud,
        longitud: this.dellongitud,
        bultos: this.delbultos.length == 0 ? 0 : this.delbultos,
        prioridad:
          this.prioridad.val.length == 0
            ? this.nivel_prioridad
            : this.prioridad.val.substr(0, this.prioridad.val.indexOf('-') - 1),
        flg: 'S',
        dispositivo: this.deldispositivo,
        firma: this.delfirma,
        uuid: this.deluuid,
        felfec: this.delfelfec,
        feldoc: this.delfeldoc,
        felser: this.delfelser,
        mmsg: this.delmmsg,
      };
      //console.log(formData);

      const response = await this.$store.dispatch(
        'deliveries/updateDeliveries',
        formData
      );
      console.log(response)
      if (response.codigo == 1) {
        this.$toast.add({
          severity: 'success',
          summary: 'Modificacion',
          detail: response.mensaje,
          //group: 'br',
          life: 2000,
        });
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'Modificacion',
          detail: response.mensaje,
          //group: 'br',
          life: 2000,
        });
      }

      setTimeout(() => {
        this.$emit('save-data', formData, null);
        this.$router.replace('/Deliveries');
      }, 3000);
      //
    },
  },
};
</script>
