export default {
  billings(state) {
    return state.billings;
  },
  docdt(state) {
    return state.docdt;
  },
  hasBillings(state) {
    //sirve para verificar si tenemos valores por mostrar.
    return state.billings && state.billings['billings'].length > 0;
  },
  hasDocdt(state) {
    //sirve para verificar si tenemos valores por mostrar.
    return state.billings && state.billings['docdt'].length > 0;
  },
  isBillings(_, getters) {
    const billings = getters.billings;
    const userId = getters.billings['billings'].docmdoc;
    return billings['billings'].some((billings) => billings.docmdoc === userId);
  },
  shouldUpdate(state) {
    const lastFetch = state.lastFetch;
    if (!lastFetch) {
      return true;
    }
    const currentTimeStamp = new Date().getTime();
    return (currentTimeStamp - lastFetch) / 1000 > 60;
  },
};
