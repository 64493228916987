<template>
  <div
    class="menu-wrapper"
    :class="{ 'layout-sidebar-active': sidebarActive }"
    @click="onMenuClick"
    @mouseover="onSidebarMouseOver"
    @mouseleave="onSidebarMouseLeave"
  >
    <div class="sidebar-logo">
      <router-link to="/home">
        <img
          src="assets/layout/images/logo-freya-single.svg"
          alt="freya-layout"
        />
      </router-link>
      <a class="sidebar-pin" @click="onToggleMenu($event)">
        <span class="pin"></span>
      </a>
    </div>

    <div class="layout-menu-container">
      <AppSubmenu
        class="layout-menu"
        :items="menu"
        :layoutMode="layoutMode"
        :parentMenuItemActive="true"
        :menuActive="menuActive"
        :mobileMenuActive="mobileMenuActive"
        :root="true"
        @menuitem-click="onMenuItemClick"
        @root-menuitem-click="onRootMenuItemClick"
      />
    </div>
  </div>
</template>

<script>
import AppSubmenu from './AppSubmenu';
export default {
  name: 'AppMenu',
  emits: [
    'menu-click',
    'menuitem-click',
    'root-menuitem-click',
    'toggle-menu',
    'sidebar-mouse-over',
    'sidebar-mouse-leave',
  ],
  props: {
    sidebarActive: Boolean,
    sidebarStatic: Boolean,
    layoutMode: String,
    menuActive: Boolean,
    mobileMenuActive: Boolean,
  },
  data() {
    return {
      menu: [
        { label: 'Inicio', icon: 'pi pi-home', to: '/home' },
        {
          label: 'Modulo de Facturacion',
          icon: 'pi pi-tag',
          items: [
            {
              label: 'Generales',
              icon: 'pi pi-star-o',
              items: [
                {
                  label: 'Ingreso de Envios',
                  icon: 'pi pi-id-card',
                  to: '/Deliveries',
                },
              ],
            },
            {
              label: 'Procesos',
              icon: 'pi pi-briefcase',
              items: [
                { label: 'Facturacion', icon: 'pi pi-desktop', to: '/Billing' },
              ],
            },
            {
              label: 'Consultas',
              icon: 'pi pi-book',
              items: [
                {
                  label: 'Consulta de Costos',
                  icon: 'pi pi-dollar', to: '/Consultas/Costos'
                },
                {
                  label: 'Facturación Histórica',
                  icon: 'pi pi-book', to: '/History/Billing'
                },
              ],
            },
            {
              label: 'Reportes',
              icon: 'pi pi-print',
              items: [
                {
                  label: 'No hay reportes disponibles por el momento',
                  icon: 'pi pi-exclamation-triangle',
                },
              ],
            },
          ],
        },
        /*{
          label: 'Modulo de Administracion',
          icon: 'pi pi-shield',
          items: [
            {
              label: 'Usuarios',
              icon: 'pi pi-user',
              items: [
                {
                  label: 'Creacion de Usuarios',
                  icon: 'pi pi-user-plus',
                },
                {
                  label: 'Modificacion de Claves de usuarios',
                  icon: 'pi pi-user-edit',
                },
              ],
            },
            {
              label: 'Menus',
              icon: 'pi pi-list',
              items: [
                {
                  label: 'Definición de Estructura de Menus',
                  icon: 'pi pi-table',
                },
                {
                  label: 'Creación Y Mantenimiento de Perfiles',
                  icon: 'pi pi-file',
                },
                {
                  label: 'Asignación de Usuarios X Perfiles',
                  icon: 'pi pi-clone',
                },
                {
                  label: 'Asignacion de Perfiles por Opción',
                  icon: 'pi pi-th-large',
                },
                {
                  label: 'Menus Asignados a Usuarios',
                  icon: 'pi pi-users',
                },
              ],
            },
          ],
        },*/
      ],
    };
  },
  methods: {
    onSidebarMouseOver() {
      if (this.layoutMode === 'sidebar' && !this.sidebarStatic) {
        this.$emit('sidebar-mouse-over');
      }
    },
    onSidebarMouseLeave() {
      if (this.layoutMode === 'sidebar' && !this.sidebarStatic) {
        setTimeout(() => {
          this.$emit('sidebar-mouse-leave');
        }, 250);
      }
    },
    onToggleMenu(event) {
      this.$emit('toggle-menu', event);
    },
    onMenuClick(event) {
      this.$emit('menu-click', event);
    },
    onMenuItemClick(event) {
      this.$emit('menuitem-click', event);
    },
    onRootMenuItemClick(event) {
      this.$emit('root-menuitem-click', event);
    },
  },
  components: { AppSubmenu },
};
</script>
