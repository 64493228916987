export default {
  insertBillings(state, payload) {
    console.log(payload);
    state.deliveries['billings'].push(payload);
  },
  setBillings(state, payload) {
    //console.log(state);
    //console.log(payload);
    state.billings['billings'] = payload;
  },
  setFetchTimestamp(state) {
    state.lastFetch = new Date().getTime();
  },
  deleteBillings(state, payload) {
    console.log('Mutation para delete ' + payload);
    state.billings['billings'].push(payload);
  },
  updateBillings(state, payload) {
    //console.log('test update');
    state.billings['billings'].push(payload);
  },
  setDocdt(state, payload) {
    //console.log(state);
    //console.log(payload);
    state.billings['docdt'] = payload;
  },
  setDocdtxDoc(state, payload) {
    //console.log(state);
    //console.log(payload);
    state.billings['docdt_doc'] = payload;
  },
};
