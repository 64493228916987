<template>
  <Toast />
  <section>
    <base-card>
      <h2>Ingreso de Entregas</h2>
      <deliveriesnewForm @save-data="saveData"></deliveriesnewForm
    ></base-card>
  </section>
</template>

<script>
import DeliveriesnewForm from '../../components/deliveries/DeliveriesnewForm.vue';

export default {
  components: {
    DeliveriesnewForm,
  },
  data() {
    return { error: null };
  },
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.goBack, false);
    }
  },
  methods: {
    handleError() {
      this.error = null;
    },
    async saveData(data, dir) {
      for (const key in dir) {
        let datadir = null;

        datadir = dir[key];
        console.log(datadir);
        this.$store.dispatch('deliveries/insertDeliveries', datadir);
      }

      try {
        await this.$store.dispatch('deliveries/insertDeliveries', data); //viene de la clases action.js G
        this.$toast.add({
          severity: 'success',
          summary: 'Guardado',
          detail: 'Envío Guardado',
          //group: 'br',
          life: 2000,
        });
        setTimeout(() => {
          this.$router.replace('/Deliveries');
        }, 3000);
      } catch (error) {
        console.log('paso algo');
        this.$toast.add({
          severity: 'error',
          summary: 'Error guardando envio',
          detail: error.message + '\n' + 'Por favor revisar todos sus campos',
          life: 5000,
        });
      }
      //
    },
    removeItemFromArr(arr, item) {
      console.log(arr);
      var i = arr.indexOf(item);

      if (i !== -1) {
        arr.splice(i, 1);
      }
    },
  },
};
</script>
