<template>
  <Toast />
  <Toolbar class="p-mb-4">
    <template v-slot:left>
      <Button
        label="Trasladar Pedidos"
        icon="pi pi-refresh"
        @click="trasladoDeliveries"
        class="p-button-secondary p-mr-2"
      />
    </template>
    <template v-slot:right>
      <SplitButton
        label="Reportes"
        :model="items"
        class="p-button-info p-mr-2"
      ></SplitButton>
      <Dialog
        header="Generación de Reporte de Envíos a un archivo de Texto"
        v-model:visible="displayBasictxt"
        :breakpoints="{ '740px': '75vw', '540px': '60vw' }"
        :style="{ width: '20vw' }"
      >
        <div class="center">
          <br />
          <div class="p-formgroup-inline">
            <span class="p-float-label">
              <Calendar
                position="center"
                id="delfecdoc"
                v-model="delfecdoc"
                :showIcon="false"
                date-format="dd/mm/yy"
              />
              <label for="delfecdoc">Fecha a generar</label>
            </span>
          </div>
        </div>

        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            @click="closeBasic"
            class="p-button-text"
          />
          <Button
            label="Generar TXT"
            icon="pi pi-check"
            @click="GeneraDataTXT"
            autofocus
          />
        </template>
      </Dialog>
      <Dialog
        header="Generación de Reporte de Envíos en PDF"
        v-model:visible="displayBasic"
        :breakpoints="{ '640px': '75vw', '540px': '60vw' }"
        :style="{ width: '20vw' }"
      >
        <div class="center">
          <br />
          <div class="p-formgroup-inline">
            <span class="p-float-label">
              <Calendar
                position="center"
                id="delfecdoc"
                v-model="delfecdoc"
                :showIcon="false"
                date-format="dd/mm/yy"
              />
              <label for="delfecdoc">Fecha a generar</label>
            </span>
          </div>
        </div>

        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            @click="closeBasic"
            class="p-button-text"
          />
          <Button
            label="Generar PDF"
            icon="pi pi-check"
            @click="exportPDF"
            autofocus
          />
        </template>
      </Dialog>
      <Button
        label="Nuevo"
        icon="pi pi-plus"
        @click="registroNuevo"
        class="p-button-success p-mr-2"
      />
    </template>
  </Toolbar>
  <DataTable
    ref="dv"
    responsiveLayout="scroll"
    id="entregas"
    :value="deliveries"
    dataKey="delenv"
    :paginator="true"
    :rows="25"
    :loading="loading1"
    :filters="filters"
    showGridlines
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :rowsPerPageOptions="[25, 50, 75, 100, 125]"
    currentPageReportTemplate="Viendo {first} a {last} de {totalRecords} entregas"
  >
    <template #header>
      <div
        class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between"
      >
        <span class="p-input-icon-left">
          <!-- <div class="p-inputgroup">
                  <InputText placeholder="Keyword" /><Button label="Search" />
                </div> -->

          <i class="pi pi-search" />
          <InputText
            v-model="filters['global'].value"
            placeholder="Buscar..."
            size="75"
          /><Button
            type="button"
            icon="pi pi-filter-slash"
            class="p-button-outlined"
            @click="initFilters()"
          />
        </span>
      </div>
    </template>
    <template #empty><b>No se encontró ningúna entrega ingresada.</b></template>
    <template #loading> Cargando todos las envios... </template>
    <Column
      field="deldoc"
      header="No. Pedido"
      :sortable="true"
      style="min-width: 12rem"
    ></Column>
    <Column
      field="delenv"
      header="No. Envío"
      :sortable="true"
      style="min-width: 12rem"
    ></Column>
    <Column
      field="delnom"
      header="Nombre de Cliente"
      :sortable="true"
      style="min-width: 12rem"
    ></Column>
    <Column
      field="delfecdoc2"
      header="Fecha de Entrega"
      :sortable="true"
      style="min-width: 12rem"
    ></Column>

    <Column
      field="deldir"
      header="Dirección de Entrega"
      :sortable="true"
      style="min-width: 12rem"
    ></Column>
    <Column
      field="deltel"
      header="Teléfono"
      :sortable="true"
      style="min-width: 12rem"
    ></Column>
    <Column
      field="delmens"
      header="Agente de Logísitica Asignado"
      :sortable="true"
      style="min-width: 12rem"
    ></Column>
    <Column :exportable="false" style="min-width: 8rem">
      <template #body="slotProps">
        <Button
          icon="pi pi-pencil"
          class="p-button-rounded p-button-success p-mr-2"
          @click="DeliveriesUpdateLink(slotProps.data)"
        />
        <Button
          icon="pi pi-trash"
          class="p-button-rounded p-button-warning"
          @click="confirmDeleteProduct(slotProps.data)"
        />
      </template>
    </Column>
  </DataTable>

  <Dialog
    v-model:visible="deleteProductDialog"
    :style="{ width: '450px' }"
    header="Confirmacion"
    :modal="true"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
      <span v-if="product"
        >Esta seguro que desea eliminar el pedido No. <b>{{ product.deldoc }}</b
        >?</span
      >
    </div>
    <template #footer>
      <Button
        label="No"
        icon="pi pi-times"
        class="p-button-text"
        @click="deleteProductDialog = false"
      />
      <Button
        label="Si"
        icon="pi pi-check"
        class="p-button-text"
        @click="deleteProduct"
      />
    </template>
  </Dialog>
</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default {
  data() {
    return {
      items: [
        {
          label: 'Generacion PDF',
          icon: 'pi pi-file-pdf',
          command: () => {
            this.openBasic();
          },
        },
        {
          label: 'Generacion TXT',
          icon: 'pi pi-file',
          command: () => {
            this.openBasicTxt();
          },
        },
      ],
      delfecdoc: null,
      displayBasic: false,
      displayBasictxt: false,
      contador: 0,
      delcia: 0,
      deleteProductDialog: false,
      ciatab: null,
      isLoading: false,
      deliveries: null,
      ClientDialog: false,
      deliverie: {},
      selectedProducts: null,
      submitted: false,
      filters: null,
      loading1: true,
      filteredCiatab: null,
      mensajeros: null,
    };
  },

  /*
--MANEJO DE LA FECHA PARA EL UPDATE, NO CAMBIA.
--LISTAS DE VALORES NO CARGAN PARA EL UPDATE (listo).
--VERIFICAR CAMBIOS DE CHECKBOX
--VER PORQUE NO CARGANN SIEMPRE LA LISTA EN LA PRIMER PANTALLA
--CAMBIO DE LOGIN.*/
  created() {
    this.initFilters();

    this.loadListValuesMensajeros(true);
    this.loadListValuesPrioridades(true);
    this.loadListValuesCiatab(true);

    this.mensajeros = this.listValuesMensajeros;

    setTimeout(() => {
      this.loadDeliveries(true, 1);
    }, 3000);

    setTimeout(() => {
      while (this.deliveries.length === 0 || this.deliveries === null) {
        this.loadDeliveries(true, 1);

        this.contador++;
        if (this.contador === 2) {
          break;
        }
      }
    }, 4000);
  },
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.goBack, false);
    }
    //console.log('yo primero 2');
  },
  computed: {
    isLoggedIn() {
      ////console.log(this.$store.getters.isAuthenticated);
      return this.$store.getters.isAuthenticated;
    },
    hasDeliveries() {
      return this.$store.getters['deliveries/hasDeliveries'];
    },
    filtrarDeliveries() {
      const deliveries =
        this.$store.getters['deliveries/deliveries']['deliveries'];

      for (const key in deliveries) {
        console.log(deliveries[key].delfecdoc);
        deliveries[key].delfecdoc = this.dateFormatOracle(
          deliveries[key].delfecdoc
        );
        for (const key2 in this.$store.getters['listvalues/listvalues'][
          'listvaluesmensajeros'
        ]) {
          if (
            deliveries[key].delmens ==
            this.$store.getters['listvalues/listvalues'][
              'listvaluesmensajeros'
            ][key2].menscod
          ) {
            deliveries[key].delmens =
              this.$store.getters['listvalues/listvalues'][
                'listvaluesmensajeros'
              ][key2].mensnom;
          }
        }
      }
      this.ordenarAsc(deliveries, 'delenv'); //es desc

      /*deliveries.sort((a, b) =>
        a.delenv > b.delenv ? 1 : b.delenv > a.delenv ? -1 : 0
      );
      deliveries.sort((a, b) =>
        b.delfecdoc > a.delfecdoc ? 1 : a.delfecdoc > b.delfecdoc ? -1 : 0
      );*/
      return deliveries;
    },

    listValuesMensajeros() {
      try {
        //console.log(
        // this.$store.getters['listvalues/listvalues']['listvaluesmensajeros']
        //);
        const mensajeros =
          this.$store.getters['listvalues/listvalues']['listvaluesmensajeros'];
        return mensajeros.filter((mensajeros) => {
          if (mensajeros.menscod != 0) {
            return true;
          }
          if (mensajeros.menscod != 0) {
            return true;
          }
          //console.log(mensajeros);

          return true;
        });
      } catch (error) {
        //console.log(error.messages);
        return true;
      }
    },
  },
  methods: {
    clearData() {
      this.deliveries = null;
    },
    GeneraDataTXT() {
      this.displayBasictxt = false;
      let contenido = '';

      const deliveries =
        this.$store.getters['deliveries/deliveries']['deliveries'];

      let linea = 0;
      let contenido_final = '';

      //console.log(deliveries);
      for (const key in deliveries) {
        var delfecdoc = new Date(this.delfecdoc); // Por ejemplo

        // Restar un día
        delfecdoc.setDate(delfecdoc.getDate() - 1);

        console.log(delfecdoc.toISOString());
        if (
          this.$store.getters['deliveries/deliveries']['deliveries'][key]
            .delfecdoc === this.dateFormatOracle(delfecdoc)
        ) {
          linea++;
          let mensaje =
            deliveries[key].delmmsg === null
              ? 'Sin Observaciones'
              : deliveries[key].delmmsg;
          contenido =
            linea +
            '.' +
            '\n' +
            'Nombre: ' +
            deliveries[key].delnom +
            '\n' +
            'Observaciones: ' +
            mensaje +
            '\n' +
            'Dirección: ' +
            deliveries[key].deldir +
            '\n' +
            'Teléfono: ' +
            deliveries[key].deltel +
            '\n' +
            'Pedido: ' +
            deliveries[key].deldoc +
            '\n------------------------------------------------\n';
          //linea++;
        }
        contenido_final += contenido;
        contenido = '';
      }
      this.exportTXT('lupnova.txt', contenido_final);
    },
    exportTXT(name, content) {
      var element = document.createElement('a');
      element.setAttribute(
        'href',
        'data:text/plain;charset=utf-8,' + encodeURIComponent(content)
      );
      element.setAttribute('download', name);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },

    ordenarAsc(p_array_json, p_key) {
      p_array_json.sort(function (a, b) {
        return a[p_key] < b[p_key];
      });
    },
    ordenarDesc(p_array_json, p_key) {
      p_array_json.sort(function (a, b) {
        return a[p_key] > b[p_key];
      });
    },
    closeBasic() {
      this.displayBasic = false;
    },
    openBasicTxt() {
      this.displayBasictxt = true;
    },
    openBasic() {
      this.displayBasic = true;
    },
    exportPDF() {
      this.displayBasic = false;

      var head = [
        [
          'NO.',
          'Dirección Entrega',
          'Pedido',
          'Teléfono',
          'Nombre de quien recibe',
          'Recibí   \nConforme      ',
          'No. de Paquetes',
        ],
      ];
      var body = [];
      let linea = 0;
      const deliveries =
        this.$store.getters['deliveries/deliveries']['deliveries'];
      this.ordenarDesc(deliveries, 'delenv');
      this.ordenarAsc(deliveries, 'delfecdoc');
      var delfecdoc = new Date(this.delfecdoc); // Por ejemplo

      // Restar un día
      delfecdoc.setDate(delfecdoc.getDate() - 1);

      console.log(delfecdoc.toISOString());
      for (const key in deliveries) {
        if (
          this.$store.getters['deliveries/deliveries']['deliveries'][key]
            .delfecdoc === this.dateFormatOracle(delfecdoc.toISOString())
        ) {
          linea++;

          let mensaje =
            deliveries[key].delmmsg === null
              ? 'Sin Observaciones'
              : deliveries[key].delmmsg;
          const body_ = [
            linea,
            deliveries[key].deldir + '\n' + mensaje,
            deliveries[key].deldoc,
            deliveries[key].deltel,
            deliveries[key].delnom,
            '\t\t\t\n\t\t\t\n',
            deliveries[key].delbultos,
          ];
          body.push(body_);
        } else {
          //console.log('no hay nada');
        }
      }
      let footer = new Image();
      footer.src = 'assets/layout/images/lupnovareports.png';

      var doc = new jsPDF('l', 'in', [8.5, 11]);
      //var doc = new jsPDF();
      //const tiempoTranscurrido = Date.now();
      //const hoy = new Date(tiempoTranscurrido);
      doc.addImage(footer, 'png', 0.7, 0, 1.6, 1.4);

      doc.text('Lupnova Joyería - Control de envío', 7, 0.6);
      doc.text('Fecha:', 7, 0.9);
      doc.text(this.dateFormatOracle(delfecdoc.toISOString()), 8, 0.9);

      //doc.addImage(footer, 'PNG', 110, 700, 384.1);
      doc.autoTable({
        head: head,
        body: body,
        startY: 1.3,
        margin: [0.2, 0.2, 0.3, 0.4],
        headStyles: { fillColor: [208, 175, 140] },
        styles: {
          columnWidth: 0.1,
          lineWidth: 0.01,
          lineColor: [208, 175, 140],
        },

        columnStyles: {
          0: { cellWidth: 0.37 },
          1: { cellWidth: 3.5 },
          2: { cellWidth: 0.8 },
          3: { cellWidth: 0.8 },
          4: { cellWidth: 1.5 },
          5: { cellWidth: 2.5, cellHeigth: 5.5 },
          6: { cellWidth: 0.8 },
        },
      });
      doc.output('dataurlnewwindow');
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },

    DeliveriesUpdateLink(product) {
      const redirectUrl =
        this.$route.path +
        '/' +
        product.delcia +
        '/' +
        product.deldoc +
        '/' +
        product.delnit +
        '/' +
        product.delenv +
        '/';

      this.$router.replace(redirectUrl);
      this.clientes = null;
    },
    dateFormatOracle(fecha) {
      const date = new Date(fecha);
      //const date2 = new Date(fecha);
      date.setDate(date.getDate() + 1);

      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();

      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }
      return dt + '/' + month + '/' + year;
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    async loadListValuesMensajeros(refresh = false) {
      this.isLoading = true;
      //console.log('mensajeros #1');
      try {
        await this.$store.dispatch('listvalues/loadListValuesMensajeros', {
          forceRefresh: refresh,
        }); //viene de la clases action.js G
      } catch (error) {
        this.error = error.message || 'algo salió mal';
        //console.log(error.message);
      }
      this.isLoading = false;
    },
    confirmDeleteProduct(product) {
      this.product = product;
      ////console.log(this.product);
      this.deleteProductDialog = true;
    },
    async deleteProduct() {
      ////console.log(this.product.cusname);
      ////console.log(this.product.cusnit);
      this.deleteProductDialog = false;
      const formData = {
        cia: this.product.delcia,
        nit: this.product.delnit,
        cod: this.product.deldoc,
        env: this.product.delenv,
      };
      ////console.log(formData.nit);
      const response = await this.$store.dispatch(
        'deliveries/deleteDeliveries',
        formData
      );
      this.clientes = null;
      this.ciacod = 0;
      console.log(response);
      if (response.codigo == 1) {
        this.$toast.add({
          severity: 'success',
          summary: 'Eliminacion ',
          detail: response.mensaje,
          //group: 'br',
          life: 2000,
        });
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'Eliminacion',
          detail: response.mensaje,
          //group: 'br',
          life: 2000,
        });
      }
      this.loading1 = true;
      setTimeout(() => {
        this.loadDeliveries(true, 1);
        this.$router.replace('/Deliveries');
        this.loading1 = false;
      }, 3000);
    },

    async loadDeliveries(refresh = false, cuscia) {
      this.isLoading = true;
      this.deliveries = null;

      try {
        await this.$store.dispatch('deliveries/loadDeliveries', cuscia, {
          forceRefresh: refresh,
        }); //viene de la clases action.js G
      } catch (error) {
        //console.log('loadDeliveries # errores');

        //console.log(error.message);
        this.error = error.message || 'algo salió mal';
        this.isLoading = false;
      }
      this.isLoading = false;
      this.deliveries = this.filtrarDeliveries;
      this.loading1 = false;
      return true;
    },
    registroNuevo() {
      const redirectUrl = '/' + (this.$route.query.redirect || 'newDeliveries');
      this.$router.replace(redirectUrl);
    },

    async loadListValuesPrioridades(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('listvalues/loadListValuesPrioridades', {
          forceRefresh: refresh,
        }); //viene de la clases action.js G
      } catch (error) {
        this.error = error.message || 'algo salió mal';
        ////console.log(error.message);
      }
      this.isLoading = false;
    },

    async loadListValuesCiatab(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('listvalues/loadListValuesCiatab', {
          forceRefresh: refresh,
        }); //viene de la clases action.js G
      } catch (error) {
        this.error = error.message || 'algo salió mal';
        ////console.log(error.message);
      }
      this.isLoading = false;
    },
    async trasladoDeliveries(refresh = false) {
      this.loading1 = true;
      this.isLoading = true;
      this.deliveries = null;
      try {
        await this.$store.dispatch('deliveries/trasladoDeliveries', {
          forceRefresh: refresh,
        }); //viene de la clases action.js G
      } catch (error) {
        //console.log('loadDeliveries # errores');

        //console.log(error.message);
        this.error = error.message || 'algo salió mal';
        this.isLoading = false;
      }
      setTimeout(() => {
        this.loadDeliveries(true, 1);
        this.deliveries = this.filtrarDeliveries;
      }, 2000);
      this.isLoading = false;
      this.deliveries = this.filtrarDeliveries;
      this.loading1 = false;
      return true;
    },
  },
};
</script>
<style scoped>
.center {
  border: 5px solid #ffffff;
  text-align: center;
}
</style>
