export default {
  insertDeliveries(state, payload) {
    console.log(payload);
    state.deliveries['deliveries'].push(payload);
  },
  setDeliveries(state, payload) {
    //console.log(state);
    state.deliveries['deliveries'] = payload;
  },
  setFetchTimestamp(state) {
    state.lastFetch = new Date().getTime();
  },
  deleteDeliveries(state, payload) {
    console.log('Mutation para delete ' + payload);
    state.deliveries['deliveries'].push(payload);
  },
  updateDeliveries(state, payload) {
    console.log('test update');
    state.deliveries['deliveries'].push(payload);
  },
};
