export default {
  /********************************************************************
   *************RECUPERACION DE LISTA DE VALORES DIRECCIONES***********
   ********************************************************************
   */
  /*async loadCusnewDir(context, data) {
    const cusnewData = {
      cusdcia: data.cia,
      cusdnit: data.nit,
      cusdcod: data.cod,
      cusddep: data.dep,
      cusdciu: data.ciu,
    };

    const token = context.rootGetters.token;

    const response = await fetch(
      `https://192.9.200.125:84/cusdirnew/${cusnewData.cusdcia}/${cusnewData.cusdnit}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      }
    );

    const responseData = await response.json();
    //console.log('Respuesta de lista de valores sin procesar.');
    //console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Error recuperando informacion.'
      );
      throw error;
    }
    const direcciones = [];

    for (const key in responseData['cusdirnew']) {
      const cusnewdir = {
        cusdcia: responseData['cusdirnew'][key].cusdcia,
        cusdnit: responseData['cusdirnew'][key].cusdnit,
        cusddep: responseData['cusdirnew'][key].cusddep,
        cusdciu: responseData['cusdirnew'][key].cusdciu,
        cusdzona: responseData['cusdirnew'][key].cusdzona,
        cusdcolonia: responseData['cusdirnew'][key].cusdcolonia,
        cusdcalle: responseData['cusdirnew'][key].cusdcalle,
        cusdcasa: responseData['cusdirnew'][key].cusdcasa,
      };
      direcciones.push(cusnewdir);
    }

    context.commit('setCusnewDir', direcciones);
    context.commit('setFetchTimestamp');
  },*/
  /********************************************************************
   *************************INSERT DIRECCIONES*************************
   ********************************************************************
   */
  async insertCusnewdir(context, data) {
    console.log(data);
    const cusnewData = {
      cusdcia: data.cusdcia,
      cusdnit: data.cusdnit,
      cusddep: data.cusddep,
      cusdciu: data.cusdciu,
      cusdzona: data.cusdzona,
    };
    console.log(cusnewData);
    console.log(JSON.stringify(data));

    const token = context.rootGetters.token;

    const response = await fetch(
      `https://192.9.200.125:84/cusdirnew/${cusnewData.cusdcia}/${cusnewData.cusdnit}/${cusnewData.cusddep}/${cusnewData.cusdciu}/${cusnewData.cusdzona}`,
      {
        method: 'POST', //INSERT
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify(data),
      }
    );
    console.log('-----------------------------------------------------');
    console.log(response);
    console.log('-----------------------------------------------------');
    //const responseData = await response.json();
    if (!response.ok) {
      //hay un error
    }

    context.commit('insertCusnewdir', {
      ...cusnewData,
      cusnit: data.cusnit,
    });
  },
  /*******************************************************************
   ********RECUPERACION ESPECIFICA DE DIRECCIONES POR CLIENTE**********
   ********************************************************************
   */
  async loadCusnewDir(context, data) {
    console.log(data); //miau
    const cusnewData = {
      cusdcia: data.cusdcia,
      cusdnit: data.cusdnit,
      cusddep: data.cusddep,
      cusdciu: data.cusdciu,
      cusdzona: data.cusdzona,
    };

    const token = context.rootGetters.token;

    const response = await fetch(
      `https://192.9.200.125:84/cusdirnew/${cusnewData.cusdcia}/${cusnewData.cusdnit}/${cusnewData.cusddep}/${cusnewData.cusdciu}/${cusnewData.cusdzona}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      }
    );

    const responseData = await response.json();
    //console.log('Respuesta de lista de valores sin procesar.');
    //console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Error recuperando informacion.'
      );
      throw error;
    }
    const direcciones = [];

    for (const key in responseData['cusdirnew']) {
      const cusnewdir = {
        cusdcia: responseData['cusdirnew'][key].cusdcia,
        cusdnit: responseData['cusdirnew'][key].cusdnit,
        cusddep: responseData['cusdirnew'][key].cusddep,
        cusdciu: responseData['cusdirnew'][key].cusdciu,
        cusdzona: responseData['cusdirnew'][key].cusdzona,
        cusdcolonia: responseData['cusdirnew'][key].cusdcolonia,
        cusdcalle: responseData['cusdirnew'][key].cusdcalle,
        cusdcasa: responseData['cusdirnew'][key].cusdcasa,
      };
      //console.log(cusnewdir);
      direcciones.push(cusnewdir);
    }

    context.commit('setCusnewDir', direcciones);
    context.commit('setFetchTimestamp');
  },
  /********************************************************************
   *************************UPDATE DIRECCIONES*************************
   ********************************************************************
   */
  async updateCusnewdir(context, data) {
    console.log(data);
    const cusnewData = {
      cusdcia: data.cusdcia,
      cusdnit: data.cusdnit,
      cusddep: data.cusddep,
      cusdciu: data.cusdciu,
      cusdzona: data.cusdzona,
    };
    console.log(cusnewData);
    console.log(JSON.stringify(data));

    const token = context.rootGetters.token;

    const response = await fetch(
      `https://192.9.200.125:84/cusdirnew/${cusnewData.cusdcia}/${cusnewData.cusdnit}/${cusnewData.cusddep}/${cusnewData.cusdciu}/${cusnewData.cusdzona}`,
      {
        method: 'PUT', //INSERT
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify(data),
      }
    );
    console.log('-----------------------------------------------------');
    console.log(response);
    console.log('-----------------------------------------------------');
    //const responseData = await response.json();
    if (!response.ok) {
      //hay un error
    }

    context.commit('updateCusnewdir', {
      ...cusnewData,
      cusnit: data.cusdnit,
    });
  },
  /********************************************************************
   *************************DELETE DIRECCIONES*************************
   ********************************************************************
   */
  async deleteCusnewdir(context, data) {
    console.log(data);
    const cusnewData = {
      cusdcia: data.cusdcia,
      cusdnit: data.cusdnit,
      cusddep: data.cusddep,
      cusdciu: data.cusdciu,
      cusdzona: data.cusdzona,
    };
    console.log(cusnewData);

    const token = context.rootGetters.token;

    const response = await fetch(
      `https://192.9.200.125:84/cusdirnew/${cusnewData.cusdcia}/${cusnewData.cusdnit}/${cusnewData.cusddep}/${cusnewData.cusdciu}/${cusnewData.cusdzona}`,
      {
        method: 'DELETE', //INSERT
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      }
    );
    console.log('-----------------------------------------------------');
    console.log(response);
    console.log('-----------------------------------------------------');
    //const responseData = await response.json();
    if (!response.ok) {
      //hay un error
    }

    context.commit('deleteCusnewDir', {
      ...cusnewData,
      cusnit: data.cusdnit,
    });
  },
};
