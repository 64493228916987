<template>
  <base-card>
    <div class="card">
      <Toolbar class="p-mb-4">
        <template v-slot:right>
          <Button
            label="Guardar"
            icon="pi pi-save"
            class="p-button-info p-mr-2"
          />
        </template>
      </Toolbar>
      <span class="p-float-label">
        <AutoComplete
          :dropdown="true"
          size="35"
          v-model="cia"
          :suggestions="filteredCiatab"
          @complete="searchCiatab($event)"
          placeholder="Seleccione una compañia"
      /></span>
      <br /><br />
      <div class="p-formgroup-inline">
        <br /><br />
        <div class="p-field">
          <b>
            <span class="p-float-label">
              <InputText
                id="docmnit"
                type="text"
                size="30"
                v-model="docmnit"
                @blur="clearValidity('delnom')"
                :maxlength="30"
              />
              <label for="docmnit">NIT ó CUI</label>
            </span>
          </b>
        </div>

        <div class="p-field">
          <b
            ><span class="p-float-label">
              <Calendar
                id="docmfecdoc"
                :showIcon="true"
                v-model="docmfecdoc"
                date-format="dd/mm/yy"
              />
              <label for="docmfecdoc">Fecha</label>
            </span></b
          >
        </div>
      </div>
      <br /><br />
      <b>
        <span class="p-float-label">
          <InputText
            id="docmnom"
            type="text"
            size="100"
            v-model="docmnom"
            :maxlength="100"
          />
          <label for="docmnom">Nombre Cliente</label>
        </span>
      </b>
      <br /><br />
      <b>
        <span class="p-float-label">
          <InputText
            id="docmdir"
            type="text"
            size="125"
            v-model="docmdir"
            :maxlength="200"
          />
          <label for="docmdir">Direccion</label>
        </span>
      </b>
      <br /><br />
      <DataTable
        ref="dv"
        responsiveLayout="scroll"
        id="entregas"
        dataKey="docdprd"
        :paginator="true"
        :rows="25"
        showGridlines
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[25, 50, 75, 100, 125]"
        currentPageReportTemplate="Viendo {first} a {last} de {totalRecords} items"
      >
        <template #header>
          <div
            class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between"
          >
            <span class="p-input-icon-left p-mb-2">
              <Button
                type="button"
                label="Agregar Producto"
                icon="pi pi-plus"
                @click="openDialog"
                class="p-button-success p-mr-2"
              />
            </span>
          </div>
        </template>
        <template #empty><b>No se encontró ningún item ingresado.</b></template>
        <Column
          field="docdnumlin"
          header="No."
          :sortable="true"
          style="text-align: left"
        ></Column>
        <Column
          field="docdesc"
          header="Descripción"
          :sortable="true"
          style="min-width: 12rem"
        ></Column>
        <Column
          field="docdunivta"
          header="Cantidad"
          :sortable="true"
          style="min-width: 12rem"
        ></Column>
        <Column
          field="docdprcuni"
          header="Precio"
          :sortable="true"
          style="min-width: 12rem"
        ></Column>

        <Column
          field="docdprecaj1"
          header="Valor"
          :sortable="true"
          style="min-width: 12rem"
        ></Column>
        <Column :exportable="false" style="min-width: 8rem"> </Column>
      </DataTable>
      <Dialog
        header="Agregar Producto"
        v-model:visible="openbasic"
        :breakpoints="{ '740px': '75vw', '540px': '60vw' }"
        :style="{ width: '50vw' }"
      >
        <div class="center">
          <div class="p-formgroup-inline">
            <span class="p-float-label">
              <AutoComplete
                :dropdown="true"
                size="84"
                v-model="docdesc"
                :suggestions="filteredCiatab"
                @complete="searchCiatab($event)"
                placeholder="Descripcion del Producto"
              />
            </span>
          </div>
          <br /><br />
          <div class="p-formgroup-inline">
            <span class="p-float-label">
              <InputNumber
                v-model="docdunivta"
                inputId="minmax-buttons"
                mode="decimal"
                showButtons
                :min="0"
                :max="100"
              />
              <label for="docdunivta">Cantidad</label></span
            >
          </div>
          <br /><br />
          <div class="p-formgroup-inline">
            <span class="p-float-label">
              <InputNumber
                id="docdprecuni"
                v-model="docdprecuni"
                inputId="stacked-buttons"
                showButtons
                mode="currency"
                currency="GTQ"
              />
              <label for="docdprecuni">Precio</label></span
            >
          </div>
        </div>

        <template #footer>
          <Button label="No" icon="pi pi-times" class="p-button-text" />
          <Button label="Guardar" icon="pi pi-check" autofocus />
        </template>
      </Dialog></div
  ></base-card>
</template>

<script>
export default {
  data() {
    return {
      openbasic: false,
      docdesc: null,
      docdunivta: null,
      docdprecuni: 0.0,
      cia: null,
      docmcia: '',
      docmnit: '',
      docmnom: '',
      docmdir: '',
      detallebill: null,
      docmfecdoc: null,
      ciatab: null,
      filteredCiatab: null,
      producto: null,
      fecha_documento: null,
      detall: [],
    };
  },
  methods: {
    saveProductos() {
      /*this.detall.push(
        cusdes: this.docdesc,
        
      )*/
      /*docdprd: responseData['docdt'][key].docdprd,
        docdunivta: responseData['docdt'][key].docdunivta,
        docdnumlin: responseData['docdt'][key].docdnumlin,
        docdesc: responseData['docdt'][key].docdesc,
        docdprcuni: responseData['docdt'][key].docdprcuni,
        docdprecaj1: responseData['docdt'][key].docdprecaj1,
        docdiva: responseData['docdt'][key].docdiva,*/
    },
    openDialog() {
      this.openbasic = true;
    },
    searchCiatab(event) {
      const companias = [];
      console.log('search');

      setTimeout(() => {
        for (const key in this.$store.getters['listvalues/listvalues'][
          'listvalues'
        ]) {
          companias.push(
            this.$store.getters['listvalues/listvalues']['listvalues'][key]
              .ciacod +
              ' - ' +
              this.$store.getters['listvalues/listvalues']['listvalues'][key]
                .cianombre
          );
        }
        if (!event.query.trim().length) {
          this.filteredCiatab = [...companias];
        } else {
          this.filteredCiatab = companias.filter((country) => {
            return country.toLowerCase().includes(event.query.toLowerCase());
          });
        }
      }, 250);
    },
  },
};
</script>
