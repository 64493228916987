export default {
  insertCusnew(state, payload) {
    //console.log(payload);
    state.cusnew['cusnew'].push(payload);
  },
  setCusnew(state, payload) {
    //console.log(state);
    state.cusnew['cusnew'] = payload;
  },
  setFetchTimestamp(state) {
    state.lastFetch = new Date().getTime();
  },
  deleteCusnew(state, payload) {
    //console.log('Mutation para delete ' + payload);
    state.cusnew['cusnew'].push(payload);
  },
  updateCusnew(state, payload) {
    //console.log('test update');
    state.cusnew['cusnew'].push(payload);
  },
};
