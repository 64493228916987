import { createApp, h, reactive } from 'vue';

//import { PivotViewPlugin } from "@syncfusion/ej2-vue-pivotview";
import AppWrapper from './AppWrapper.vue';

import router from './router.js';
import montab from './store/index.js';
import BaseCard from './components/ui/BaseCard.vue';
import BaseButton from './components/ui/BaseButton.vue';
import BaseBadge from './components/ui/BaseBadge.vue';
import BaseSpinner from './components/ui/BaseSpinner.vue';
import BaseDialog from './components/ui/BaseDialog.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Checkbox from 'primevue/checkbox';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import ConfirmDialog from 'primevue/confirmdialog';
import PrimeVue from 'primevue/config';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';

import RadioButton from 'primevue/radiobutton';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import Slider from 'primevue/slider';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import ProgressBar from 'primevue/progressbar';
import MultiSelect from 'primevue/multiselect';
import AutoComplete from 'primevue/autocomplete';
import Divider from 'primevue/divider';
import Password from 'primevue/password';
import ProgressSpinner from 'primevue/progressspinner';
import Tooltip from 'primevue/tooltip';
import Textarea from 'primevue/textarea';
import SplitButton from 'primevue/splitbutton';
import Ripple from 'primevue/ripple';
import TabPanel from 'primevue/tabpanel';
import Chart from 'primevue/chart';

//import { pivotData } from './pivotData.js';

import 'primevue/resources/primevue.min.css'; //required: PrimeVue components
import 'primeicons/primeicons.css'; //required: PrimeIcons
import './App.scss';

//import App from './App.vue';
const esLocale = {
  firstDayOfWeek: 1,
  dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
  dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
  dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
  monthNames: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
  monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
  today: 'Hoy',
  clear: 'Borrar',
  dateFormat: 'mm/yy',
  weekHeader: 'Sm'
};

const app = createApp({
  render() {
    return h(AppWrapper);
  },
});
console.log(esLocale)
//PrimeVue.config.locale = esLocale;

app.config.globalProperties.$appState = reactive({
  colorScheme: 'light',
  isNewThemeLoaded: false,
});
app.use(PrimeVue, { ripple: true ,locale: esLocale });
app.directive('ripple', Ripple);
app.use(router);
app.use(montab);
app.use(PrimeVue);
app.use(ToastService);

app.component('base-card', BaseCard);
app.component('base-button', BaseButton);
app.component('base-badge', BaseBadge);
app.component('base-spinner', BaseSpinner);
app.component('base-dialog', BaseDialog);
//app.component('base-checkbox', BaseCheckbox);

app.component('Password', Password);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('InputText', InputText);
app.component('Checkbox', Checkbox);
app.component('Button', Button);
app.component('Dialog', Dialog);
app.component('ConfirmDialog', ConfirmDialog);
app.component('Toast', Toast);
app.component('Toolbar', Toolbar);
app.component('RadioButton', RadioButton);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);

app.component('Dropdown', Dropdown);
app.component('Calendar', Calendar);
app.component('Slider', Slider);
app.component('TriStateCheckbox', TriStateCheckbox);
app.component('ProgressBar', ProgressBar);
app.component('MultiSelect', MultiSelect);
app.component('AutoComplete', AutoComplete);
app.component('Divider', Divider);
app.component('ProgressSpinner', ProgressSpinner);
app.directive('tooltip', Tooltip);
app.component('Chart', Chart);

app.component('Textarea', Textarea);
app.component('SplitButton', SplitButton);
app.component('TabPanel', TabPanel);

//app.component('pivotData', pivotData);

app.mount('#app');
