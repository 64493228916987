export default {
  cusnewdir(state) {
    return state.cusnewdir;
  },
  hasCusnewdir(state) {
    //sirve para verificar si tenemos valores por mostrar.
    return state.cusnewdir && state.cusnewdir['cusnewdir'].length > 0;
  },
  isListvalues(_, getters) {
    const cusnewdir = getters.cusnewdir;
    console.log(cusnewdir['cusnewdir']);

    const userId = getters.cusnewdir['cusnewdir'].cusdcia;
    return cusnewdir['cusnewdir'].some((cusnewdir) => cusnewdir.id === userId);
  },
  shouldUpdate(state) {
    const lastFetch = state.lastFetch;
    if (!lastFetch) {
      return true;
    }
    const currentTimeStamp = new Date().getTime();
    return (currentTimeStamp - lastFetch) / 1000 > 60;
  },
};
