import API_DOMAIN from '../../../config';

export default {
  async getDollars() {},

  /*****************************************************************
   ************ACTUALIZACION DE UNA CLIENTE ESPECIFICAMENTE***********
   ******************************************************************
   */
  async updateDeliveries(context, data) {
    const infodata = {
      delcia: data.cia,
      deldoc: data.doc,
      delenv: data.env,
      delmens: data.mens,
      delfecdoc: data.fecdoc,
      delnom: data.nom,
      deldir: data.dir,
      delnit: data.nit,
      deltel: data.tel,
      dellatitud: data.latitud,
      dellongitud: data.longitud,
      delbultos: data.bultos == null ? 'null' : data.bultos,
      delprioridad: data.prioridad,
      delflg: data.flg,
      deldispositivo: data.dispositivo,
      delfirma: data.firma,
      deluuid: data.uuid,
      delfelfec: data.felfec,
      delfeldoc: 0,
      delfelser: data.felser,
      delmmsg: data.mmsg,
    };
    //console.log(infodata);
    const token = context.rootGetters.token;
    //console.log(JSON.stringify(infodata));
    try {
      const response = await fetch(`${API_DOMAIN}/api/mosi/Envios`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify(infodata),
      });
      const res = await response.json();

      // Actualizar el estado local después de la actualización en el servidor
      context.commit('updateDeliveries', {
        ...infodata,
        deldoc: data.deldoc,
      });
      if (!response.ok) {
        return {
          mensaje: response.statusText,
          codigo: 0, // Ejemplo de lógica para determinar el valor de "codigo"
        };
      } else {
        return {
          mensaje: res.deliveries[0].mensaje,
          codigo: res.deliveries[0].envio,
        };
      }
    } catch (error) {
      console.error('Se produjo un error:', error);
      // Manejar el error en caso de que ocurra un problema durante la solicitud o el procesamiento de la respuesta
    }
  },
  /*****************************************************************
   ************ELIMINACION DE UNA CLIENTE ESPECIFICAMENTE***********
   *****************************************************************
   */
  async deleteDeliveries(context, data) {
    console.log('Delete de Envios');
    console.log(data);
    const infodata = {
      delcia: data.cia,
      deldoc: data.cod,
      delenv: data.env,
      delmens: '',
      delfecdoc: '',
      delnom: '',
      deldir: '',
      delnit: data.nit,
      deltel: '',
      dellatitud: '',
      dellongitud: '',
      delbultos: '',
      delprioridad: '',
      delflg: '',
      deldispositivo: '',
      delfirma: '',
      deluuid: '',
      delfelfec: '',
      delfeldoc: '',
      delfelser: '',
      delmmsg: '',
    };

    const token = context.rootGetters.token;
    ////console.log(token);

    //console.log(
    //  `${API_DOMAIN}/deliveries/${cusnewData.delcia}/${cusnewData.deldoc}/${cusnewData.delnit}`
    // );
    const response = await fetch(`${API_DOMAIN}/api/mosi/Envios`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(infodata),
    });
    context.commit('deleteDeliveries', {
      ...infodata,
      deldoc: infodata.deldoc,
    });
    console.log(response);
    const res = await response.json();
    if (!response.ok) {
      return {
        mensaje: response.statusText,
        codigo: 0, // Ejemplo de lógica para determinar el valor de "codigo"
      };
    } else {
      return {
        mensaje: res.deliveries[0].mensaje,
        codigo: res.deliveries[0].envio,
      };
    }
  },
  /*****************************************************************
   ************INSERCCION DE UNA NUEVO CLIENTE**********************
   *****************************************************************
   */
  /*this.nit.val =
            this.selectedDeliverie.delnit == null
              ? ''
              : this.selectedDeliverie.delnit;
              

              
              */

  async insertDeliveries(context, data) {
    const infodata = {
      delcia: data.cia,
      deldoc: data.doc,
      delenv: 0,
      delmens: data.mens,
      delfecdoc: data.fecdoc,
      delnom: data.nom,
      deldir: data.dir,
      delnit: data.nit,
      deltel: data.tel,
      dellatitud: data.latitud,
      dellongitud: data.longitud,
      delbultos: data.bultos == null ? 'null' : data.bultos,
      delprioridad: data.prioridad,
      delflg: data.flg,
      deldispositivo: data.dispositivo,
      delfirma: data.firma,
      deluuid: data.uuid,
      delfelfec: data.felfec,
      delfeldoc: 0,
      delfelser: data.felser,
      delmmsg: data.mmsg,
    };

    const token = context.rootGetters.token;
    //console.log('Envio de informacion para realizar insert en Oracle');
    ////console.log(JSON.stringify(infodata));
    //console.log(infodata);
    //console.log(token);
    //console.log(
    // '${API_DOMAIN}/deliveries/${infodata.delcia}/${infodata.deldoc}/${infodata.delnit}'
    //);
    const response = await fetch(
      `${API_DOMAIN}/deliveries/${infodata.delcia}/${infodata.deldoc}/${infodata.delnit}/${infodata.delenv}`,
      {
        method: 'POST', //INSERT
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify(infodata),
      }
    );
    //console.log('1///////////////////////////////////////////////////////');
    //console.log(response);
    //console.log('///////////////////////////////////////////////////////');
    const responseData = await response.json();

    //console.log(responseData);
    //console.log('/////////////D//////////////////////////////////////////');

    if (!response.ok) {
      //console.log('ha ocurrido un error');
      const error = new Error(
        responseData.mensaje || 'Error Guardando la informacion.'
      );
      //console.log(error);
      throw error;
    }

    context.commit('insertDeliveries', {
      ...infodata,
      cusnit: data.cusnit,
    });
  },
  /*******************************************************************
   ************RECUPERACION DE TODOS LOS CLIENTES*********************
   *******************************************************************
   */
  dateFormatOracle(fecha) {
    const date = new Date(fecha);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }
    return dt + '/' + month + '/' + year;
  },
  async loadDeliveries(context, delcia) {
    console.log('Inicio de Metodo de Recuperacion de Entregas...');

    const token = context.rootGetters.token;
    ////console.log(token);
    console.log(delcia);

    const response = await fetch(`${API_DOMAIN}/api/mosi/listado/Envios`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        //cache: 'no-store',
        Authorization: 'Bearer ' + token,
      },
    });

    const responseData = await response.json();
    console.log(responseData);
    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Error recuperando informacion.'
      );
      throw error;
    }
    const deliveries = [];
    context.commit('setDeliveries', deliveries);

    //console.log(deliveries);
    //console.log(responseData);
    for (const key in responseData['deliveries']) {
      const deliverie = {
        delcia: responseData['deliveries'][key].delcia,
        deldoc: responseData['deliveries'][key].deldoc,
        delenv: responseData['deliveries'][key].delenv,
        delmens: responseData['deliveries'][key].delmens,
        delfecdoc: responseData['deliveries'][key].delfecdoc,
        delnom: responseData['deliveries'][key].delnom,
        deldir: responseData['deliveries'][key].deldir,
        delnit: responseData['deliveries'][key].delnit,
        deltel: responseData['deliveries'][key].deltel,
        dellatitud: responseData['deliveries'][key].dellatitud,
        dellongitud: responseData['deliveries'][key].dellongitud,
        delbultos: responseData['deliveries'][key].delbultos,
        delprioridad: responseData['deliveries'][key].delprioridad,
        delflg: responseData['deliveries'][key].delflg,
        deldispositivo: responseData['deliveries'][key].deldispositivo,
        delfirma: responseData['deliveries'][key].delfirma,
        deluuid: responseData['deliveries'][key].deluuid,
        delfelfec: responseData['deliveries'][key].delfelfec,
        delfeldoc: responseData['deliveries'][key].delfeldoc,
        delfelser: responseData['deliveries'][key].delfelser,
        delmmsg: responseData['deliveries'][key].delmmsg,
        delfecdoc2: responseData['deliveries'][key].delfecdoc,
      };

      deliveries.push(deliverie);
    }

    context.commit('setDeliveries', deliveries);
    context.commit('setFetchTimestamp');
  },
  /*****************************************************************
   ******************TRASLADO DE PEDIDOS****************************
   *****************************************************************
   */

  async trasladoDeliveries(context) {
    console.log('inicio de traslado deliveries');
    const token = context.rootGetters.token;
    const response = await fetch(`${API_DOMAIN}/relocation/orders/1`, {
      method: 'POST', //INSERT
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
    const responseData = await response.json();

    if (!response.ok) {
      //console.log('ha ocurrido un error');
      const error = new Error(
        responseData.mensaje || 'Error trasladando la informacion.'
      );
      //console.log(error);
      throw error;
    }
  },

  convertirFormatoFecha(fechaString, formatoOriginal, formatoDestino) {
    // Dividir la cadena en día, mes y año según el formato original
    var partesFecha = fechaString.split(formatoOriginal);

    // Crear un nuevo objeto Date con los componentes de la fecha
    var fecha = new Date(partesFecha[2], partesFecha[1] - 1, partesFecha[0]);

    // Obtener los componentes de la fecha
    var dia = fecha.getDate();
    var mes = fecha.getMonth() + 1; // Los meses en JavaScript son de 0 a 11, por eso se suma 1
    var año = fecha.getFullYear();

    // Formatear la fecha en el formato destino
    var fechaFormateada = '';

    if (formatoDestino === 'dd-mm-yyyy') {
      fechaFormateada = dia + '-' + mes + '-' + año;
    } else if (formatoDestino === 'dd/mm/yyyy') {
      fechaFormateada = dia + '/' + mes + '/' + año;
    }

    return fechaFormateada;
  },
};

// async updateDeliveries(context, data) {
//   const infodata = {
//     delcia: data.cia,
//     deldoc: data.doc,
//     delenv: data.env,
//     delmens: data.mens,
//     delfecdoc: data.fecdoc,
//     delnom: data.nom,
//     deldir: data.dir,
//     delnit: data.nit,
//     deltel: data.tel,
//     dellatitud: data.latitud,
//     dellongitud: data.longitud,
//     delbultos: data.bultos == null ? 'null' : data.bultos,
//     delprioridad: data.prioridad,
//     delflg: data.flg,
//     deldispositivo: data.dispositivo,
//     delfirma: data.firma,
//     deluuid: data.uuid,
//     delfelfec: data.felfec,
//     delfeldoc: 0,
//     delfelser: data.felser,
//     delmmsg: data.mmsg,
//   };
//   const token = context.rootGetters.token;

//   const response = fetch(
//     `${API_DOMAIN}/deliveries/${infodata.delcia}/${infodata.deldoc}/${infodata.delnit}/${infodata.delenv}`,
//     {
//       method: 'PUT', //INSERT
//       headers: {
//         'Content-Type': 'application/json',

//         Authorization: 'Bearer ' + token,
//       },
//       body: JSON.stringify(infodata),
//     }
//   );

//   //const responseData = await response.json();
//   if (!response.ok) {
//     //hay un error
//   }

//   context.commit('updateDeliveries', {
//     ...infodata,
//     deldoc: data.deldoc,
//   });
// },
